import {
  FormikInput,
  FormikSelect,
  FormikTextarea,
} from "@/components/form/inputs";
import { Card2, SkeletonCard } from "@/components/ui/Card";
import { PROVINCES } from "@/config/provinces";
import { API_URL } from "@/config/routes";
import { useAuth } from "@/hooks/useAuth";
import { fetcher } from "@/utils";
import { Form, Formik } from "formik";
import { useState } from "react";
import { toast } from "react-toastify";
import { verticalTableFromRows } from "../../components/ui/Table";
import UserLink from "../users/UserLink";
import { FormikUserSelect } from "../users/UserSelect";
import ClientLink from "./ClientLink";
import useClient from "./useClient";

const getClientNameField = ({ client, readOnly }) => {
  return {
    label: client.type === "person" ? "Nome e cognome" : "Ragione sociale",
    value: readOnly ? (
      <ClientLink client={client} />
    ) : (
      <>
        {client.type === "company" ? (
          <FormikInput
            name="business_name"
            placeholder="Ragione sociale"
            required
          />
        ) : (
          <div className="flex flex-col gap-2">
            <FormikInput name="first_name" placeholder="Nome" required />
            <FormikInput name="last_name" placeholder="Cognome" required />
          </div>
        )}
      </>
    ),
  };
};

const getFreelancerFields = ({ client, readOnly }) => {
  return [
    {
      label: "Codice Fiscale",
      value: readOnly ? (
        <span>{client.fiscal_code_for_freelancers || "-"}</span>
      ) : (
        <FormikInput
          name="fiscal_code_for_freelancers"
          placeholder="Codice fiscale"
          maxLength={16}
          required
        />
      ),
    },
  ];
};

const getCompanyFields = ({ client, readOnly }) => {
  return [
    {
      label: "Intestatario",
      value: readOnly ? (
        <span>
          {client.first_name || "-"} {client.last_name || ""}
        </span>
      ) : (
        <div className="flex flex-col gap-2">
          <FormikInput
            name="first_name"
            placeholder="Digita il nome del driver del veicolo"
          />
          <FormikInput
            name="last_name"
            placeholder="Digita il nome del driver del veicolo"
          />
        </div>
      ),
    },
    {
      label: "Proprietario dell'attività",
      value: readOnly ? (
        <span>
          {client.controlling_party || "-"}{" "}
          {client.controlling_party_tax_id &&
            "(" + client.controlling_party_tax_id + ")"}
        </span>
      ) : (
        <div className="flex flex-col gap-2">
          <FormikInput
            name="controlling_party"
            placeholder="Nome e cognome o ragione sociale del proprietario"
          />
          <FormikInput
            name="controlling_party_tax_id"
            placeholder="Codice fiscale o partita IVA del proprietario"
            maxLength={16}
          />
        </div>
      ),
    },
    {
      label: "Responsabile flotta",
      value: readOnly ? (
        <span>
          {client.fleet_manager || "-"}{" "}
          {client.fleet_manager_tax_id &&
            "(" + client.fleet_manager_tax_id + ")"}
        </span>
      ) : (
        <div className="flex flex-col gap-2">
          <FormikInput
            name="fleet_manager"
            placeholder="Nome e cognome del responsabile flotta"
          />
          <FormikInput
            name="fleet_manager_tax_id"
            placeholder="Codice fiscale o partita IVA del responsabile flotta"
            maxLength={16}
          />
        </div>
      ),
    },
  ];
};

function ClientCreatedBySelect({ client, readOnly }) {
  const { user } = useAuth();

  if (!readOnly && user.role === "admin") {
    return (
      <FormikUserSelect
        name="user_id"
        readOnly={readOnly}
        value={client.user.id}
      />
    );
  }
  if (client.user.id) {
    return <UserLink user={client.user} />;
  }
  return <i>Admin</i>;
}

function AddressInput({ client, readOnly }) {
  return readOnly ? (
    client?.address?.simple || "-"
  ) : (
    <div className="grid grid-cols-2 gap-2">
      <FormikInput
        placeholder="Indirizzo"
        name="address.street"
        required={true}
      />
      <FormikInput
        placeholder="CAP"
        name="address.postal_code"
        required={true}
      />
      <FormikInput placeholder="Città" name="address.city" required={true} />
      <FormikSelect
        placeholder="Provincia"
        name="address.province"
        required={true}
        options={PROVINCES}
      />
    </div>
  );
}

function EmailInput({ client, readOnly }) {
  return readOnly ? (
    <>
      <div>{client.email && <span>{client.email} (email)</span>}</div>
      <div>{client.pec && <span>{client.pec} (PEC)</span>}</div>
      <span>{!client.pec && !client.email && "-"}</span>
    </>
  ) : (
    <div className="flex flex-row gap-2">
      <FormikInput placeholder="Email" name="email" required={true} />
      <FormikInput placeholder="PEC" name="pec" />
    </div>
  );
}

function PhoneInput({ client, readOnly }) {
  return readOnly ? (
    <>
      <div>
        {client.mobile_phone && <span>{client.mobile_phone} (cellulare)</span>}
      </div>
      <div>{client.phone && <span>{client.phone} (ufficio)</span>}</div>
      <span>{!client.mobile_phone && !client.phone && "-"}</span>
    </>
  ) : (
    <div className="flex flex-row gap-2">
      <FormikInput
        placeholder="Cellulare"
        name="mobile_phone"
        required={true}
      />
      <FormikInput placeholder="Ufficio" name="phone" />
    </div>
  );
}

export default function ClientDetailsCard({ clientId }) {
  const { client, isLoading, mutate } = useClient(clientId);
  const [isEditing, setIsEditing] = useState(false);

  const handleSubmit = (values) => {
    fetcher(`${API_URL}/clients/id/${client.id}`, {
      method: "PATCH",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          mutate();
          toast.success("Cliente aggiornato con successo");
          setIsEditing(false);
        } else {
          toast.error("Errore nell'aggiornamento del cliente");
        }
      })
      .catch((err) => {
        toast.error("Errore nell'aggiornamento del cliente");
      });
  };

  if (isLoading || !client) {
    return <SkeletonCard className="md:max-w-4xl max-w-full" />;
  }

  const initialValues = {
    tax_id: client.tax_id,
    fiscal_code_for_freelancers: client?.fiscal_code_for_freelancers,
    type: client.type,
    business_name: client?.business_name,
    first_name: client?.first_name,
    last_name: client?.last_name,
    controlling_party: client?.controlling_party,
    controlling_party_tax_id: client?.controlling_party_tax_id,
    fleet_manager: client?.fleet_manager,
    fleet_manager_tax_id: client?.fleet_manager_tax_id,
    address: client?.address,
    email: client?.email,
    pec: client?.pec,
    mobile_phone: client?.mobile_phone,
    phone: client?.phone,
    notes: client?.notes,
    user_id: client?.user?.id,
  };

  return (
    <Card2>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Form>
          <div className="flex px-1 mb-1">
            <Card2.Header>Dettagli cliente</Card2.Header>
            <Card2.EditButton
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />
          </div>
          {/* Make table with helper method */}
          {verticalTableFromRows(
            {
              labelClassName: "w-[30%]",
              valueClassName: "whitespace-normal",
            },
            [
              {
                label: "Forma giuridica",
                value: (
                  <FormikSelect
                    name="type"
                    readOnly
                    placeholder="Scegli la forma giuridica"
                    options={[
                      { value: "person", label: "Persona fisica" },
                      { value: "company", label: "Società" },
                      { value: "freelancer", label: "Professionista" },
                    ]}
                  />
                ),
              },
              {
                label:
                  client.type === "person" ? "Codice fiscale" : "Partita IVA",
                value: client.tax_id,
              },
              // If client is freelancer, show freelancer fields
              ...(client.type === "freelancer"
                ? getFreelancerFields({ client, readOnly: !isEditing })
                : []),
              getClientNameField({ client, readOnly: !isEditing }),
              {
                label: "Registrato da",
                value: (
                  <ClientCreatedBySelect
                    client={client}
                    readOnly={!isEditing}
                  />
                ),
              },
              // If client is company, show company fields
              ...(client.type === "company"
                ? getCompanyFields({ client, readOnly: !isEditing })
                : []),
              {
                label: "Indirizzo",
                value: <AddressInput client={client} readOnly={!isEditing} />,
              },
              {
                label: "Email",
                value: <EmailInput client={client} readOnly={!isEditing} />,
              },
              {
                label: "Telefono",
                value: <PhoneInput client={client} readOnly={!isEditing} />,
              },
              {
                label: "Note",
                value: (
                  <FormikTextarea
                    name="notes"
                    readOnly={!isEditing}
                    rows={4}
                    placeholder="Includere eventuali note, come per esempio se il cliente ha mai fatto Noleggio a Lungo Termine, e se sì con quale fornitore (Arval, ALD, Sifà, etc.)"
                  />
                ),
              },
            ]
          )}
        </Form>
      </Formik>
    </Card2>
  );
}
