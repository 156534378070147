import { Input } from "@/components/form/inputs";
import { SmallButton } from "@/components/ui/Button";
import Table from "@/components/ui/Table";
import { useState } from "react";
import { FaPen, FaSave, FaTimes, FaTrash } from "react-icons/fa";
import TaskLabelBadge from "./TaskLabelBadge";
import useTaskLabels from "./useTaskLabels";

function TaskLabelRowInput({ initialValues, onSave, onCancel }) {
  const [values, setValues] = useState({
    title: initialValues.title,
    description: initialValues.description,
    color: initialValues.color,
  });
  const [dirty, setDirty] = useState(false);

  const handleChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setDirty(true);
  };

  return (
    <Table.Row>
      <Table.Td>
        <Input
          placeholder="Nome dell'etichetta"
          name="title"
          required
          value={values.title}
          onChange={handleChange}
        />
      </Table.Td>
      <Table.Td>
        <Input
          placeholder="Breve descrizione dell'etichetta"
          value={values.description}
          required
          onChange={handleChange}
          name="description"
        />
      </Table.Td>
      <Table.Td>
        <div className="flex gap-2 items-center">
          <div className="w-4 h-4 rounded-full overflow-hidden">
            <input
              className="w-[200%] h-[200%] m-[-50%] p-0 cursor-pointer" // This is a hack to make the input bigger than the parent
              type="color"
              id="color-input"
              name="color"
              value={values.color}
              onChange={handleChange}
            />
          </div>
          <label
            htmlFor="color-input"
            style={{ color: values.color }}
            className="underline underline-offset-4 decoration-dotted decoration-zinc-300 decoration-2 cursor-pointer font-semibold"
          >
            {values.color}
          </label>
        </div>
      </Table.Td>
      <Table.Td>
        <div className="flex justify-end gap-2">
          <SmallButton
            className="text-white bg-blue-500 hover:bg-blue-700 hover:text-white py-1 px-3 pr-4"
            icon={<FaSave className="text-sm" />}
            disabled={
              !values.title || !values.color || !values.description || !dirty
            }
            onClick={() => onSave(values)}
          >
            Salva
          </SmallButton>
          <SmallButton
            icon={<FaTimes className="text-sm" />}
            className={
              "text-white bg-gray-400 hover:bg-gray-500 hover:text-white py-1 px-3 pr-4"
            }
            onClick={onCancel}
          >
            Annulla
          </SmallButton>
        </div>
      </Table.Td>
    </Table.Row>
  );
}

function TaskLabelRow({ label }) {
  const [isEditing, setIsEditing] = useState(false);
  const { updateLabel, deleteLabel } = useTaskLabels();

  if (isEditing) {
    return (
      <TaskLabelRowInput
        initialValues={label}
        onSave={(values) => {
          updateLabel({ id: label.id, values }).then(() => setIsEditing(false));
        }}
        onCancel={() => setIsEditing(false)}
      />
    );
  }

  return (
    <Table.Row>
      <Table.Td>
        <TaskLabelBadge className={"text-sm"} label={label} />
      </Table.Td>
      <Table.Td className="truncate">{label.description}</Table.Td>
      <Table.Td>
        <div className="flex gap-2 items-center">
          <span
            className="w-4 h-4 rounded-full"
            style={{ backgroundColor: label.color }}
          ></span>
          <span className="font-semibold" style={{ color: label.color }}>
            {label.color}
          </span>
        </div>
      </Table.Td>
      <Table.Td>
        <div className="flex justify-end gap-2">
          <SmallButton
            icon={<FaPen className="text-sm" />}
            onClick={() => setIsEditing(true)}
          >
            Modifica
          </SmallButton>
          <SmallButton
            icon={<FaTrash className="text-sm" />}
            className={"text-red-600 hover:text-red-700 hover:bg-red-100"}
            onClick={() => confirm("Sei sicuro?") && deleteLabel(label)}
          >
            Elimina
          </SmallButton>
        </div>
      </Table.Td>
    </Table.Row>
  );
}

export function TaskLabelTable({ createNew, setCreateNew, className }) {
  const { labels, isLoading, createLabel } = useTaskLabels();

  if (isLoading) return <Table.Skeleton />;

  return (
    <Table className={className}>
      <Table.Head>
        <Table.Row>
          <Table.Th className="w-[20%]">Tag</Table.Th>
          <Table.Th className="w-[40%]">Descrizione</Table.Th>
          <Table.Th className="w-[20%]">Colore</Table.Th>
          <Table.Th className="w-[20%] text-end pr-8">Azioni</Table.Th>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {createNew && (
          <TaskLabelRowInput
            initialValues={{ title: "", description: "", color: "#000000" }}
            onSave={(values) => {
              createLabel(values).then(() => setCreateNew(false));
            }}
            onCancel={() => setCreateNew(false)}
          />
        )}
        {labels.map((label) => (
          <TaskLabelRow label={label} key={label.id} />
        ))}
      </Table.Body>
    </Table>
  );
}
