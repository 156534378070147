import { clsxm } from "@/utils";
import { useField } from "formik";

export function Checkbox({ ...props }) {
  return (
    <label className={clsxm("flex items-center", props?.className)}>
      <input
        type="checkbox"
        className="w-4 h-4 text-blue-700 bg-gray-100 border-gray-300 rounded focus:ring-blue-500  focus:ring-2"
        disabled={props?.disabled || props?.readOnly}
        {...props}
      />
      <span className="ml-2 select-none">{props?.label}</span>
    </label>
  );
}

export function FormikCheckbox({ name, ...props }) {
  const [field] = useField({ name, type: "checkbox" });

  return <Checkbox {...field} {...props} />;
}
