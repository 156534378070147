import { Button } from "@/components/ui/Button";
import { clsxm } from "@/utils";
import { MdOutlineSearch } from "react-icons/md";

function Searchbar({ id, value, onChange, placeholder, className }) {
  return (
    <div className={clsxm("relative", className)}>
      <label
        htmlFor={id}
        className="relative text-gray-400 focus-within:text-gray-600 block w-full"
      >
        <MdOutlineSearch className="pointer-events-none w-6 h-6 absolute top-1/2 transform -translate-y-1/2 left-5" />
        <input
          type="text"
          id={id}
          className="w-full px-4 pl-14 py-3 text-gray-700 bg-white border border-gray-200 rounded-full appearance-none focus:outline-none focus:shadow-outline"
          placeholder={placeholder}
          value={value}
          onChange={onChange}
        />
      </label>
    </div>
  );
}

function SearchbarButton({ ...props }) {
  return (
    <Button className="rounded-full p-3 text-[1.5rem]" {...props}>
      <MdOutlineSearch />
    </Button>
  );
}

Searchbar.Button = SearchbarButton;

export default Searchbar;
