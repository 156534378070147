import { Page } from "@/components/layout/Page";
import Logo from "@/components/ui/Logo";
import { useAuth } from "@/hooks/useAuth";
import { clsxm } from "@/utils";
import { useEffect } from "react";
import { SlPin } from "react-icons/sl";
import { useLocation, useNavigate } from "react-router-dom";
import LoginForm from "./LoginForm";
import PasswordResetForm from "./PasswordResetForm";
import PasswordResetRequestForm from "./PasswordResetRequestForm";

export const authRoutes = [
  { path: "/login", element: <Login /> },
  {
    path: "/password-reset-request",
    element: <PasswordResetRequest />,
  },
  {
    path: "/password-reset/:token",
    element: <PasswordReset />,
  },
];

const useRedirectIfLoggedIn = () => {
  const { isUserAuthenticated } = useAuth();
  const location = useLocation();
  const navigate = useNavigate();

  // If the user is authenticated, redirect to correct page.
  useEffect(() => {
    if (isUserAuthenticated) {
      const { from } = location.state || { from: { pathname: "/" } };
      navigate(from);
    }
  }, [isUserAuthenticated, navigate, location]);
};

function AuthPage({ children, title }) {
  useRedirectIfLoggedIn();
  return (
    <Page
      title={title}
      className="bg-gray-100 w-full h-full flex flex-col items-center"
      fullScreen={true}
    >
      <Logo className="max-w-[10rem] w-full md:w-1/4 text-[#d50000] mb-2" />
      <div className="flex h-full w-full items-center justify-center">
        {children}
      </div>
    </Page>
  );
}

function AuthCard({ children, title, className, titleClassName }) {
  return (
    <div className={clsxm("w-1/4 flex flex-col items-center gap-1", className)}>
      <h1
        className={clsxm(
          "text-3xl font-medium text-zinc-700 mb-5 underline decoration-stone-300 w-full text-center",
          titleClassName
        )}
      >
        {title}
      </h1>
      {children}
    </div>
  );
}

function NoticeCard() {
  return (
    <div className="bg-amber-200 text-yellow-900 max-w-sm">
      <h2 className="bg-amber-300 text-xl font-semibold p-2 px-4">
        <SlPin className="inline text-2xl pb-1" /> Avvisi
      </h2>
      <div className="flex flex-col gap-3 p-6 text-sm">
        <p>
          <b>&gt;</b> Ricordiamo ai nostri utenti che l'accesso è consentito ad
          una sola persona per volta.{" "}
          <b>
            Se si accede da un altro dispositivo, la sessione corrente verrà
            terminata.
          </b>
        </p>
        <p>
          <b>&gt;</b> Se si dimentica la password, è possibile richiederne il
          ripristino tramite il link qui a fianco.
        </p>
        <p>
          <b>&gt;</b> Per qualunque problema, contattare il backoffice tramite
          e-mail all'indirizzo{" "}
          <a href="mailto:backoffice@genoleggia.it" className="underline">
            <b>backoffice@genoleggia.it</b>
          </a>
        </p>
        <p>
          <b>&gt;</b> L'unico browser ufficialmente supportato è{" "}
          <b>
            <a href="https://www.google.com/chrome/" className="underline">
              Google Chrome
            </a>
          </b>
          . Altri browser potrebbero non funzionare correttamente.
        </p>
      </div>
    </div>
  );
}

function Login() {
  return (
    <AuthPage title="Login">
      <div className="flex flex-col md:flex-row gap-4 items-center w-full">
        <div className="w-full md:w-1/2 flex justify-end md:px-16">
          <AuthCard
            title="Accedi al portale"
            className="w-2/3 min-w-[300px] max-w-[500px]"
          >
            <LoginForm />
          </AuthCard>
        </div>
        <div className="w-full md:w-1/2 md:px-16">
          <NoticeCard />
        </div>
      </div>
    </AuthPage>
  );
}

function PasswordResetRequest() {
  return (
    <AuthPage title="Richiesta reset password">
      <AuthCard title="Ripristino password" titleClassName="text-left">
        <PasswordResetRequestForm />
      </AuthCard>
    </AuthPage>
  );
}

function PasswordReset() {
  return (
    <AuthPage title="Reset password">
      <AuthCard title="Cambia password" titleClassName="text-left">
        <PasswordResetForm />
      </AuthCard>
    </AuthPage>
  );
}
