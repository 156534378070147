import { IoMdHelp } from "react-icons/io";
import { IoHelpCircleSharp } from "react-icons/io5";

export default function TutorialBadge({ ...props }) {
  return (
    <button
      className="text-blue-700 rounded-lg px-3 py-1 text-sm font-semibold border border-blue-700 flex gap-1 items-center hover:text-white hover:bg-blue-700"
      {...props}
    >
      <IoHelpCircleSharp className="text-lg" />
      Videoguida
    </button>
  );
}
