import { API_URL } from "@/config/routes";
import { fetcher } from "@/utils";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import useSWR from "swr";

// Define a userFetcher function that will be used to fetch the user data
const userFetcher = async (url) => {
  const res = await fetcher(url);
  if (res.ok) {
    return res.json();
  } else if (res.status === 401) {
    // If the user is not authenticated, set the user to null
    // so that the redirect will be triggered
    return null;
  }
};

// Function to check if the user is authenticated
const _isUserAuthenticated = (user, isLoading) => {
  return user && user?.status !== "blocked" && !isLoading;
};

export function useAuth() {
  const navigate = useNavigate();
  // Use SWR to fetch the user data
  const {
    data: user,
    isLoading,
    mutate,
  } = useSWR(`${API_URL}/users/me`, userFetcher);
  // Flag that will be used to check if the user is authenticated
  const [isUserAuthenticated, setIsUserAuthenticated] = useState(
    _isUserAuthenticated(user, isLoading)
  );

  useEffect(() => {
    setIsUserAuthenticated(_isUserAuthenticated(user, isLoading));
  }, [user, isLoading]);

  const login = async (email, password) => {
    return fetcher(`${API_URL}/auth/login`, {
      method: "POST",
      body: JSON.stringify({
        email,
        password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then(async (res) => {
      if (res.ok) {
        return res.json().then((data) => {
          // Mutate user data
          mutate(data.user);
        });
      } else {
        return res.json().then((data) => {
          throw new Error(
            data?.msg ||
              "Qualcosa è andato storto, per assistenza contattare il supporto tecnico all'email gabriele@genoleggia.it"
          );
        });
      }
    });
  };

  const logout = async () => {
    return fetcher(`${API_URL}/auth/logout`, {
      method: "POST",
    }).then((res) => {
      if (res.ok) {
        // First, initiate the redirect
        navigate("/login", { replace: true });
        // Then, mutate user data to trigger the logout state update
        mutate(null, false); // The second argument `false` prevents revalidation
      } else {
        res.json().then((data) => {
          console.log(data);
        });
      }
    });
  };

  const requestPasswordReset = async (email) => {
    return fetcher(`${API_URL}/auth/request-password-reset`, {
      method: "POST",
      body: JSON.stringify({
        email,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.ok) {
        return res.json().then((data) => {
          return data;
        });
      } else {
        return res.json().then((data) => {
          throw new Error(data.msg);
        });
      }
    });
  };

  const resetPassword = async (token, password) => {
    return fetcher(`${API_URL}/auth/reset-password`, {
      method: "POST",
      body: JSON.stringify({
        token,
        password,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      if (res.ok) {
        return res.json().then((data) => {
          return data;
        });
      } else {
        return res.json().then((data) => {
          throw new Error(data.msg);
        });
      }
    });
  };

  return {
    user,
    login,
    logout,
    isLoading,
    isUserAuthenticated,
    requestPasswordReset,
    resetPassword,
    mutate,
  };
}
