import { FormikInput } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { useAuth } from "@/hooks/useAuth";
import { Form, Formik } from "formik";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

export default function PasswordResetForm() {
  const { resetPassword } = useAuth();
  const { token } = useParams();
  const navigate = useNavigate();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await resetPassword(token, values.password);
      toast.success(
        "La tua password è stata aggiornata con successo! Ora puoi effettuare il login."
      );
      resetForm();
      navigate("/login");
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col items-center justify-center w-full">
      <Formik
        initialValues={{ password: "", password_confirmation: "" }}
        onSubmit={handleSubmit}
        validate={(values) => {
          const errors = {};
          if (values.password !== values.password_confirmation) {
            errors.password_confirmation = "Le password non coincidono";
          }
          return errors;
        }}
      >
        {({ dirty, isSubmitting, isValid }) => (
          <Form className="w-full">
            <FormikInput
              className="mb-2"
              label="Password"
              name="password"
              type="password"
              placeholder="Inserisci la tua nuova password"
              required
            />
            <FormikInput
              label="Conferma password"
              name="password_confirmation"
              type="password"
              placeholder="Inserisci di nuovo la tua nuova password"
              required
            />

            <Button
              className="mt-6 w-full"
              type="submit"
              isLoading={isSubmitting}
              disabled={!dirty || isSubmitting || !isValid}
            >
              <span className="w-full text-center">Conferma modifica</span>
            </Button>
          </Form>
        )}
      </Formik>
    </div>
  );
}
