import { ErrorBoundary as ReactErrorBoundary } from "react-error-boundary";
import { BsArrowClockwise, BsArrowLeft } from "react-icons/bs";
import { MdErrorOutline } from "react-icons/md";
import { useNavigate } from "react-router-dom";

function Fallback({ error }) {
  const navigate = useNavigate();
  return (
    <div
      role="alert"
      className="flex flex-col items-center justify-center h-screen gap-5"
    >
      <MdErrorOutline className="text-6xl text-red-600" />
      <h1 className="text-4xl font-medium text-zinc-800 leading-none">
        Si è verificato un errore
      </h1>
      <p className="text-red-700 font-mono px-5 py-2 bg-red-100 rounded">
        {error.message}
      </p>
      <div className="flex items-center gap-3">
        <button
          className="flex items-center justify-center px-4 pr-5 py-2 text-sm font-medium text-white bg-blue-600 border border-transparent rounded-md hover:bg-blue-700 focus:outline-none"
          onClick={() => navigate(-1)}
        >
          <BsArrowLeft className="mr-2 text-lg" />
          Torna indietro
        </button>
        <button
          className="flex items-center justify-center px-4 pr-5 py-2 text-sm font-medium text-white bg-zinc-600 border border-transparent rounded-md hover:bg-zinc-700 focus:outline-none"
          onClick={() => window.location.reload()}
        >
          <BsArrowClockwise className="mr-2 text-lg" />
          Ricarica la pagina
        </button>
      </div>
    </div>
  );
}

export function ErrorBoundary({ children }) {
  return (
    <ReactErrorBoundary FallbackComponent={Fallback}>
      {children}
    </ReactErrorBoundary>
  );
}
