import Dropzone from "@/components/form/Dropzone";
import Label from "@/components/form/Label";
import { Checkbox, Select } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { API_URL } from "@/config/routes";
import { fetcher } from "@/utils";
import { useState } from "react";
import { FaArrowsRotate } from "react-icons/fa6";
import { toast } from "react-toastify";

const RENTING_COMPANIES = [
  "ALD",
  "Alphabet",
  "Arval",
  "Athlon",
  "Drivalia",
  "Leaseplan",
  "Leasys",
  "Volkswagen Leasing",
];

function FastDeliveryRentingCompanySelect({
  rentingCompany,
  setRentingCompany,
}) {
  return (
    <Select
      options={
        RENTING_COMPANIES.map((company) => ({
          label: company,
          value: company,
        })) || []
      }
      value={rentingCompany}
      onChange={(e) => setRentingCompany(e.target.value)}
    ></Select>
  );
}

function FastDeliveryDropzone({ files, setFiles }) {
  return (
    <Dropzone
      subtitle=".csv, .xls, .xlsx, .ods"
      files={files}
      setFiles={setFiles}
      maxFiles={1}
    />
  );
}

// Form to have the user select a `renting_company` and then upload a CSV file with the data to be imported.
export default function FastDeliveryUpdateForm() {
  const [rentingCompany, setRentingCompany] = useState("");
  const [defaultParser, setDefaultParser] = useState(false);
  const [files, setFiles] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Handle submit: send the data to the backend to be processed.
  const handleSubmit = (e) => {
    e.preventDefault();
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append("renting_company", rentingCompany);
    formData.append("default_parser", defaultParser);
    formData.append("file", files[0]);

    fetcher(`${API_URL}/fast-delivery`, {
      method: "POST",
      body: formData,
    })
      .then((res) => {
        if (res.ok) {
          toast.success("Veicoli in pronta consegna aggiornati correttamente");
        } else {
          // Throw an error with the message
          return res.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .catch((err) => {
        toast.error(
          <span className="p-1 rounded text-xs bg-red-200 text-red-800 font-mono">
            Errore:{" "}
            <b>
              {err.message ||
                "Si è verificato un errore durante l'aggiornamento dei veicoli in pronta consegna"}
            </b>
          </span>
        );
      })
      .finally(() => {
        // Reset the form
        setRentingCompany("");
        setFiles([]);
        setIsSubmitting(false);
      });
  };

  return (
    <form onSubmit={handleSubmit}>
      <div className="mt-8">
        <Label>Seleziona il fornitore</Label>
      </div>
      <div className="flex gap-4 mt-2">
        <div className="w-1/3">
          <FastDeliveryRentingCompanySelect
            rentingCompany={rentingCompany}
            setRentingCompany={setRentingCompany}
          />
          <div className="mt-4 text-zinc-800">
            <Label className="mb-2">Parser</Label>  
            <Checkbox
              label="Utilizza il formato Standard"
              checked={defaultParser}
              onChange={(e) => setDefaultParser(e.target.checked)}
            />
          </div>
        </div>
        <div className="w-2/3">
          <FastDeliveryDropzone files={files} setFiles={setFiles} />
        </div>
      </div>
      <div className="flex justify-end mt-6">
        <Button
          disabled={rentingCompany === "" || files.length === 0 || isSubmitting}
          icon={<FaArrowsRotate className="text-xl" />}
          isRotating={isSubmitting}
        >
          Aggiorna veicoli in pronta consegna
        </Button>
      </div>
    </form>
  );
}
