import { clsxm } from "@/utils";

export default function UserStatusSpan({ status }) {
  const statusMap = {
    active: { label: "Attivo", color: "text-green-500" },
    pending: { label: "Invitato", color: "text-amber-500" },
    blocked: { label: "Sospeso", color: "text-red-500" },
  };

  return (
    <span className={clsxm("flex items-center")}>
      <span
        className={clsxm(
          "mr-1 text-lg",
          statusMap[status]?.color || "text-gray-500"
        )}
      >
        •
      </span>
      {statusMap[status]?.label || "Sconosciuto"}
    </span>
  );
}
