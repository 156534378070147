import { clsxm } from "@/utils";
import Tippy from "@tippyjs/react";
import React from "react";

export function Tooltip({ content, placement, children, className }) {
  return (
    <Tippy
      // animation="scale"
      // inertia={true}
      duration={0} // disable animation
      content={content}
      placement={placement ?? "top"}
      className={clsxm(
        "bg-zinc-800/90 text-white text-base font-medium px-2 py-1 rounded",
        className
      )}
    >
      {children}
    </Tippy>
  );
}
