import { FormikInput, FormikTextarea } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { verticalTableFromRows } from "@/components/ui/Table";
import { API_URL } from "@/config/routes";
import { fetcher } from "@/utils";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function TaskCreateForm() {
  const INITIAL_VALUES = {
    title: "",
    description: "",
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    fetcher(`${API_URL}/tasks`, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((data) => {
        toast.success(
          <span>
            Richiesta <b>#{data.id}</b> creata con successo!{" "}
            <Link to={`/tasks/${data.id}`} className="text-blue-700 underline">
              Clicca qui
            </Link>{" "}
            per visualizzarla.
          </span>
        );
        resetForm(INITIAL_VALUES);
      })
      .catch((errorRes) => {
        errorRes.json().then((errorData) => {
          toast.error(errorData.message);
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
      {({ isSubmitting, isValid, dirty, resetForm }) => (
        <Form>
          {verticalTableFromRows(
            {
              labelClassName: "w-1/3",
              valueClassName: "w-2/3",
            },
            [
              {
                label: "Titolo",
                value: (
                  <FormikInput
                    type="text"
                    name="title"
                    placeholder="Inserisci il titolo"
                    required
                  />
                ),
              },
              {
                label: "Descrizione",
                value: (
                  <FormikTextarea
                    rows={3}
                    name="description"
                    placeholder="Scrivi cosa desideri aggiungere, modificare o segnalare nell'applicazione o riguardante Genoleggia in generale."
                  />
                ),
              },
            ]
          )}
          <div className="mt-4 flex gap-2 justify-end">
            <Button
              type="button"
              className="bg-gray-300 hover:bg-gray-400"
              onClick={() => resetForm(INITIAL_VALUES)}
            >
              Reset
            </Button>
            <Button type="submit" disabled={isSubmitting || !isValid || !dirty}>
              Crea richiesta
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
