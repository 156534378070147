import { clsxm } from "@/utils";
import { useFormikContext } from "formik";
import { FaPen } from "react-icons/fa";
import { FiArrowLeft, FiEdit2, FiSave } from "react-icons/fi";
import { SmallButton } from "./Button";

export default function Card({ onClick, children, className }) {
  return (
    <div
      className={clsxm(
        "bg-white shadow-sm border rounded-md overflow-hidden",
        { "cursor-pointer": onClick },
        className
      )}
      onClick={onClick}
    >
      {children}
    </div>
  );
}

function Card2(props) {
  return (
    <div
      className={clsxm(
        "flex flex-col gap-1 rounded py-2 px-2 bg-slate-100 shadow-inner shadow-slate-200 ",
        props?.className
      )}
    >
      {props?.children}
    </div>
  );
}

function Card2Header(props) {
  return (
    <h1
      className={clsxm(
        "font-semibold font-mono uppercase text-slate-600 py-1 px-2",
        props?.className
      )}
    >
      {props?.children}
    </h1>
  );
}

function Card2EditButton({ isEditing, setIsEditing, noCancelButton }) {
  // Buttons to edit and save a form. Will only work if the form is wrapped in a Formik component.
  const { isSubmitting, dirty, resetForm } = useFormikContext();

  return (
    <div className="text-sm flex items-center ml-auto">
      {!isEditing ? (
        <SmallButton
          type="button"
          onClick={() => setIsEditing(true)}
          icon={<FaPen className="text-xs" />}
        >
          Modifica
        </SmallButton>
      ) : (
        <>
          {!noCancelButton && (
            <SmallButton
              type="button"
              className="text-gray-500 mr-1 hover:bg-transparent hover:text-gray-700"
              onClick={() => {
                setIsEditing(false);
                resetForm();
              }}
              icon={<FiArrowLeft />}
            >
              Annulla
            </SmallButton>
          )}
          <SmallButton
            type="submit"
            icon={<FiSave />}
            disabled={isSubmitting || !dirty}
          >
            Salva
          </SmallButton>
        </>
      )}
    </div>
  );
}

Card2.Header = Card2Header;
Card2.EditButton = Card2EditButton;
export { Card2 };

export function SkeletonCard({ className }) {
  return (
    <Card2 className={className}>
      {/* Pulsating CardHeader placeholder */}
      <div className="w-1/6 h-6 py-4 bg-slate-200 rounded animate-pulse"></div>
      {/* Pulsating CardBody placeholder */}
      <div className="flex flex-col gap-2 mt-6">
        <div className="w-3/4 h-8 bg-slate-200 rounded animate-pulse"></div>
        <div className="w-2/3 h-8 bg-slate-200 rounded animate-pulse"></div>
        <div className="w-1/4 h-4 bg-slate-200 rounded animate-pulse mt-4"></div>
        <div className="w-1/2 h-4 bg-slate-200 rounded animate-pulse"></div>
        <div className="w-3/4 h-8 bg-slate-200 rounded animate-pulse"></div>
        <div className="w-2/3 h-8 bg-slate-200 rounded animate-pulse"></div>
        <div className="w-4/5 h-24 bg-slate-200 rounded animate-pulse mt-4"></div>
        <div className="w-1/3 h-16 bg-slate-200 rounded animate-pulse mt-4"></div>
      </div>
    </Card2>
  );
}
