import { clsxm } from "@/utils";
import { Editor } from "@tinymce/tinymce-react";
import { useRef, useState } from "react";
import { SkeletonCard } from "../ui/Card";

export default function RichTextEditor({ content, setContent }) {
  const editorRef = useRef(null);
  const [isReady, setIsReady] = useState(false);

  // Note: we are using the editor as a CONTROLLED component,
  // which means that it needs to recode the content from the
  // props every time it changes. This could have performance
  // issues if the content is too big.

  return (
    <>
      {!isReady && <SkeletonCard className="m-0" />}
      <div className={clsxm(!isReady && "hidden")}>
        <Editor
          apiKey={import.meta.env.VITE_TINYMCE_API_KEY}
          onInit={(evt, editor) => (editorRef.current = editor)}
          value={content}
          onEditorChange={(content) => setContent(content)}
          init={{
            language: "it",
            language_url: "/public/tinymce/it.js",
            menubar: false,
            plugins: [
              "advlist",
              "anchor",
              "autolink",
              "code",
              "lists",
              "link",
              "image",
              "charmap",
              "preview",
              "searchreplace",
              "visualblocks",
              "code",
              "insertdatetime",
              //   "media",
              "table",
              "wordcount",
            ],
            toolbar:
              "undo redo | blocks | " +
              "bold italic forecolor | alignleft aligncenter " +
              "alignright alignjustify | bullist numlist outdent indent | " +
              "removeformat",
            content_style:
              "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
            setup: function (editor) {
              editor.on("init", function (e) {
                setIsReady(true);
              });
            },
          }}
        />
      </div>
    </>
  );
}
