import { Page, PageHeader } from "@/components/layout/Page";
import { Card2 } from "@/components/ui/Card";
import { LoadingDots } from "@/components/ui/Loading";
import { useParams } from "react-router-dom";
import { DealChatFloatingButton } from "./DealChat";
import { DealChatProvider } from "./DealChatProvider";
import DealCreateForm from "./DealCreateForm";
import DealDetails from "./DealDetails";
import DealsDataTable from "./DealsDataTable";
import useDeal from "./useDeal";

export const dealsRoutes = [
  { path: "/deals/new", element: <DealCreate /> },
  { path: "/deals/:id", element: <DealDetailsPage /> },
  { path: "/deals", element: <DealList /> },
];

function DealCreate() {
  return (
    <Page title="Nuova trattativa">
      <PageHeader
        breadcrumbs={[
          { label: "Trattative", href: "/deals" },
          { label: "Nuova trattativa" },
        ]}
      />
      <Card2 className="flex flex-col gap-2 w-[75%]">
        <Card2.Header>Inserisci i dettagli della trattativa</Card2.Header>
        <DealCreateForm />
      </Card2>
    </Page>
  );
}

function DealList() {
  return (
    <Page title="Trattative">
      <PageHeader
        breadcrumbs={[{ label: "Trattative" }]}
        description="Qui puoi visualizzare e filtrare le tue trattative"
        topRight={<DealsDataTable.Buttons />}
      />
      <DealsDataTable />
    </Page>
  );
}

function DealDetailsPage() {
  const { id } = useParams();
  const { deal } = useDeal(id);

  return (
    <Page title={`Trattativa #${deal?.id}`}>
      <div className="flex flex-col">
        <PageHeader
          breadcrumbs={[
            { label: "Trattative", href: "/deals" },
            { label: deal?.pretty_id ?? <LoadingDots /> },
          ]}
          description="Questa pagina ti permette di visualizzare e modificare i dettagli di questa trattativa"
        />

        <div className="flex flex-col gap-3">
          <DealChatProvider>
            <DealDetails />
            <DealChatFloatingButton />
          </DealChatProvider>
        </div>
      </div>
    </Page>
  );
}
