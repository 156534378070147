import { Page, PageHeader } from "@/components/layout/Page";
import { Card2 } from "@/components/ui/Card";
import { LoadingDots } from "@/components/ui/Loading";
import { useParams } from "react-router-dom";
import { DealChatProvider } from "../deals/DealChatProvider";
import DealDetails from "../deals/DealDetails";
import ContractCreateForm from "./ContractCreateForm";
import ContractDataTable from "./ContractDataTable";
import ContractDetails from "./ContractDetails";
import useContract from "./useContract";

export const contractRoutes = [
  { path: "/contracts/new", element: <ContractCreate /> },
  { path: "/contracts/:id", element: <ContractDetailsPage /> },
  { path: "/contracts", element: <ContractList /> },
];

function ContractCreate() {
  return (
    <Page title="Nuovo contratto">
      <PageHeader
        breadcrumbs={[
          { label: "Contratti", href: "/contracts" },
          { label: "Nuovo contratto" },
        ]}
      />
      <Card2 className="flex flex-col gap-2 w-[75%]">
        <Card2.Header>Inserisci i dettagli del contratto</Card2.Header>
        <ContractCreateForm />
      </Card2>
    </Page>
  );
}

function ContractList() {
  return (
    <Page title="Contratti">
      <PageHeader
        breadcrumbs={[{ label: "Contratti" }]}
        description="Qui puoi visualizzare e filtrare i tuoi contratti"
        topRight={<ContractDataTable.Buttons />}
      />
      <ContractDataTable />
    </Page>
  );
}

function ContractDetailsPage() {
  const { id } = useParams();
  const { contract, isLoading } = useContract(id);

  return (
    <Page title={`Contratto ${contract?.pretty_id}`}>
      <div className="flex flex-col">
        <PageHeader
          breadcrumbs={[
            { label: "Contratti", href: "/contracts" },
            { label: contract?.pretty_id ?? <LoadingDots /> },
          ]}
          description="Questa pagina ti permette di visualizzare e modificare i dettagli di questo contratto"
        />
        <ContractDetails />
      </div>
    </Page>
  );
}
