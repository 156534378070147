import { clsxm } from "@/utils";
import { useField } from "formik";
import React, { useEffect, useState } from "react";
import { FiChevronDown } from "react-icons/fi";
import { default as ReactSelect, components } from "react-select";
import { default as ReactCreatableSelect } from "react-select/creatable";
import Label from "../Label";
import { Checkbox } from "./Checkbox";

const DropdownIndicator = (props) => {
  return (
    <components.DropdownIndicator {...props}>
      <FiChevronDown />
    </components.DropdownIndicator>
  );
};

// Define the styles for the react-select component
const controlStyles = {
  base: "border rounded-lg bg-white hover:cursor-pointer",
  focus: "ring-1 ring-blue-100",
  nonFocus: "hover:border-gray-300",
  disabled: "bg-gray-100 text-gray-500 hover:cursor-not-allowed",
};
const placeholderStyles = "text-gray-500 pl-1 py-0.5";
const selectInputStyles = "pl-1";
const valueContainerStyles = "px-1 gap-1";
const singleValueStyles = "ml-1";
const indicatorsContainerStyles = "p-1 gap-1";
const clearIndicatorStyles =
  "text-gray-500 p-1 rounded-md hover:bg-red-50 hover:text-red-800";
const indicatorSeparatorStyles = "";
const dropdownIndicatorStyles =
  "p-1 hover:bg-gray-100 text-gray-500 rounded-md hover:text-black";
const menuStyles = "p-1 mt-2 border border-gray-200 bg-white rounded-lg";
const groupHeadingStyles = "ml-3 mt-2 mb-1 text-gray-500 text-sm";
const optionStyles = {
  base: "hover:cursor-pointer px-3 py-2 rounded",
  focus: "bg-gray-100 active:bg-gray-200",
  selected: "bg-blue-50 hover:bg-blue-100 active:bg-blue-200",
};
const noOptionsMessageStyles =
  "text-gray-500 p-2 bg-gray-50 border border-dashed border-gray-200 rounded-sm";

// Define the class names for the react-select component
const reactSelectClassNames = {
  control: ({ isFocused, isDisabled }) =>
    clsxm(
      controlStyles.base,
      isDisabled ? controlStyles.disabled : "",
      isFocused ? controlStyles.focus : controlStyles.nonFocus
    ),
  placeholder: () => placeholderStyles,
  input: () => selectInputStyles,
  valueContainer: () => valueContainerStyles,
  singleValue: () => singleValueStyles,
  indicatorsContainer: () => indicatorsContainerStyles,
  clearIndicator: () => clearIndicatorStyles,
  indicatorSeparator: () => indicatorSeparatorStyles,
  dropdownIndicator: () => dropdownIndicatorStyles,
  menu: () => menuStyles,
  groupHeading: () => groupHeadingStyles,
  option: ({ isFocused, isSelected }) =>
    clsxm(
      isFocused && optionStyles.focus,
      isSelected && optionStyles.selected,
      optionStyles.base
    ),
  noOptionsMessage: () => noOptionsMessageStyles,
};

export function Select({ options, readOnly, value, onChange, ...props }) {
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    // Single value select
    if (!props.isMulti) {
      // If `value` is not defined or is an object, set selectedOption to `value`
      if (!value || typeof value === "object") {
        setSelectedOption(value);
      }
      // If value is defined and is not an object, find the corresponding option
      else {
        const matchingOption = options.find((option) => option.value === value);
        // If not matching option is found, set selectedOption to an obj
        // with label and value set to`value`
        setSelectedOption(matchingOption || { value, label: value });
      }
    }
    // Multi value select
    else {
      // If `value` is not defined or is an array of objects, set selectedOption to `value`
      if (!value || typeof value[0] === "object") {
        setSelectedOption(value);
      }
      // If value is defined and is not an array of objects, find the corresponding options
      else {
        const matchingOptions = options.filter((option) =>
          value.includes(option.value)
        );
        setSelectedOption(matchingOptions);
      }
    }
  }, [value, options]);

  if (readOnly) {
    return (
      <>
        <div className={clsxm("flex flex-col", props?.className)}>
          {props?.label && <Label htmlFor={props?.name}>{props.label}</Label>}
          <span>{selectedOption?.label}</span>
          <input type="hidden" name={props?.name} value={value} />
        </div>
      </>
    );
  }

  const filterOption = (option, rawInput) => {
    const searchTerm = rawInput.toLowerCase();
    const optionValue = option.data?.filterKey || option.data?.label;
    return optionValue.toString().toLowerCase().includes(searchTerm);
  };

  // If the `isCreatable` prop is passed, use the creatable version of react-select
  var _ReactSelect = props?.isCreatable ? ReactCreatableSelect : ReactSelect;

  return (
    <div className={clsxm("w-full", props?.className)}>
      {props?.label && <Label htmlFor={props?.name}>{props.label}</Label>}
      <div className="relative w-full h-full">
        <_ReactSelect
          isMulti={false}
          closeMenuOnSelect={true}
          hideSelectedOptions={false}
          placeholder="Seleziona..."
          noOptionsMessage={() => "Nessuna opzione disponibile"}
          backspaceRemovesValue={true}
          filterOption={filterOption}
          unstyled
          components={{ DropdownIndicator }}
          classNames={reactSelectClassNames}
          options={options}
          {...props}
          value={selectedOption}
          onChange={(option) => {
            setSelectedOption(option);
            if (onChange && option && !props.isMulti) {
              onChange({
                target: {
                  name: props.name,
                  ...option,
                },
              });
            } else if (onChange && props.isMulti) {
              onChange(option);
            }
          }}
        />
        {props?.required && (
          <span
            className="absolute -top-2 -right-2.5 flex items-center pr-2 text-lg select-none text-gray-400"
            title="Campo obbligatorio"
          >
            *
          </span>
        )}
      </div>
    </div>
  );
}

export function FormikSelect({ name, ...props }) {
  const [field, meta, helpers] = useField(name);

  return (
    <Select
      {...props}
      {...field}
      name={name}
      error={meta.touched && meta.error}
      onChange={(event) => {
        helpers.setValue(event.target.value);
      }}
    />
  );
}

export function CreatableSelect({ options, ...props }) {
  return <Select isCreatable options={options} {...props} />;
}

export function FormikCreatableSelect({ name, ...props }) {
  const [field, meta, helpers] = useField(name);

  return (
    <Select
      isCreatable
      {...props}
      {...field}
      name={name}
      error={meta.touched && meta.error}
      onChange={(event) => {
        helpers.setValue(event.target.value);
      }}
    />
  );
}

const MultiSelectInputOption = ({ isSelected, children, ...props }) => {
  return (
    <components.Option {...props} isSelected={isSelected}>
      <Checkbox checked={isSelected} />
      {children}
    </components.Option>
  );
};

const MultiSelectValueContainer = ({ data, selectProps, ...props }) => {
  const allOptions = selectProps.value;
  const firstOption = allOptions[0];

  // Only show the message for the first selected option
  if (firstOption === data) {
    return (
      <components.MultiValueContainer
        {...props}
        data={data}
        selectProps={selectProps}
      >
        <span className="text-gray-600 pl-2">{`${allOptions.length} opzioni selezionate`}</span>
      </components.MultiValueContainer>
    );
  }

  // For other selected options, render nothing
  return null;
};

const multiSelectPropsBag = {
  isMulti: true,
  closeMenuOnSelect: false,
  hideSelectedOptions: false,
  isSearchable: false,
  backspaceRemovesValue: false,
  isClearable: false,
  components: {
    DropdownIndicator,
    Option: MultiSelectInputOption,
    MultiValueContainer: MultiSelectValueContainer,
  },
  classNames: {
    ...reactSelectClassNames,
    option: ({ isSelected }) =>
      clsxm(
        isSelected && "bg-white active:bg-blue-100",
        "hover:cursor-pointer px-3 py-2 rounded hover:bg-gray-50 !flex !items-center !gap-1"
      ),
  },
};

export function MultiSelect({ ...props }) {
  return <Select {...multiSelectPropsBag} {...props} />;
}

export function FormikMultiSelect({ name, ...props }) {
  const [field, meta, helpers] = useField(name);

  return (
    <Select
      {...multiSelectPropsBag}
      {...props}
      {...field}
      name={name}
      error={meta.touched && meta.error}
      onChange={(option) => {
        helpers.setValue(option.map((o) => o.value));
      }}
    />
  );
}

export function BadgeSelect({
  icon,
  prefix,
  isMulti,
  children,
  className,
  ...props
}) {
  return (
    <div
      className={clsxm(
        "text-zinc-800 select-none rounded-lg bg-gray-200 flex text-sm rounded-r-3xl",
        className
      )}
    >
      <div className="font-semibold whitespace-nowrap flex items-center pl-4 pr-3.5">
        {icon && <div className="mr-2 text-base">{icon}</div>}
        {prefix}
      </div>
      {/* If no children are passed, render a the specified select */}
      {!children && isMulti && <MultiSelect {...props} />}
      {!children && !isMulti && <Select {...props} />}
      {/* If children are passed, render those instead */}
      {children}
    </div>
  );
}
