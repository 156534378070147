import { Page, PageHeader } from "@/components/layout/Page";
import AccessLogsDataTable from "./AccessLogsDataTable";

export const accessLogsRoutes = [
  { path: "/access-logs", element: <AccessLogs /> },
];

export default function AccessLogs() {
  return (
    <Page title="Log accessi">
      <PageHeader
        breadcrumbs={[{ label: "Log accessi" }]}
        description="Visualizza i log degli accessi sul portale"
      />
      <AccessLogsDataTable />
    </Page>
  );
}
