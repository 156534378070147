import Pagination from "@/components/form/Pagination";
import { Searchbar } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import Table from "@/components/ui/Table";
import { logos as rentingCompaniesLogosSrc } from "@/config/rentingCompanies";
import { API_URL } from "@/config/routes";
import { useAuth } from "@/hooks/useAuth";
import { clsxm, fetcher, formatDate, objectToQueryString } from "@/utils";
import { useEffect, useState } from "react";
import { FaArrowsRotate } from "react-icons/fa6";
import { GoInfo } from "react-icons/go";
import { Link, useSearchParams } from "react-router-dom";
import useSWR from "swr";

import { FastDeliveryDetailModal } from "./FastDeliveryDetailModal";

async function SWRFetcher({ url, params }) {
  return fetcher(url + "?" + objectToQueryString(params)).then((res) =>
    res.json()
  );
}

function FastDeliveryVehicleAvailability({ date, status }) {
  // If the vehicle has no date and no status, we show an error
  if (!date && !status) status = "error";

  const getDefaultOption = () => {
    if (!date) return "??? + 90-120 gg";
    if (new Date(date) > new Date())
      return `${formatDate(date, "short")} + 90-120 gg`;
    return "90-120 gg";
  };

  const options = {
    HAS_LICENSE: "60-90 gg",
    HAS_CHASSIS: "90-120 gg",
    default: getDefaultOption(),
    error: "??",
  };

  const styles = {
    HAS_LICENSE: "bg-blue-500",
    HAS_CHASSIS: "bg-emerald-500",
    default: "bg-zinc-400",
  };

  return (
    <span
      className={clsxm(
        "py-1 px-2 font-semibold rounded-lg text-white text-xs shadow-inner shadow-black/20",
        styles[status] || styles["default"]
      )}
    >
      {options[status] || options["default"]}
    </span>
  );
}

function FastDeliveryTableRow({ vehicle }) {
  const [showDetails, setShowDetails] = useState(false);

  return (
    <>
      <FastDeliveryDetailModal
        isOpen={showDetails}
        vehicle={vehicle}
        onClose={() => setShowDetails(false)}
      />
      <Table.Row>
        <Table.Td className="pl-8" title={vehicle.full_name}>
          <div
            className="group flex items-center gap-3 font-medium underline-offset-2 decoration-dotted underline decoration-zinc-500 hover:cursor-pointer hover:decoration-2 hover:decoration-solid hover:decoration-blue-200 hover:text-blue-800"
            onClick={() => setShowDetails(true)}
          >
            <GoInfo className="text-xl text-zinc-600 flex-shrink-0 group-hover:text-current" />
            <span className="truncate">{vehicle.full_name}</span>
          </div>
        </Table.Td>
        <Table.Td
          className="truncate uppercase font-mono text-zinc-700"
          title={vehicle.color}
        >
          {vehicle.color ?? "-"}
        </Table.Td>
        <Table.Td className="flex items-center justify-center">
          <img
            title={vehicle.renting_company}
            src={rentingCompaniesLogosSrc[vehicle.renting_company]}
            alt={vehicle.renting_company}
            className="h-6 animate-fadeIn"
          />
        </Table.Td>
        <Table.Td className="text-center">
          <FastDeliveryVehicleAvailability
            date={vehicle.available_from}
            status={vehicle.status}
          />
        </Table.Td>
        <Table.Td className="text-center font-mono font-semibold">
          <span
            className={clsxm(
              "py-1 px-2 font-semibold rounded-lg text-white text-xs shadow-inner shadow-black/20",
              vehicle.quantity < 5 && "bg-red-500",
              vehicle.quantity >= 5 && vehicle.quantity < 10 && "bg-yellow-500",
              vehicle.quantity >= 10 && "bg-green-500"
            )}
          >
            {vehicle.quantity}
          </span>
        </Table.Td>
      </Table.Row>
    </>
  );
}

function FastDeliveryTableHeader({ filters, onFiltersChange }) {
  const onSortChange = (sort_by) => {
    onFiltersChange({
      sort_by: sort_by,
      order:
        filters.sort_by === sort_by && filters.order === "desc"
          ? "asc"
          : "desc",
    });
  };

  return (
    <Table.Head>
      <Table.Row>
        <Table.Th
          className="w-[40%] pl-16"
          sortable
          sortDirection={
            filters.sort_by === "full_name" ? filters.order : undefined
          }
          onClick={() => onSortChange("full_name")}
        >
          Veicolo
        </Table.Th>
        <Table.Th className="w-[15%]">Colore</Table.Th>
        <Table.Th className="w-[15%] text-center">Noleggiatore</Table.Th>
        <Table.Th className="w-[15%] text-center">Arriva tra</Table.Th>
        <Table.Th
          className="w-[15%] text-center"
          sortable
          sortDirection={
            filters.sort_by === "quantity" ? filters.order : undefined
          }
          onClick={() => onSortChange("quantity")}
        >
          Quantità
        </Table.Th>
      </Table.Row>
    </Table.Head>
  );
}

function FastDeliveryTable({ vehicles, filters, onFiltersChange }) {
  return (
    <Table className="w-full table-fixed">
      <FastDeliveryTableHeader
        filters={filters}
        onFiltersChange={onFiltersChange}
      />
      <Table.Body>
        {!vehicles &&
          Array.from({ length: 30 }).map((_, i) => (
            <Table.SkeletonRow key={i} colsNumber={5}></Table.SkeletonRow>
          ))}
        {vehicles &&
          vehicles.map((vehicle) => (
            <FastDeliveryTableRow key={vehicle.id} vehicle={vehicle} />
          ))}
      </Table.Body>
    </Table>
  );
}

function FastDeliverySearchbar({ onSubmit, className }) {
  const [searchTerm, setSearchTerm] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(searchTerm);
  };

  return (
    <form
      className={clsxm("w-full flex gap-2", className)}
      onSubmit={handleSubmit}
    >
      <Searchbar
        id="search-deals"
        value={searchTerm}
        onChange={(e) =>
          setSearchTerm(e.target.value !== "" ? e.target.value : undefined)
        }
        placeholder="Cerca veicolo per nome o per noleggiatore"
        className="flex-grow"
      />
      <Searchbar.Button />
    </form>
  );
}

const DEFAULT_QUERY_PARAMS = {
  page: 1,
  per_page: 30,
  sort_by: "full_name",
  order: "asc",
  q: undefined,
};

function FastDeliveryButtons() {
  const { user } = useAuth();
  return (
    <>
      {user.role === "admin" && (
        <Link to="/fast-delivery/update">
          <Button
            className="py-4"
            icon={<FaArrowsRotate className="text-xl" />}
          >
            Aggiorna veicoli in stock
          </Button>
        </Link>
      )}
    </>
  );
}

export default function FastDeliveryDataTable({ status }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParams] = useState({
    ...DEFAULT_QUERY_PARAMS,
    ...Object.fromEntries(searchParams),
    status, // This way we can pass the status from the parent component
  });

  // Fetch the data
  const data = useSWR(
    { url: `${API_URL}/fast-delivery`, params: queryParams },
    SWRFetcher
  );

  // Whenever the params change, we want to reset the page to 1
  const handleParamsChange = (params) => {
    setQueryParams({ ...queryParams, ...params, page: 1 });
  };

  // Whenever the query params change, we want to update the search params
  useEffect(() => {
    const filteredEntries = Object.entries(queryParams).filter(
      ([, value]) => value !== undefined
    );
    setSearchParams(filteredEntries);
  }, [queryParams]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        {/* Search bar */}
        <FastDeliverySearchbar
          className="w-[50%]"
          onSubmit={(searchTerm) => handleParamsChange({ q: searchTerm })}
        />
      </div>
      {/* The table */}
      <FastDeliveryTable
        vehicles={data.data?.items}
        filters={queryParams}
        onFiltersChange={(filters) => handleParamsChange({ ...filters })}
      />
      <div className="flex justify-end">
        <Pagination
          onPageChange={(page) => setQueryParams({ ...queryParams, page })}
          currentPage={queryParams.page}
          totalPages={Math.ceil(data.data?.total / queryParams.per_page)}
          perPage={queryParams.per_page}
          showTotal
        />
      </div>
    </div>
  );
}

FastDeliveryDataTable.Buttons = FastDeliveryButtons;
