import { clsxm } from "@/utils";
import { CgSpinner } from "react-icons/cg";

export function Loading({ className }) {
  return (
    <span className={clsxm("flex items-center space-x-2 p-4", className)}>
      <CgSpinner className="text-2xl animate-spin" />
      <span>Caricamento in corso</span>
    </span>
  );
}

export function LoadingSpinner({ className }) {
  return <CgSpinner className={clsxm("animate-spin", className)} />;
}

export function FallbackSpinner({ children, isLoading, className }) {
  return isLoading ? (
    <LoadingSpinner className={clsxm("text-lg", className)} />
  ) : (
    children
  );
}

export function LoadingDots({ centered = false, ...props }) {
  const viewBoxCentered = "0 0 24 24";
  const viewBoxBaseline = "0 0 24 7";
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1em"
      height="1em"
      viewBox={centered ? viewBoxCentered : viewBoxBaseline}
      {...props}
    >
      <circle cx={4} cy={12} r={2.5} fill="currentColor">
        <animate
          id="Bouncing3Dots0"
          attributeName="cy"
          begin="0;Bouncing3Dots1.end+0.25s"
          calcMode="spline"
          dur="0.6s"
          keySplines=".33,.66,.66,1;.33,0,.66,.33"
          values="12;6;12"
        ></animate>
      </circle>
      <circle cx={12} cy={12} r={2.5} fill="currentColor">
        <animate
          attributeName="cy"
          begin="Bouncing3Dots0.begin+0.1s"
          calcMode="spline"
          dur="0.6s"
          keySplines=".33,.66,.66,1;.33,0,.66,.33"
          values="12;6;12"
        ></animate>
      </circle>
      <circle cx={20} cy={12} r={2.5} fill="currentColor">
        <animate
          id="Bouncing3Dots1"
          attributeName="cy"
          begin="Bouncing3Dots0.begin+0.2s"
          calcMode="spline"
          dur="0.6s"
          keySplines=".33,.66,.66,1;.33,0,.66,.33"
          values="12;6;12"
        ></animate>
      </circle>
    </svg>
  );
}
