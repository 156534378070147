import {
  CreatableSelect,
  FormikCreatableSelect,
} from "@/components/form/inputs";
import { API_URL } from "@/config/routes";
import useSWR from "swr";
import DealOutcomeBadge from "./DealOutcomeBadge";

function useOutcomeOptions() {
  const { data: outcomes } = useSWR(`${API_URL}/deals/outcomes`, {
    fallbackData: [],
  });

  const options = outcomes?.map((outcome) => ({
    value: outcome,
    label: <DealOutcomeBadge outcome={outcome} />,
  }));

  return options;
}

export function OutcomeSelect(props) {
  const options = useOutcomeOptions();
  return <CreatableSelect options={options} {...props} />;
}

export function FormikOutcomeSelect(props) {
  const options = useOutcomeOptions();

  return <FormikCreatableSelect options={options} {...props} />;
}
