import { FormikSelect, Select } from "@/components/form/inputs";
import { LoadingDots } from "@/components/ui/Loading";
import { API_URL } from "@/config/routes";
import useSWR from "swr";

function useClientOptions() {
  const clientSelectUrl = `${API_URL}/clients?per_page=1000000&simple=true`;
  const { data: clients, isLoading } = useSWR(clientSelectUrl, {
    fallbackData: { items: [] },
  });

  // Display a loading message while fetching the data
  if (isLoading) {
    return [
      {
        value: "",
        label: <LoadingDots centered className="text-xl text-zinc-600" />,
      },
    ];
  }

  return clients.items?.map((client) => ({
    value: client.id,
    label: (
      <span>
        <span className="text-slate-400 font-medium">{client.tax_id} </span>{" "}
        {client.name}
      </span>
    ),
    filterKey: `${client.name} ${client.tax_id}`,
  }));
}

export function ClientSelect(props) {
  const options = useClientOptions();
  return <Select options={options} {...props} />;
}

export function FormikClientSelect(props) {
  const options = useClientOptions();
  return <FormikSelect options={options} {...props} />;
}
