import { FormikInput } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { verticalTableFromRows } from "@/components/ui/Table";
import { API_URL } from "@/config/routes";
import { fetcher } from "@/utils";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";

export default function UserChangePasswordForm() {
  const INITIAL_VALUES = {
    old_password: "",
    new_password: "",
  };

  const handleSubmit = (values, { resetForm, setSubmitting }) => {
    fetcher(`${API_URL}/users/change-password`, {
      method: "POST",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then(() => {
        toast.success("Password aggiornata con successo!");
        resetForm(INITIAL_VALUES);
      })
      .catch((errorRes) => {
        errorRes.json().then((errorData) => {
          toast.error(errorData.message);
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
      {({ isSubmitting, isValid, dirty, resetForm }) => (
        <Form>
          {verticalTableFromRows(
            {
              labelClassName: "w-1/3",
              valueClassName: "w-2/3",
            },
            [
              {
                label: "Vecchia password",
                value: (
                  <FormikInput
                    type="password"
                    name="old_password"
                    placeholder="Inserisci la tua vecchia password"
                    required
                  />
                ),
              },
              {
                label: "Nuova password",
                value: (
                  <FormikInput
                    type="password"
                    name="new_password"
                    placeholder="Inserisci la tua nuova password"
                    required
                  />
                ),
              },
            ]
          )}
          <div className="mt-4 flex gap-2 justify-end">
            <Button
              type="button"
              className="bg-gray-300 hover:bg-gray-400"
              onClick={() => resetForm(INITIAL_VALUES)}
            >
              Reset
            </Button>
            <Button type="submit" disabled={isSubmitting || !isValid || !dirty}>
              Conferma modifica
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
