import { useAuth } from "@/hooks/useAuth";
import React, { useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { FiEdit2, FiLogOut, FiUser } from "react-icons/fi";
import { Link } from "react-router-dom";
import Dropdown from "../ui/Dropdown";

const Avatar = ({ placeholderInitials }) => {
  return (
    <div
      className={`flex items-center justify-center rounded bg-blue-500 text-white text-sm font-semibold w-9 h-9 flex-shrink-0 uppercase pt-0.5`}
    >
      {placeholderInitials}
    </div>
  );
};

export default function UserDropdown() {
  const { user, logout } = useAuth();
  const [isLoggingOut, setIsLoggingOut] = useState(false);
  const roles = {
    admin: "Amministratore",
    manager: "Manager",
    user: "Utente",
    partner: "Socio",
  };

  const doLogout = async () => {
    setIsLoggingOut(true);
    await logout();
  };

  return (
    <Dropdown className="text-sm">
      <Dropdown.Trigger className="bg-black/5 hover:bg-zinc-200 active:bg-zinc-300 text-zinc-700 py-4">
        <div className="flex flex-row items-center">
          <Avatar
            placeholderInitials={
              // Get the first letter of user.name, and if present, the first letter of user.last_name
              user?.name?.charAt(0) + (user?.last_name?.charAt(0) || "")
            }
          />
          <div className="flex flex-col justify-start ml-3 text-left flex-shrink truncate">
            <span className="text-sm truncate font-medium">{user?.name}</span>
            <span className="text-xs truncate text-zinc-400">
              {roles[user.role]}
            </span>
          </div>
          <BiChevronDown className="text-xl ml-2 text-white bg-zinc-400 rounded-full" />
        </div>
      </Dropdown.Trigger>
      <Dropdown.Header>
        <div className="flex flex-col gap-1">
          <span className="block text-base">{user?.name}</span>
          <span className="block truncate text-sm font-medium">
            {user?.email}
          </span>
        </div>
      </Dropdown.Header>
      <Dropdown.Divider />
      <Link to="/users/me">
        <Dropdown.Item icon={<FiUser />}>Il mio profilo</Dropdown.Item>
      </Link>
      <Link to="/users/change-password">
        <Dropdown.Item icon={<FiEdit2 />}>Cambia password</Dropdown.Item>
      </Link>
      <Dropdown.Divider />
      <Dropdown.Item
        onClick={doLogout}
        icon={<FiLogOut />}
        className="text-red-500"
      >
        {isLoggingOut ? "Log out in corso..." : "Esci"}
      </Dropdown.Item>
    </Dropdown>
  );
}
