import { API_URL } from "@/config/routes";
import { useAuth } from "@/hooks/useAuth";
import { fetcher } from "@/utils";
import { createContext, useContext, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import useSWR from "swr";

const DealChatContext = createContext();

export function useDealChat() {
  return useContext(DealChatContext);
}

export function DealChatProvider({ dealId, children }) {
  const { id } = useParams();
  const { user } = useAuth();
  const textareaRef = useRef();
  const endOfMessagesRef = useRef();
  const [isSending, setIsSending] = useState(false);
  const {
    data: messages,
    isLoading,
    mutate,
  } = useSWR(`${API_URL}/messages/deals/${dealId || id}`, {
    refreshInterval: 10000,
  });

  // This function can be called to scroll to the latest message.
  function scrollToLatestMessage() {
    // Wait for the messages to be loaded
    setTimeout(() => {
      endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
    }, 100);
  }

  // This function can be called to focus the text area.
  function focusTextarea() {
    textareaRef.current?.focus();
  }

  // Send a message with text and optional attachment
  async function sendMessage({ text, attachment_id }) {
    setIsSending(true);
    return fetcher(`${API_URL}/messages/deals/`, {
      method: "POST",
      body: JSON.stringify({
        sender_id: user.id,
        deal_id: id,
        text: text.trim(),
        attachment_id: attachment_id,
      }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => {
        mutate([...messages, data]);
        // Focus the textarea and scroll to the latest message
        scrollToLatestMessage();
        focusTextarea(); //! Not working
      })
      .finally(() => {
        setIsSending(false);
      });
  }

  // Delete a message by ID
  async function deleteMessage(message_id) {
    return fetcher(`${API_URL}/messages/${message_id}`, {
      method: "DELETE",
    }).then(() => {
      mutate(messages.filter((message) => message.id !== message_id));
    });
  }

  const value = {
    textareaRef,
    endOfMessagesRef,
    messages,
    isLoading,
    isSending,
    sendMessage,
    deleteMessage,
    scrollToLatestMessage,
    focusTextarea,
  };

  return (
    <DealChatContext.Provider value={value}>
      {children}
    </DealChatContext.Provider>
  );
}
