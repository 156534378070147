import useSWR from "swr";
import { API_URL } from "../../config/routes";

export default function useUser(id) {
  const { data, isLoading, mutate, error } = useSWR(
    id ? `${API_URL}/users/${id}` : null, // If id is null, don't fetch
    { fallbackData: {} }
  );

  return {
    user: data,
    isLoading,
    mutate,
    error,
  };
}
