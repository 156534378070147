import { clsxm } from "@/utils";

export default function Label({ className, ...props }) {
  return (
    <label
      className={clsxm(
        "text-gray-600 font-semibold mb-1 block text-sm",
        className
      )}
      {...props}
    >
      {props.children}
    </label>
  );
}
