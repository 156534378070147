export const PROVINCES = [
  { value: "AG", label: "AGRIGENTO" },
  { value: "AL", label: "ALESSANDRIA" },
  { value: "AN", label: "ANCONA" },
  { value: "AO", label: "AOSTA" },
  { value: "AR", label: "AREZZO" },
  { value: "AP", label: "ASCOLI PICENO" },
  { value: "AT", label: "ASTI" },
  { value: "AV", label: "AVELLINO" },
  { value: "BA", label: "BARI" },
  { value: "BT", label: "BARLETTA-ANDRIA-TRANI" },
  { value: "BL", label: "BELLUNO" },
  { value: "BN", label: "BENEVENTO" },
  { value: "BG", label: "BERGAMO" },
  { value: "BI", label: "BIELLA" },
  { value: "BO", label: "BOLOGNA" },
  { value: "BZ", label: "BOLZANO" },
  { value: "BS", label: "BRESCIA" },
  { value: "BR", label: "BRINDISI" },
  { value: "CA", label: "CAGLIARI" },
  { value: "CL", label: "CALTANISSETTA" },
  { value: "CB", label: "CAMPOBASSO" },
  { value: "CI", label: "CARBONIA-IGLESIAS" },
  { value: "CE", label: "CASERTA" },
  { value: "CT", label: "CATANIA" },
  { value: "CZ", label: "CATANZARO" },
  { value: "CH", label: "CHIETI" },
  { value: "CO", label: "COMO" },
  { value: "CS", label: "COSENZA" },
  { value: "CR", label: "CREMONA" },
  { value: "KR", label: "CROTONE" },
  { value: "CN", label: "CUNEO" },
  { value: "EN", label: "ENNA" },
  { value: "FM", label: "FERMO" },
  { value: "FE", label: "FERRARA" },
  { value: "FI", label: "FIRENZE" },
  { value: "FG", label: "FOGGIA" },
  { value: "FC", label: "FORLI’-CESENA" },
  { value: "FR", label: "FROSINONE" },
  { value: "GE", label: "GENOVA" },
  { value: "GO", label: "GORIZIA" },
  { value: "GR", label: "GROSSETO" },
  { value: "IM", label: "IMPERIA" },
  { value: "IS", label: "ISERNIA" },
  { value: "SP", label: "LA SPEZIA" },
  { value: "AQ", label: "L’AQUILA" },
  { value: "LT", label: "LATINA" },
  { value: "LE", label: "LECCE" },
  { value: "LC", label: "LECCO" },
  { value: "LI", label: "LIVORNO" },
  { value: "LO", label: "LODI" },
  { value: "LU", label: "LUCCA" },
  { value: "MC", label: "MACERATA" },
  { value: "MN", label: "MANTOVA" },
  { value: "MS", label: "MASSA-CARRARA" },
  { value: "MT", label: "MATERA" },
  { value: "VS", label: "MEDIO CAMPIDANO" },
  { value: "ME", label: "MESSINA" },
  { value: "MI", label: "MILANO" },
  { value: "MO", label: "MODENA" },
  { value: "MB", label: "MONZA E BRIANZA" },
  { value: "NA", label: "NAPOLI" },
  { value: "NO", label: "NOVARA" },
  { value: "NU", label: "NUORO" },
  { value: "OG", label: "OGLIASTRA" },
  { value: "OT", label: "OLBIA-TEMPIO" },
  { value: "OR", label: "ORISTANO" },
  { value: "PD", label: "PADOVA" },
  { value: "PA", label: "PALERMO" },
  { value: "PR", label: "PARMA" },
  { value: "PV", label: "PAVIA" },
  { value: "PG", label: "PERUGIA" },
  { value: "PU", label: "PESARO E URBINO" },
  { value: "PE", label: "PESCARA" },
  { value: "PC", label: "PIACENZA" },
  { value: "PI", label: "PISA" },
  { value: "PT", label: "PISTOIA" },
  { value: "PN", label: "PORDENONE" },
  { value: "PZ", label: "POTENZA" },
  { value: "PO", label: "PRATO" },
  { value: "RG", label: "RAGUSA" },
  { value: "RA", label: "RAVENNA" },
  { value: "RC", label: "REGGIO CALABRIA" },
  { value: "RE", label: "REGGIO EMILIA" },
  { value: "RI", label: "RIETI" },
  { value: "RN", label: "RIMINI" },
  { value: "RM", label: "ROMA" },
  { value: "RO", label: "ROVIGO" },
  { value: "SA", label: "SALERNO" },
  { value: "SS", label: "SASSARI" },
  { value: "SV", label: "SAVONA" },
  { value: "SI", label: "SIENA" },
  { value: "SR", label: "SIRACUSA" },
  { value: "SO", label: "SONDRIO" },
  { value: "TA", label: "TARANTO" },
  { value: "TE", label: "TERAMO" },
  { value: "TR", label: "TERNI" },
  { value: "TO", label: "TORINO" },
  { value: "TP", label: "TRAPANI" },
  { value: "TN", label: "TRENTO" },
  { value: "TV", label: "TREVISO" },
  { value: "TS", label: "TRIESTE" },
  { value: "UD", label: "UDINE" },
  { value: "VA", label: "VARESE" },
  { value: "VE", label: "VENEZIA" },
  { value: "VB", label: "VERBANO-CUSIO-OSSOLA" },
  { value: "VC", label: "VERCELLI" },
  { value: "VR", label: "VERONA" },
  { value: "VV", label: "VIBO VALENTIA" },
  { value: "VI", label: "VICENZA" },
  { value: "VT", label: "VITERBO" },
];
