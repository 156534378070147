import Pagination from "@/components/form/Pagination";
import PillDropdown from "@/components/form/PillDropdown";
import { Searchbar } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import Table from "@/components/ui/Table";
import { API_URL } from "@/config/routes";
import useDebounce from "@/hooks/useDebounce";
import {
  fetcher,
  formatDate,
  formatRelativePastDate,
  objectToQueryString,
} from "@/utils";
import { useEffect, useState } from "react";
import { MdAdd, MdFileDownload, MdReadMore } from "react-icons/md";
import { Link } from "react-router-dom";
import useSWR from "swr";
import UserLink from "../users/UserLink";
import ClientLink from "./ClientLink";

const DEFAULT_QUERY_PARAMS = {
  page: 1,
  per_page: 20,
  sort_by: "updated_at",
  order: "desc",
  q: "",
};

async function SWRFetcher({ url, params }) {
  return fetcher(url + "?" + objectToQueryString(params)).then((res) =>
    res.json()
  );
}

function ClientRow({ client }) {
  return (
    <Table.Row>
      <Table.Td>{formatDate(client.created_at, "short")}</Table.Td>
      <Table.Td>{formatRelativePastDate(client.updated_at)}</Table.Td>
      <Table.Td className="truncate">
        <UserLink user={client.user} />
      </Table.Td>
      <Table.Td className="truncate">
        <ClientLink client={client} />
      </Table.Td>
      <Table.Td className="truncate">{client.tax_id}</Table.Td>
      <Table.Td className="pr-6">
        <Link to={`/clients/${client.id}`}>
          <span className="w-full flex justify-center group">
            <MdReadMore className="text-zinc-300 text-3xl group-hover:text-blue-700 hover:cursor-pointer" />
          </span>
        </Link>
      </Table.Td>
    </Table.Row>
  );
}

function ClientsTableHeader({ filters, onFilterChange }) {
  return (
    <Table.Head>
      <Table.Row>
        <Table.Th
          sortable
          sortDirection={
            filters.sort_by === "created_at" ? filters.order : null
          }
          onClick={() =>
            onFilterChange({
              sort_by: "created_at",
              order:
                filters.sort_by === "created_at" && filters.order === "desc"
                  ? "asc"
                  : "desc",
            })
          }
        >
          Creato il
        </Table.Th>
        <Table.Th
          sortable
          sortDirection={
            filters.sort_by === "updated_at" ? filters.order : null
          }
          onClick={() =>
            onFilterChange({
              sort_by: "updated_at",
              order:
                filters.sort_by === "updated_at" && filters.order === "desc"
                  ? "asc"
                  : "desc",
            })
          }
        >
          Ultima modifica
        </Table.Th>
        <Table.Th className="w-[20%]">Creato da</Table.Th>
        <Table.Th className="w-[30%]">Nome</Table.Th>
        <Table.Th className="w-[20%]">CF/PIVA</Table.Th>
        <Table.Th className="w-[10%] text-center pr-6">Dettagli</Table.Th>
      </Table.Row>
    </Table.Head>
  );
}

function ClientsTable({ clients, isLoading, filters, onFilterChange }) {
  return (
    <Table className="w-full table-fixed">
      <ClientsTableHeader filters={filters} onFilterChange={onFilterChange} />
      <Table.Body>
        {!clients &&
          isLoading &&
          Array.from({ length: 20 }).map((_, i) => (
            <Table.SkeletonRow key={i} colsNumber={6}></Table.SkeletonRow>
          ))}
        {clients &&
          clients.map((client) => (
            <ClientRow key={client.id} client={client} filters={filters} />
          ))}
      </Table.Body>
    </Table>
  );
}

function ClientsSearchbar({ onDebouncedChange, className }) {
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    // When the change has been debounced, we want to call the callback and set the typing state to false
    onDebouncedChange(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <Searchbar
      id="search-clients"
      value={searchTerm}
      onChange={(e) =>
        setSearchTerm(e.target.value !== "" ? e.target.value : undefined)
      }
      placeholder="Cerca per nome e cognome, codice fiscale o partita IVA"
      className={className}
    />
  );
}

// Define the button here so we can export it and place it in the header
function ClientsDataTableNewClientButton() {
  return (
    <Link to="/clients/new">
      <Button className="py-4" icon={<MdAdd className="text-xl" />}>
        Nuovo cliente
      </Button>
    </Link>
  );
}

export default function ClientsDataTable() {
  const [queryParams, setQueryParams] = useState(DEFAULT_QUERY_PARAMS);
  const { data: clients, isLoading } = useSWR(
    { url: `${API_URL}/clients`, params: queryParams },
    SWRFetcher
  );

  const handleParamsChange = (params) => {
    // Whenever the params change, we want to reset the page to 1
    setQueryParams({ ...queryParams, ...params, page: 1 });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-end">
        {/* The search bar */}
        <ClientsSearchbar
          className="w-[65%]"
          onDebouncedChange={(searchTerm) => {
            handleParamsChange({ ...queryParams, q: searchTerm });
          }}
        />
        <Pagination
          onPageChange={(page) => setQueryParams({ ...queryParams, page })}
          currentPage={queryParams.page}
          totalPages={clients?.pages}
        />
      </div>
      <ClientsTable
        clients={clients?.items}
        filters={queryParams}
        isLoading={isLoading}
        onFilterChange={(filters) =>
          handleParamsChange({ ...queryParams, ...filters })
        }
      />
      <div className="flex justify-end ">
        <Pagination
          onPageChange={(page) => setQueryParams({ ...queryParams, page })}
          currentPage={queryParams.page}
          totalPages={clients?.pages}
        />
      </div>
    </div>
  );
}

ClientsDataTable.NewClientButton = ClientsDataTableNewClientButton;
