import { clsxm } from "@/utils";
import React, { Children } from "react";
import { BsBox } from "react-icons/bs";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";

const areThereChildren = (children) => {
  // Check if there are any children
  const flatChildren = Children.toArray(children).flat();
  const nonEmptyChildren = flatChildren.filter(Boolean);
  return nonEmptyChildren.length > 0;
};

function Table({ children, className, ...props }) {
  // Find out if there are real children in the tbody
  const tbodyChildren = Children.toArray(children).find(
    (child) => child.type === TableBody
  );

  return (
    <div
      className={clsxm(
        "flex flex-col bg-white rounded border-2 border-slate-100",
        className
      )}
    >
      <table className="w-full table-fixed whitespace-nowrap" {...props}>
        {children}
      </table>
      {!areThereChildren(tbodyChildren.props.children) && (
        <div className="w-full flex flex-col items-center justify-center h-96 text-gray-400">
          <BsBox className="text-4xl" />
          <span className="mt-2 font-medium text-center">
            Nessun dato disponibile.
          </span>
        </div>
      )}
    </div>
  );
}

function TableHead({ children, ...props }) {
  return <thead {...props}>{children}</thead>;
}

function TableBody({ children, ...props }) {
  return <tbody {...props}>{children}</tbody>;
}

function TableRow({ children, className, ...props }) {
  return (
    <tr
      className={clsxm(
        "border-b border-gray-100 text-left last:border-b-0 only:border-b-2 only:bg-zinc-50/50 even:bg-zinc-50/50",
        className
      )}
      {...props}
    >
      {children}
    </tr>
  );
}

function SortIcon({ direction }) {
  if (direction === "asc") {
    return <TiArrowSortedUp className="inline ml-1 text-[1.2em] -mt-0.5" />;
  } else if (direction === "desc") {
    return <TiArrowSortedDown className="inline ml-1 text-[1.2em] -mt-0.5" />;
  } else {
    return <TiArrowUnsorted className="inline ml-1 text-[1.2em] -mt-0.5" />;
  }
}

function TableTh({ children, className, sortable, sortDirection, ...props }) {
  return (
    <th
      className={clsxm(
        "px-4 py-2 text-xs font-medium text-gray-500 uppercase tracking-wider whitespace-pre-line [line-height:1.6]",
        sortable && "cursor-pointer hover:bg-zinc-100",
        className
      )}
      {...props}
    >
      {children} {sortable && <SortIcon direction={sortDirection} />}
    </th>
  );
}

function TableTd({ children, className, ...props }) {
  return (
    <td
      className={clsxm("px-4 py-2.5  text-sm text-zinc-800", className)}
      {...props}
    >
      {children}
    </td>
  );
}

/**
 * Renders a vertical table from a list of rows.
 * Each row is an object with keys: `label`, `subLabel` (optional) and `value`.
 *
 * @param {Object} options - The options for rendering the table.
 * @param {string} options.labelClassName - The class name for the label column.
 * @param {string} options.valueClassName - The class name for the value column.
 * @param {Array} rows - The list of rows to render.
 * @returns {JSX.Element} The rendered vertical table.
 */
export function verticalTableFromRows(options, rows) {
  return (
    <Table>
      <Table.Body>
        {rows
          .filter((row) => row.value !== undefined)
          .map((row, i) => (
            <TableRow key={i}>
              <Table.Th
                className={clsxm(
                  "border-r border-r-gray-100",
                  options?.labelClassName
                )}
              >
                {row.label}
                {row.subLabel && (
                  <div className="normal-case font-light text-xs text-gray-500 mt-1">
                    {row.subLabel}
                  </div>
                )}
              </Table.Th>
              <Table.Td className={options?.valueClassName}>
                {row.value}
              </Table.Td>
            </TableRow>
          ))}
      </Table.Body>
    </Table>
  );
}

function SkeletonTable({
  colsNumber = 4,
  rowsNumber = 15,
  className,
  ...props
}) {
  // Render a placeholder skeleton table
  return (
    <Table className={clsxm("animate-pulse", className)} {...props}>
      <Table.Head>
        <Table.Row>
          {Array.from({ length: colsNumber }).map((_, i) => (
            // I want the first and the last column to be smaller
            <Table.Th
              key={i}
              className={clsxm(
                "w-[10%] pl-6",
                i === 0 && "w-[5%]",
                i === colsNumber - 1 && "w-[5%]"
              )}
            >
              <div className="h-5 bg-gray-200 rounded w-full"></div>
            </Table.Th>
          ))}
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {Array.from({ length: rowsNumber }).map((_, i) => (
          <Table.Row key={i}>
            {Array.from({ length: colsNumber }).map((_, j) => (
              <Table.Td
                key={j}
                className={clsxm(
                  "pl-6",
                  j === 0 && "pl-6",
                  j === colsNumber - 1 && "pr-6"
                )}
              >
                <div className="h-5 bg-gray-200 rounded w-full"></div>
              </Table.Td>
            ))}
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );
}

function SkeletonRow({ colsNumber = 4, className, ...props }) {
  return (
    <TableRow className={clsxm("animate-pulse", className)} {...props}>
      {Array.from({ length: colsNumber }).map((_, i) => (
        <Table.Td key={i}>
          <div className="h-5 bg-gray-200 rounded w-full"></div>
        </Table.Td>
      ))}
    </TableRow>
  );
}

Table.Head = TableHead;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Th = TableTh;
Table.Td = TableTd;
Table.Skeleton = SkeletonTable;
Table.SkeletonRow = SkeletonRow;

export default Table;
