import { Page, PageHeader } from "@/components/layout/Page";
import { useAuth } from "@/hooks/useAuth";
import { BsFillSafeFill } from "react-icons/bs";
import { FaExternalLinkAlt, FaYoutube } from "react-icons/fa";
import { IoDocumentsSharp, IoShareSocial } from "react-icons/io5";

export const bookmarksRoutes = [{ path: "/bookmarks", element: <Bookmarks /> }];

function Anchor({ href, children, ...props }) {
  return (
    <a
      href={href}
      className="text-blue-700 font-semibold hover:text-blue-700 hover:underline"
      {...props}
    >
      {children}
    </a>
  );
}

function BookmarkCard({ title, href, children, icon }) {
  return (
    <div className="w-full max-w-xl m-0 pl-4 border-l-4 pt-2 pb-3 border-zinc-300 hover:border-amber-400 duration-200">
      {/* title */}
      <a
        href={href}
        target="_blank"
        className="text-2xl font-bold hover:underline hover:cursor-pointer"
      >
        <div className="flex gap-2 items-center mb-1 text-zinc-800">
          {/* icon */}
          {icon && (
            <div className="flex items-center justify-center text-3xl">
              {icon}
            </div>
          )}
          <h1>{title}</h1>
          {href && <FaExternalLinkAlt className="text-lg text-zinc-600" />}
        </div>
      </a>
      <p>{children}</p>
    </div>
  );
}

function Bookmarks() {
  const { user } = useAuth();
  return (
    <Page title="Risorse">
      <PageHeader
        breadcrumbs={[{ label: "Risorse" }]}
        description="Accedi alle risorse utili che Genoleggia mette a disposizione per i propri consulenti"
      />
      <div className="flex flex-wrap gap-5 py-2">
        <BookmarkCard
          title="Area formativa"
          href="https://www.youtube.com/playlist?list=PL4fXPgXSc-XP-qLZPscT67ueR6Kz7ZWf7"
          icon={<FaYoutube />}
        >
          Il canale Youtube di <b>Genoleggia</b> dove spieghiamo come funziona
          il mondo del Noleggio a Lungo Termine. Guarda i nostri video{" "}
          <Anchor
            target="_blank"
            href="https://www.youtube.com/playlist?list=PL4fXPgXSc-XP-qLZPscT67ueR6Kz7ZWf7"
          >
            cliccando su questo link
          </Anchor>
          .
        </BookmarkCard>
        <BookmarkCard
          title="Documenti"
          icon={<IoDocumentsSharp />}
          href={
            "https://drive.google.com/drive/folders/1h1h2py0WjRDDiDWXMpg6xIJEJyCPbG52?usp=sharing"
          }
        >
          Cartella sul cloud con tutti i documenti utili per il lavoro di
          consulenza. Puoi trovare i file di excel con i listini stock
          aggiornati, le schede tecniche dei veicoli, le presentazioni e i
          documenti necessari per la vendita.{" "}
          <Anchor
            target="_blank"
            href="https://drive.google.com/drive/folders/1h1h2py0WjRDDiDWXMpg6xIJEJyCPbG52?usp=sharing"
          >
            Clicca qui per accedere alla cartella
          </Anchor>
          .
        </BookmarkCard>
        {user?.role === "admin" && (
          <BookmarkCard
            title="Documenti amministrazione"
            icon={<BsFillSafeFill />}
            href="https://drive.google.com/drive/folders/17-vSrjlxqC_vqcUzDcOaHU0E5KigMIAz"
          >
            Documenti riservati all'amministrazione.{" "}
            <Anchor
              target="_blank"
              href="https://drive.google.com/drive/folders/17-vSrjlxqC_vqcUzDcOaHU0E5KigMIAz"
            >
              Clicca qui per accedere alla cartella
            </Anchor>
            .
          </BookmarkCard>
        )}
        <BookmarkCard
          title="Pubblicità"
          icon={<IoShareSocial />}
          href="https://drive.google.com/drive/folders/1dClM_k2A9NGVF0rEeSPWVDLHEmkVoNaF?usp=sharing"
        >
          Qui puoi trovare tutti i contenuti pubblicitari creati dalla nostra
          agenzia di comunicazione. Puoi utilizzare questi contenuti per
          promuovere la tua attività sui social e sui canali di comunicazione
          che preferisci.{" "}
          <Anchor
            target="_blank"
            href="https://drive.google.com/drive/folders/1dClM_k2A9NGVF0rEeSPWVDLHEmkVoNaF?usp=sharing"
          >
            Clicca qui per visualizzare i contenuti
          </Anchor>
          .
        </BookmarkCard>
      </div>
    </Page>
  );
}
