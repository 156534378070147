import { formatDate } from "@/utils";
import { useField } from "formik";
import { Input } from "./Input";

export function DateInput({ readOnly, ...props }) {
  if (readOnly) {
    return (
      <>
        <span>{props?.value ? formatDate(props?.value) : "-"}</span>
        <input type="hidden" name={props?.name} value={props?.value} />
      </>
    );
  }
  return <Input type="date" readOnly={readOnly} {...props} />;
}

export function FormikDateInput({ name, ...props }) {
  // Access formik's props
  const [field, meta] = useField(name);

  return <DateInput {...field} {...props} error={meta.touched && meta.error} />;
}
