import {
  Checkbox,
  FormikInput,
  FormikTextarea,
} from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { verticalTableFromRows } from "@/components/ui/Table";
import { API_URL } from "@/config/routes";
import { useAuth } from "@/hooks/useAuth";
import { fetcher } from "@/utils";
import { Form, Formik } from "formik";
import { useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { FormikClientSelect } from "../clients/ClientSelect";

const validationSchema = Yup.object().shape({
  vehicle_brand_name: Yup.string().max(255),
  vehicle_model_name: Yup.string().max(255),
  price: Yup.number()
    .typeError("Il canone deve essere un numero")
    .positive("Il canone deve essere un numero positivo"),
  deposit: Yup.number()
    .typeError("Il canone deve essere un numero")
    .min(0, "Il canone deve essere un numero positivo"),
});

function DealVehicleInput() {
  return (
    <div className="flex gap-1 w-full">
      <FormikInput name="vehicle_brand_name" placeholder="Marca" required />
      <FormikInput name="vehicle_model_name" placeholder="Modello" required />
      <FormikInput name="vehicle_name" placeholder="Allestimento" />
    </div>
  );
}

function DealClientInput() {
  return (
    <div className="flex flex-row gap-2">
      <FormikClientSelect name="client_id" required className="w-full" />
      <Link to="/clients/new">
        <Button
          type="button"
          className="flex items-center justify-center py-2"
          icon={<BsPlusCircle />}
        >
          Nuovo
        </Button>
      </Link>
    </div>
  );
}

export default function DealCreateForm() {
  const { user } = useAuth();
  const [goToDeal, setGoToDeal] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = (values, { resetForm }) => {
    fetcher(`${API_URL}/deals`, {
      method: "POST",
      body: JSON.stringify({ ...values, author_id: user.id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            toast.success(
              <span>
                Trattativa <b>#{data.id}</b> creata con successo!
              </span>
            );
            if (goToDeal) navigate(`/deals/${data.id}`);
            resetForm();
          });
        } else {
          toast.error("Errore nella creazione della trattativa");
        }
      })
      .catch((error) => {
        toast.error("Errore nella creazione della trattativa");
      });
  };

  return (
    <Formik
      initialValues={{
        client_id: "",
        vehicle_brand_name: "",
        vehicle_model_name: "",
        vehicle_name: "",
        distance: "",
        duration: "",
        price: "",
        deposit: "",
        notes: "",
      }}
      onSubmit={handleSubmit}
      validationSchema={validationSchema}
    >
      {({ isSubmitting, dirty, resetForm }) => (
        <Form>
          {/* Make table with helper method */}
          {verticalTableFromRows(
            {
              labelClassName: "w-[30%]",
              valueClassName: "whitespace-normal",
            },
            [
              {
                label: "Cliente",
                value: <DealClientInput />,
              },
              {
                label: "Veicolo",
                value: <DealVehicleInput />,
              },
              {
                label: "Km (annui)",
                value: (
                  <FormikInput
                    name="distance"
                    type="number"
                    required
                    min={0}
                    max={100000}
                    step={1000}
                    placeholder="Inserisci il numero di km percorsi annualmente"
                  />
                ),
              },
              {
                label: "Durata (mesi)",
                value: (
                  <FormikInput
                    name="duration"
                    type="number"
                    min={12}
                    max={60}
                    step={12}
                    required
                    placeholder="Inserisci il numero di mesi del contratto"
                  />
                ),
              },
              {
                label: "Canone desiderato (€)",
                value: (
                  <FormikInput
                    name="price"
                    type="number"
                    min={0}
                    step={0.01}
                    placeholder="Inserisci la rata mensile desiderata"
                    required
                  />
                ),
              },
              {
                label: "Anticipo desiderato (€)",
                value: (
                  <FormikInput
                    name="deposit"
                    required
                    type="number"
                    min={0}
                    step={0.01}
                    placeholder="Inserisci l'anticipo desiderato"
                  />
                ),
              },
              {
                label: "Note",
                value: (
                  <FormikTextarea
                    placeholder="Inserisci eventuali note"
                    rows={2}
                    name="notes"
                  />
                ),
              },
            ]
          )}
          <div className="flex flex-row justify-between pt-4">
            <Checkbox
              label="Vai alla nuova trattativa dopo la creazione"
              className="text-sm text-zinc-800"
              disabled={!dirty || isSubmitting}
              checked={goToDeal}
              onChange={() => setGoToDeal(!goToDeal)}
            />
            <div className="flex items-center gap-2">
              <Button
                type="reset"
                className="bg-gray-300 hover:bg-gray-400"
                onClick={() => resetForm()}
              >
                Reset
              </Button>
              <Button
                type="submit"
                icon={<BsPlusCircle />}
                disabled={!dirty || isSubmitting}
              >
                Crea trattativa
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
