import { API_URL } from "@/config/routes";
import { fetcher } from "@/utils";
import { toast } from "react-toastify";
import useSwr from "swr";

export function useTask(id) {
  const { data, isLoading, error, mutate } = useSwr(`${API_URL}/tasks/${id}`);

  const updateTask = async (task) => {
    return fetcher(`${API_URL}/tasks/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(task),
    })
      .then((res) => res.json())
      .then((data) => {
        mutate(data);
        toast.success("Dettagli della richiesta aggiornati con successo");
      });
  };

  return {
    task: data,
    isLoading,
    error,
    updateTask,
  };
}

export function useTaskComments(id) {
  const { data, isLoading, error, mutate } = useSwr(
    `${API_URL}/tasks/${id}/comments`,
    { fallbackData: [] }
  );

  const addComment = async (comment) => {
    return fetcher(`${API_URL}/tasks/${id}/comments`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(comment),
    })
      .then((res) => res.json())
      .then((message) => {
        mutate([...data, message]);
      });
  };

  return {
    comments: data,
    isLoading,
    error,
    addComment,
  };
}
