import { clsxm } from "../../utils";
import { LoadingDots } from "./Loading";

export function Button({
  size = "md",
  disabled,
  icon,
  children,
  className,
  isRotating,
  isLoading,
  ...props
}) {
  const textSize = {
    sm: "text-base",
    md: "text-lg",
  }[size];

  const textSizeLoadingIndicator = {
    sm: "text-xl",
    md: "text-2xl",
  }[size];

  const gapSize = {
    sm: "gap-1.5",
    md: "gap-2",
  }[size];

  return (
    <button
      className={clsxm(
        "bg-blue-600 hover:bg-blue-700  active:shadow-inner active:shadow-blue-900 text-white font-semibold py-3 px-4 rounded-lg",
        disabled &&
          "opacity-50 cursor-not-allowed active:shadow-none hover:bg-blue-600",
        icon && "pr-6",
        className
      )}
      disabled={disabled}
      {...props}
    >
      <div className={clsxm("flex items-center justify-center", gapSize)}>
        {icon && (
          <span className={clsxm(textSize, isRotating && "animate-spin")}>
            {icon}
          </span>
        )}
        {isLoading ? (
          <div className={textSizeLoadingIndicator}>
            <LoadingDots centered={true} />
          </div>
        ) : (
          children
        )}
      </div>
    </button>
  );
}

export function SmallButton({ className, ...props }) {
  return (
    <Button
      size="sm"
      className={clsxm(
        "text-sm flex items-center h-auto bg-transparent text-blue-700 hover:bg-blue-700 hover:text-white px-2 py-0.5 ",
        className
      )}
      {...props}
    ></Button>
  );
}
