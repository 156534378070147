import { FormikSelect, Select } from "@/components/form/inputs";
import { LoadingDots } from "@/components/ui/Loading";
import { API_URL } from "@/config/routes";
import useSWR from "swr";

function useDealOptions() {
  const dealSelectUrl = `${API_URL}/deals?per_page=1000000&status=Conclusa`;
  const { data: deal, isLoading } = useSWR(dealSelectUrl, {
    fallbackData: { items: [] },
  });

  // Display a loading message while fetching the data
  if (isLoading) {
    return [
      {
        value: "",
        label: <LoadingDots centered className="text-xl text-zinc-600" />,
      },
    ];
  }

  return deal.items?.map((deal) => ({
    value: deal.id,
    label: (
      <span>
        <span className="text-slate-400 font-medium">{deal.pretty_id} </span>{" "}
        {deal.vehicle_full_name}
      </span>
    ),
    filterKey: `${deal.pretty_id}`,
  }));
}

export function DealSelect(props) {
  const options = useDealOptions();
  return <Select options={options} {...props} />;
}

export function FormikDealSelect(props) {
  const options = useDealOptions();
  return <FormikSelect options={options} {...props} />;
}
