import Label from "@/components/form/Label";
import {
  FormikInput,
  FormikSelect,
  FormikTextarea,
} from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { Card2, SkeletonCard } from "@/components/ui/Card";
import { useAuth } from "@/hooks/useAuth";
import { clsxm, formatDate } from "@/utils";
import { Form, Formik } from "formik";
import React, { useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaPaperPlane, FaPencilAlt } from "react-icons/fa";
import { useParams } from "react-router-dom";
import UserLink from "../users/UserLink";
import TaskStatusBadge, { TASK_STATUSES } from "./TaskStatusBadge";
import TaskLabelBadge from "./labels/TaskLabelBadge";
import { FormikTaskLabelsMultiSelect } from "./labels/TaskLabelMultiSelect";
import { useTask, useTaskComments } from "./useTask";

function LabelWithEditButton({ children, onClick, className }) {
  const { user } = useAuth();

  return (
    <Label
      className={clsxm(
        "items-center flex  gap-1.5 mb-2.5",
        user?.role === "admin" && "hover:text-blue-700 cursor-pointer",
        className
      )}
    >
      {children}
      {user.role === "admin" && (
        <button
          type="button"
          onClick={onClick}
          className="text-lg text-gray-400 hover:text-blue-700"
        >
          <FaPencilAlt />
        </button>
      )}
    </Label>
  );
}

function SettingsSidebar() {
  const { id } = useParams();
  const { task, isLoading, updateTask } = useTask(id);
  const [isEditing, setIsEditing] = useState(false);
  const { user } = useAuth();

  // Only I can edit certain fields
  const isUserSuperAdmin = user?.id === 1;
  const isUserAdmin = user?.role === "admin";

  // Only super admin can edit task status apart from "To do" and "In coda" when editing
  const TASK_STATUSES_FILTERED =
    !isUserSuperAdmin && isEditing
      ? ["To do", "In coda", "Cancellata"]
      : TASK_STATUSES;

  const INITIAL_VALUES = {
    labels: task.labels.map((label) => label.id),
    status: task.status,
    complexity: task.complexity,
  };

  if (isLoading || !task) return <div>Loading...</div>;

  const handleSubmit = async (values) => {
    await updateTask(values);
    setIsEditing(false);
  };

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
      {({ isSubmitting, isValid, dirty, resetForm }) => (
        <Form>
          <div className="flex flex-col">
            <LabelWithEditButton
              className="mb-4 text-zinc-800 text-xl"
              onClick={() => setIsEditing((prev) => !prev)}
            >
              Dettagli
            </LabelWithEditButton>
            <Label>Etichette</Label>
            <FormikTaskLabelsMultiSelect
              name="labels"
              className={clsxm("w-full mb-1", !isEditing && "hidden")}
            />
            <div
              className={clsxm(
                "flex flex-row flex-wrap gap-1",
                isEditing && "hidden"
              )}
            >
              {task.labels.map((label) => (
                <TaskLabelBadge key={label.id} label={label} />
              ))}
              {!task.labels.length && "-"}
            </div>
            <span className="w-full h-[1px] bg-gray-200 mt-4 mb-4" />

            <Label>Stato</Label>

            <FormikSelect
              name="status"
              options={TASK_STATUSES_FILTERED.map((status) => ({
                value: status,
                label: <TaskStatusBadge status={status} />,
              }))}
              className="w-full mb-1"
              readOnly={!isEditing || !isUserAdmin}
            />
            <span className="w-full h-[1px] bg-gray-200 mt-2 mb-4" />

            <Label>Ore di lavoro previste (complessità)</Label>
            <FormikInput
              name="complexity"
              type="number"
              suffix="h"
              className="w-full"
              readOnly={!isEditing || !isUserSuperAdmin}
            />

            {isEditing && (
              <>
                <span className="w-full h-[1px] bg-gray-200 mt-4 mb-4" />
                <div className="flex flex-row justify-end gap-2">
                  <Button
                    type="button"
                    onClick={() => {
                      resetForm();
                      setIsEditing(false);
                    }}
                    className="bg-gray-200 hover:bg-gray-300"
                  >
                    Annulla
                  </Button>

                  <Button
                    type="submit"
                    disabled={isSubmitting || !isValid || !dirty}
                  >
                    Salva
                  </Button>
                </div>
              </>
            )}
          </div>
        </Form>
      )}
    </Formik>
  );
}

function TaskComment({ comment }) {
  return (
    <Card2 className="m-0 mb-2.5 p-6 border-none flex flex-col w-full">
      <div className="flex flex-row justify-between">
        <span className="text-gray-600 text-sm">
          <UserLink user={comment.author} />
          &nbsp;il&nbsp;
          {formatDate(comment.created_at)}
        </span>
      </div>
      <div className="mt-2 whitespace-pre-line">{comment.content}</div>
    </Card2>
  );
}

function TaskComments() {
  const { id } = useParams();
  const { comments, isLoading } = useTaskComments(id);

  return (
    <>
      <Label className="mt-5 mb-3 text-zinc-800 text-xl">Commenti</Label>
      {comments.map((comment) => (
        <TaskComment key={comment.id} comment={comment} />
      ))}
      {!comments.length && !isLoading && (
        <div className="italic text-gray-400 mb-2 border-dashed border-2 p-4 rounded-md">
          Nessun commento
        </div>
      )}
      {isLoading && <SkeletonCard className="m-0" />}
    </>
  );
}

function TaskCommentForm() {
  const { id } = useParams();
  const { addComment } = useTaskComments(id);

  const handleSubmit = async (values, { resetForm }) => {
    if (values.content.trim()) {
      await addComment(values);
    }
    resetForm();
  };

  return (
    <Formik initialValues={{ content: "" }} onSubmit={handleSubmit}>
      {({ dirty, isSubmitting }) => (
        <Form>
          <div className="flex flex-col w-full mt-2">
            <Label>Aggiungi un commento</Label>
            <FormikTextarea
              rows={3}
              placeholder="Aggiungi un commento alla richiesta"
              name="content"
              className="w-full"
            />
            <div className="flex flex-row justify-end gap-2 mt-2">
              <Button
                icon={
                  isSubmitting ? (
                    <CgSpinner className="animate-spin" />
                  ) : (
                    <FaPaperPlane />
                  )
                }
                disabled={!dirty || isSubmitting}
                type="submit"
              >
                Aggiungi commento
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}

export default function TaskDetails({ className }) {
  const { id } = useParams();
  const { task, isLoading } = useTask(id);

  if (isLoading || !task) return <SkeletonCard />;

  return (
    <div className={clsxm("flex flex-col", className)}>
      <div className="flex flex-col w-full">
        <h1 className="text-2xl font-semibold text-zinc-800">
          {task.title}
          <span className="font-light text-gray-400 ml-2">#{task.id}</span>
        </h1>
        <span className="text-gray-600 text-sm mt-2">
          creato da <UserLink user={task.author} />
          &nbsp;il&nbsp;
          {formatDate(task.created_at)}
        </span>
      </div>
      <div className="flex flex-row w-full gap-8 mt-4">
        <div className="flex flex-col w-2/3">
          <p className="-ml-1 p-5 bg-zinc-50 rounded-md w-auto whitespace-pre-line">
            {task.description || (
              <span className="text-gray-400 italic">Nessuna descrizione</span>
            )}
          </p>
          <TaskComments />
          <TaskCommentForm />
        </div>

        <div className="flex flex-col w-1/3">
          <SettingsSidebar />
        </div>
      </div>
    </div>
  );
}
