import PillDropdown from "@/components/form/PillDropdown";
import { Searchbar } from "@/components/form/inputs";
import { Page, PageHeader } from "@/components/layout/Page";
import { Badge } from "@/components/ui/Badge";
import { Button } from "@/components/ui/Button";
import Card from "@/components/ui/Card";
import { Modal } from "@/components/ui/Modal";
import { Tooltip } from "@/components/ui/Tooltip";
import TutorialBadge from "@/components/ui/TutorialBadge";
import { API_URL } from "@/config/routes";
import { useAuth } from "@/hooks/useAuth";
import useSessionStorage from "@/hooks/useSessionStorage";
import { clsxm } from "@/utils";
import { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaShippingFast, FaTimes } from "react-icons/fa";
import {
  MdAdd,
  MdBusinessCenter,
  MdOutlineVerticalAlignBottom,
  MdOutlineVerticalAlignTop,
  MdPerson,
  MdSort,
  MdWarning,
} from "react-icons/md";
import ReactPaginate from "react-paginate";
import { Link, useNavigate } from "react-router-dom";
import useSWR from "swr";
import GenoleggiaLogoIcon from "/images/logo-icon.png";

const DEFAULT_FILTERS = {
  query: "",
  orderBy: "price-asc",
  minPrice: 100,
  maxPrice: 2000,
  target: "all",
  fastDelivery: false,
};

const formatNumber = (number) => {
  return new Intl.NumberFormat("it-IT", {
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  }).format(number);
};

const getFilteredOffers = (offers, filters) => {
  const { minPrice, maxPrice, query, orderBy, fastDelivery, target } = filters;
  // If all filters are empty, show all the offers
  if (Object.keys(filters).every((key) => !filters[key])) return offers;
  // Filter the offers based on the current state of the filters
  const filteredOffers = offers.filter((offer) => {
    let searchCondition = offer.vehicle.name
      .toLowerCase()
      .includes(query.toLowerCase());
    let priceCondition =
      offer.price_range[0] >= minPrice && offer.price_range[1] <= maxPrice;
    if (fastDelivery === "true") {
      searchCondition = searchCondition && offer.fast_delivery;
    }
    if (target !== "all") {
      searchCondition =
        searchCondition && (offer.target === target || offer.target === "all");
    }
    return searchCondition && priceCondition;
  });
  // Sort the offers based on the current state of the filters
  if (orderBy) {
    const sortingFunctions = {
      "a-z": (a, b) => a.vehicle.name.localeCompare(b.vehicle.name),
      "z-a": (a, b) => b.vehicle.name.localeCompare(a.vehicle.name),
      "price-asc": (a, b) => a.price_range[0] - b.price_range[0],
      "price-desc": (a, b) => b.price_range[0] - a.price_range[0],
    };
    filteredOffers.sort(sortingFunctions[orderBy]);
    // After sorting, always put `favorite` offers first
    filteredOffers.sort((a, b) => {
      if (a.favorite && !b.favorite) return -1;
      if (!a.favorite && b.favorite) return 1;
      return 0;
    });
  }
  return filteredOffers;
};

export function CardBadge({ id, title, className, label }) {
  const map = {
    private: {
      title: "Per privati",
      icon: <MdPerson />,
      className: "bg-blue-100 text-blue-900 ring-blue-200",
    },
    company: {
      title: "Per aziende",
      icon: <MdBusinessCenter />,
      className: "bg-green-100 text-green-900 ring-green-200",
    },
    fastDelivery: {
      title: "Alcune configurazioni sono in consegna veloce (30-60gg)",
      icon: <FaShippingFast />,
      className: "bg-red-100 text-red-800 ring-red-200 animate-pulse",
    },
  };
  return (
    <Badge
      className={clsxm(map[id].className, className)}
      icon={map[id].icon}
      tooltip={title || map[id].title}
      label={label}
    />
  );
}

function OfferCardHeader({ offer }) {
  const priceRange = offer.price_range;
  // The header of the card should show the price range of the offer
  return (
    <div className="flex items-start justify-between w-full p-1">
      <OfferCardBadges
        fastDelivery={offer.fast_delivery}
        target={offer.target}
        className="mr-3"
      />
      <span className="text-zinc-800 text-right text-lg leading-5">
        {Math.abs(priceRange[0] - priceRange[1]) < 5 ? (
          <>
            <span className="font-semibold">{formatNumber(priceRange[0])}</span>{" "}
            <p className="text-sm text-zinc-600">€/mese</p>
          </>
        ) : (
          <>
            <span className="font-semibold">{formatNumber(priceRange[0])}</span>
            -
            <span className="font-semibold">{formatNumber(priceRange[1])}</span>{" "}
            <p className="text-sm text-zinc-600">€/mese</p>
          </>
        )}
      </span>
    </div>
  );
}

function OfferCardBadges({ fastDelivery, target, className }) {
  // The card should show the badges for the vehicle's fast delivery
  // and the target of the offer (private or company)
  return (
    <div className={clsxm("flex", className)}>
      {fastDelivery && <CardBadge id="fastDelivery"></CardBadge>}
      {target === "private" && <CardBadge id="private"></CardBadge>}
      {target === "company" && <CardBadge id="company"></CardBadge>}
      {target === "all" && <CardBadge id="private"></CardBadge>}
      {target === "all" && <CardBadge id="company"></CardBadge>}
    </div>
  );
}

function OfferCard({ offer, onClick: handleClick }) {
  // The card should have the vehicle's name, the image of the vehicle, the
  // vehicle's category.
  return (
    <Card
      onClick={handleClick}
      className="h-96 hover:ring-inset hover:ring-1 hover:shadow-sm ring-zinc-200 group"
    >
      <div className="flex flex-col justify-between h-full p-6">
        <OfferCardHeader offer={offer} />
        <div className="overflow-hidden w-full">
          <img
            className="w-full h-auto scale-125 group-hover:scale-[135%] transition-all"
            src={offer.vehicle.model.image}
            alt={offer.vehicle.model.name}
          />
        </div>
        <div className="flex flex-col">
          <h3 className="text-xl font-semibold">
            {offer?.favorite && (
              <Tooltip content="Offerta Genoleggia">
                <img
                  src={GenoleggiaLogoIcon}
                  className="w-6 h-auto inline-block mr-1 mb-1 text-2xl text-[#d50000]"
                />
              </Tooltip>
            )}
            <span className="text-gray-700">{offer.vehicle.brand.name} </span>
            <span>{offer.vehicle.model.name}</span>
          </h3>
          <p className="text-sm text-gray-500">{offer.vehicle.version}</p>
        </div>
      </div>
    </Card>
  );
}

function OffersListFilterToolbar({
  offersNumber,
  filters,
  setFilters,
  className,
}) {
  return (
    <div
      className={clsxm(
        "flex flex-row items-center gap-3 flex-wrap pr-0 md:pr-12",
        className
      )}
    >
      <span className="flex gap-1.5 items-center text-lg md:text-xl flex-grow md:flex-grow-0 pl-2">
        <b>{offersNumber}</b> {offersNumber === 1 ? "risultato" : "risultati"}
      </span>
      <PillDropdown
        prefix="Ordina"
        icon={<MdSort />}
        options={[
          { value: "a-z", label: "dalla A alla Z" },
          { value: "z-a", label: "dalla Z alla A" },
          { value: "price-asc", label: "per prezzo crescente" },
          { value: "price-desc", label: "per prezzo decrescente" },
        ]}
        value={filters.orderBy}
        onChange={(e) => setFilters({ ...filters, orderBy: e.target.value })}
      />
      <PillDropdown
        prefix="Consegna veloce (30-60gg)?"
        icon={
          <CardBadge
            id="fastDelivery"
            className="mr-1 animate-none -ml-3"
            title="Consegna veloce (30-60gg)"
          ></CardBadge>
        }
        options={[
          { value: true, label: "Sì" },
          { value: false, label: "No" },
        ]}
        value={filters.fastDelivery}
        onChange={(e) =>
          setFilters({ ...filters, fastDelivery: e.target.value })
        }
      />
      <PillDropdown
        prefix="Per chi?"
        icon={
          <div className="flex flex-row mr-1 -ml-3">
            <CardBadge id="private"></CardBadge>
            <CardBadge id="company"></CardBadge>
          </div>
        }
        options={[
          { value: "all", label: "Tutti" },
          { value: "private", label: "Privati" },
          { value: "company", label: "Aziende" },
        ]}
        value={filters.target}
        onChange={(e) => setFilters({ ...filters, target: e.target.value })}
      />
      <div className="flex flex-row flex-wrap gap-3">
        <PillDropdown
          prefix="Da €"
          icon={<MdOutlineVerticalAlignBottom />}
          options={[100, 200, 300, 400, 500, 600, 700, 800, 900]}
          value={filters.minPrice}
          onChange={(e) =>
            setFilters({ ...filters, minPrice: parseInt(e.target.value) || "" })
          }
        />
        <PillDropdown
          prefix="A €"
          icon={<MdOutlineVerticalAlignTop />}
          options={[200, 300, 400, 500, 600, 700, 800, 900, 1000, 1500, 2000]}
          value={filters.maxPrice}
          onChange={(e) =>
            setFilters({ ...filters, maxPrice: parseInt(e.target.value) || "" })
          }
        />
      </div>
      <button
        className="flex flex-row items-center gap-2 px-4 py-[0.33rem] pr-5  font-semibold text-white bg-zinc-800 rounded-full  hover:bg-zinc-900"
        onClick={() => setFilters(DEFAULT_FILTERS)}
      >
        <FaTimes />
        <span>Reset filtri</span>
      </button>
    </div>
  );
}

function OffersListFooter({
  offersNumber,
  handlePageClick,
  itemsPerPage,
  pageNumber,
  className,
}) {
  const pageCount = Math.ceil(offersNumber / itemsPerPage);
  const pageLinkClassName =
    "px-2 font-medium hover:text-blue-800 hover:underline";
  return (
    <>
      {offersNumber > 0 && (
        <div
          className={clsxm("flex flex-row justify-end items-center", className)}
        >
          {pageCount > 1 && (
            <ReactPaginate
              breakLabel=""
              nextLabel="avanti >"
              onPageChange={(e) => handlePageClick(e.selected)}
              forcePage={pageNumber}
              pageRangeDisplayed={5}
              marginPagesDisplayed={0}
              pageCount={pageCount}
              previousLabel="< indietro"
              renderOnZeroPageCount={null}
              className="flex text-lg items-center justify-end py-4 space-x-1 w-auto text-blue-700 select-none"
              pageLinkClassName={pageLinkClassName}
              previousLinkClassName={pageLinkClassName}
              nextLinkClassName={pageLinkClassName}
              activeLinkClassName="text-blue-900 underline text-xl"
              disabledLinkClassName="hidden"
            />
          )}
        </div>
      )}
    </>
  );
}

function OfferListCardSkeleton() {
  return (
    <Card className="h-96">
      <div className="flex flex-col justify-between h-full p-6 gap-2">
        <div className="flex flex-row justify-between w-full">
          <div className="w-6 h-6 bg-gray-200 animate-pulse rounded-full"></div>
          <div className="w-24 h-6 bg-gray-200 animate-pulse rounded"></div>
        </div>
        <div className="w-full h-full bg-gray-200 animate-pulse rounded my-3"></div>
        <div className="flex flex-col gap-2">
          <div className="w-1/2 h-5 bg-gray-200 animate-pulse rounded"></div>
          <div className="w-1/3 h-5 bg-gray-200 animate-pulse rounded"></div>
        </div>
      </div>
    </Card>
  );
}

function OffersList({
  offers,
  isLoading,
  pageNumber,
  itemsPerPage,
  className,
}) {
  const navigate = useNavigate();
  const endOffset = pageNumber * itemsPerPage + itemsPerPage;
  const [currentItems, setCurrentItems] = useState(
    [...offers].slice(pageNumber * itemsPerPage, endOffset)
  );

  useEffect(() => {
    setCurrentItems([...offers].slice(pageNumber * itemsPerPage, endOffset));
  }, [pageNumber, offers]);

  useEffect(() => {
    // When the page changes, scroll to the top
    window.scrollTo(0, 0);
  }, [pageNumber]);

  return (
    <div
      className={clsxm(
        "grid grid-cols-1 gap-3 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5",
        className
      )}
    >
      {currentItems && currentItems.length > 0 ? (
        currentItems.map((offer, index) => (
          <OfferCard
            key={index}
            offer={offer}
            onClick={() => navigate(`/offers/${offer.vehicle.slug}`)}
          />
        ))
      ) : !isLoading && offers.length === 0 ? (
        <span className="text-zinc-700 text-xl p-4">
          Nessun risultato trovato
        </span>
      ) : (
        Array.from({ length: 20 }).map((_, index) => (
          <OfferListCardSkeleton key={index} />
        ))
      )}
    </div>
  );
}

function Tutorial({ showTutorial, setShowTutorial }) {
  return (
    <Modal
      onClose={() => setShowTutorial(false)}
      isOpen={showTutorial}
      title="Tutorial area offerte"
    >
      <div className="p-5 px-7 h-[80vh] flex items-center justify-center">
        <div className="h-full w-full">
          <iframe
            width="100%"
            height="100%"
            src="https://www.youtube.com/embed/r-88jDYVuOI?si=qlLyQEWqVZsucYi4"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </Modal>
  );
}

export default function OfferListPage() {
  // The offers page should have a toolbar with a search bar and a filter button
  // on the top side and a list of cards, each one representing an offer, on the
  // bottom side. The cards should be clickable and should redirect to the offer
  // details page.
  const itemsPerPage = 20;
  const [offers, setOffers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [showTutorial, setShowTutorial] = useState(false);
  const [pageNumber, setPageNumber] = useSessionStorage("offers-pageNumber", 0);
  // prettier-ignore
  const { user } = useAuth();
  const [filters, setFilters] = useSessionStorage(
    "offers-filters",
    DEFAULT_FILTERS
  );
  // Use SWR to fetch the offers from the API, but only once, since they are cached anyway
  const { data } = useSWR(`${API_URL}/offers`, {
    revalidateOnFocus: false,
    revalidateIfStale: false,
  });

  useEffect(() => {
    document.title = "Genoleggiapp | Offerte";
  }, []);

  const handleFiltersChange = (newFilters) => {
    // Whenever the filters change, set the page number to 0
    setPageNumber(0);
    setFilters(newFilters);
  };

  useEffect(() => {
    setIsLoading(true);
    // Whenever the data is loaded, update the offers and the view
    if (data) {
      setIsLoading(false);
      // If there are no filtered offers, set the filtered offers to the offers
      if (offers.length === 0) {
        setOffers(data);
      }
    }
  }, [data]);

  useEffect(() => {
    // Whenever there's a change in the filters, update the offers
    if (data) setOffers(getFilteredOffers(data, filters));
  }, [filters, data]);

  return (
    <Page>
      <Tutorial showTutorial={showTutorial} setShowTutorial={setShowTutorial} />
      <PageHeader
        description="Visualizza tutte le offerte disponibili"
        breadcrumbs={[
          {
            label: (
              <div className="flex flex-row items-center gap-3">
                Offerte
                <TutorialBadge onClick={() => setShowTutorial(true)} />
              </div>
            ),
            path: "/offers",
          },
        ]}
        topRight={
          user?.role === "admin" && (
            <Link to="/offers/new">
              <Button className="py-4" icon={<MdAdd className="text-xl" />}>
                Nuova offerta
              </Button>
            </Link>
          )
        }
      />
      <div className="mb-4">
        <p className="text-zinc-800 bg-yellow-100 rounded-lg p-5 pr-7 flex flex-row items-center">
          <span className="h-auto w-20 flex text-base text-yellow-600 mr-5 ml-2">
            <MdWarning className="w-full my-auto h-full" />
          </span>
          <span className="text-sm">
            <p>
              Ricordiamo agli utenti che le offerte scaricate{" "}
              <b>non sono preventivi</b>, di conseguenza è possibile che a causa
              di forte domanda o di variazioni di mercato il prezzo finale e le
              condizioni dell'offerta possano differire da come indicato. Per
              formalizzare l'offerta è necessario parlare con il backoffice
              all'indirizzo e-mail{" "}
              <a
                className="text-blue-700 hover:underline"
                href="mailto:preventivi@genoleggia.it"
              >
                preventivi@genoleggia.it
              </a>{" "}
              o attraverso la chat disponibile nell'applicazione, il quale vi
              fornirà un preventivo ufficiale.
            </p>
          </span>
        </p>
      </div>
      <Searchbar
        id="search-bar"
        placeholder="Cerca marca, modello o allestimento"
        value={filters.query}
        onChange={(e) => setFilters({ ...filters, query: e.target.value })}
        className="mb-4 lg:w-[65%]"
      />
      <OffersListFilterToolbar
        offersNumber={
          offers.length === 0 && isLoading ? (
            <CgSpinner className="text-xl animate-spin" />
          ) : (
            offers.length
          )
        }
        filters={filters}
        setFilters={handleFiltersChange}
        className="mb-4"
      />
      <OffersList
        offers={offers}
        isLoading={isLoading}
        pageNumber={pageNumber}
        itemsPerPage={itemsPerPage}
      />
      <OffersListFooter
        offersNumber={offers.length}
        handlePageClick={setPageNumber}
        itemsPerPage={itemsPerPage}
        pageNumber={pageNumber}
      />
    </Page>
  );
}
