import { API_URL } from "@/config/routes";
import { fetcher } from "@/utils";
import { toast } from "react-toastify";
import useSwr from "swr";

export default function useTaskLabels() {
  const { data, isLoading, mutate } = useSwr(`${API_URL}/tasks/labels`, {
    fallbackData: [],
  });

  const updateLabel = async ({ id, values }) => {
    await fetcher(`${API_URL}/tasks/labels/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          mutate((labels) =>
            labels.map((label) => (label.id === id ? data : label))
          );
          toast.success("Etichetta aggiornato con successo");
        });
      } else {
        toast.error("Errore durante l'aggiornamento dell'etichetta");
      }
    });
  };

  const deleteLabel = async (label) => {
    await fetcher(`${API_URL}/tasks/labels/${label.id}`, {
      headers: {
        "Content-Type": "application/json",
      },
      method: "DELETE",
    }).then((res) => {
      if (res.ok) {
        mutate((labels) => labels.filter((l) => l.id !== label.id));
        toast.success("Etichetta eliminata con successo");
      } else {
        toast.error("Errore durante l'eliminazione dell'etichetta");
      }
    });
  };

  const createLabel = async (label) => {
    await fetcher(`${API_URL}/tasks/labels`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(label),
    }).then((res) => {
      if (res.ok) {
        res.json().then((data) => {
          mutate((labels) => [...labels, data]);
          toast.success("Etichetta creata con successo");
        });
      } else {
        toast.error("Errore durante la creazione dell'etichetta");
      }
    });
  };

  return {
    labels: data,
    isLoading,
    updateLabel,
    deleteLabel,
    createLabel,
  };
}
