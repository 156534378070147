import Pagination from "@/components/form/Pagination";
import { Searchbar } from "@/components/form/inputs";
import Table from "@/components/ui/Table";
import { API_URL } from "@/config/routes";
import {
  clsxm,
  fetcher,
  formatRelativePastDate,
  objectToQueryString,
} from "@/utils";
import { useState } from "react";
import useSWR from "swr";
import UserLink from "../users/UserLink";

const DEFAULT_QUERY_PARAMS = {
  page: 1,
  per_page: 50,
  sort_by: "updated_at",
  order: "desc",
  q: "",
};

async function SWRFetcher({ url, params }) {
  return fetcher(url + "?" + objectToQueryString(params)).then((res) =>
    res.json()
  );
}

function AccessLogsRow({ accessLog }) {
  return (
    <Table.Row>
      <Table.Td className="text-zinc-500">#{accessLog.id}</Table.Td>
      <Table.Td>
        {new Date(accessLog.created_at).toLocaleString("it-IT")}
        <span className="text-zinc-500 font-medium ml-2">
          ({formatRelativePastDate(accessLog.created_at)})
        </span>
      </Table.Td>
      <Table.Td className="truncate">
        <span className="text-zinc-500">
          Evento di tipo "<b>login</b>" ricevuto dall'utenza:{" "}
          <UserLink user={accessLog.user} />
        </span>
      </Table.Td>
    </Table.Row>
  );
}

function AccessLogsTableHeader({ filters, onFilterChange }) {
  return (
    <Table.Head>
      <Table.Row>
        <Table.Th className="w-[10%]">ID Log</Table.Th>
        <Table.Th
          className="w-[25%]"
          sortable
          sortDirection={
            filters.sort_by === "created_at" ? filters.order : null
          }
          onClick={() =>
            onFilterChange({
              sort_by: "created_at",
              order:
                filters.sort_by === "created_at" && filters.order === "desc"
                  ? "asc"
                  : "desc",
            })
          }
        >
          Data e ora
        </Table.Th>
        <Table.Th>Descrizione</Table.Th>
      </Table.Row>
    </Table.Head>
  );
}

function AccessLogsTable({ accessLogs, isLoading, filters, onFilterChange }) {
  return (
    <Table className="w-full table-fixed">
      <AccessLogsTableHeader
        filters={filters}
        onFilterChange={onFilterChange}
      />
      <Table.Body>
        {!accessLogs &&
          isLoading &&
          Array.from({ length: 20 }).map((_, i) => (
            <Table.SkeletonRow key={i} colsNumber={3}></Table.SkeletonRow>
          ))}
        {accessLogs &&
          accessLogs.map((accessLog) => (
            <AccessLogsRow
              key={accessLog.id}
              accessLog={accessLog}
              filters={filters}
            />
          ))}
      </Table.Body>
    </Table>
  );
}

function AccessLogsSearchbar({ onSubmit, className }) {
  const [searchTerm, setSearchTerm] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(searchTerm);
  };

  return (
    <form
      className={clsxm("w-full flex gap-2", className)}
      onSubmit={handleSubmit}
    >
      <Searchbar
        id="search-access-logs"
        value={searchTerm}
        onChange={(e) =>
          setSearchTerm(e.target.value !== "" ? e.target.value : undefined)
        }
        placeholder="Cerca i log per nome utente"
        className="flex-grow"
      />
      <Searchbar.Button />
    </form>
  );
}

export default function AccessLogsDataTable() {
  const [queryParams, setQueryParams] = useState(DEFAULT_QUERY_PARAMS);
  const { data: accessLogs, isLoading } = useSWR(
    { url: `${API_URL}/analytics/access-logs`, params: queryParams },
    SWRFetcher
  );

  const handleParamsChange = (params) => {
    // Whenever the params change, we want to reset the page to 1
    setQueryParams({ ...queryParams, ...params, page: 1 });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="flex justify-between items-end">
        {/* The search bar */}
        <AccessLogsSearchbar
          className="w-[50%]"
          onSubmit={(searchTerm) => handleParamsChange({ q: searchTerm })}
        />
        <Pagination
          onPageChange={(page) => setQueryParams({ ...queryParams, page })}
          currentPage={queryParams.page}
          totalPages={accessLogs?.pages}
        />
      </div>
      <AccessLogsTable
        accessLogs={accessLogs?.items}
        filters={queryParams}
        isLoading={isLoading}
        onFilterChange={(filters) =>
          handleParamsChange({ ...queryParams, ...filters })
        }
      />
      <div className="flex justify-end ">
        <Pagination
          onPageChange={(page) => setQueryParams({ ...queryParams, page })}
          currentPage={queryParams.page}
          totalPages={accessLogs?.pages}
          perPage={queryParams.per_page}
          showTotal
        />
      </div>
    </div>
  );
}
