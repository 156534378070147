import {
  CreatableSelect,
  FormikSelect,
  Select,
} from "@/components/form/inputs";
import { API_URL } from "@/config/routes";
import useSWR from "swr";

function useVehicleModelOptions({ brandId }) {
  // Only fetch if brandId is defined
  const shouldFetch = brandId !== undefined;

  const { data: models } = useSWR(
    shouldFetch ? `${API_URL}/vehicles/brands/${brandId}/models` : null,
    { fallbackData: [] }
  );

  return (
    models?.map((model) => ({
      value: model.id,
      label: model.name,
      image: model.image,
    })) || []
  );
}

export function VehicleModelSelect({ brandId, ...props }) {
  const options = useVehicleModelOptions({ brandId });
  return <Select options={options} {...props} />;
}
export function CreatableVehicleModelSelect({ brandId, ...props }) {
  const options = useVehicleModelOptions({ brandId });
  return <CreatableSelect options={options} {...props} />;
}

export function FormikVehicleModelSelect({ brandId, ...props }) {
  const options = useVehicleModelOptions({ brandId });
  return <FormikSelect options={options} {...props} />;
}
