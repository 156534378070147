import { clsxm, formatNumber } from "@/utils";
import {
  AreaChart,
  BadgeDelta,
  BarChart,
  Card,
  Flex,
  Grid,
  Metric,
  Tab,
  TabGroup,
  TabList,
  TabPanel,
  TabPanels,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Text,
  Title,
} from "@tremor/react";
import DealOutcomeBadge from "../deals/DealOutcomeBadge";
import UserLink from "../users/UserLink";
import SkeletonDashboard, { SkeletonCharts } from "./SkeletonDashboard";
import useAnalytics from "./useAnalytics";

const formatters = {
  currency: (number) => (number !== "-" ? `€${formatNumber(number)}` : number),
  percentage: (number) =>
    number !== "-" ? `${formatNumber(number * 100, 1)}%` : number,
  timedelta: (seconds) =>
    seconds !== "-"
      ? new Date(1000 * seconds).toISOString().substring(11, 19)
      : seconds,
};

const getDeltaType = (change, deltaType) => {
  change = deltaType === "inverted" ? -change : change;
  if (change > 0) {
    return "moderateIncrease";
  } else if (change < 0) {
    return "moderateDecrease";
  } else {
    return "unchanged";
  }
};

const formatKpiDataPoint = (item) => {
  return {
    ...item,
    metric: item?.type ? formatters[item.type](item.metric) : item.metric,
    previousMonth: item?.type
      ? formatters[item.type](item.previous_month)
      : item.previous_month,
    delta:
      item.change >= 0
        ? `+${formatters.percentage(item.change)}`
        : formatters.percentage(item.change),
    deltaType: getDeltaType(item.change, item?.delta_type),
  };
};

function SimpleKpiCard({ title, metric, previousMonth, delta, deltaType }) {
  const deltaColors = {
    moderateIncrease: "emerald",
    moderateDecrease: "rose",
    unchanged: "orange",
  };

  return (
    <Card>
      <Text>{title}</Text>
      <Flex
        justifyContent="start"
        alignItems="baseline"
        className="truncate space-x-2 mt-1"
      >
        <Metric>{metric}</Metric>
        <Text className="truncate">rispetto a {previousMonth}</Text>
      </Flex>
      <Flex justifyContent="start" className="space-x-2 mt-2">
        <BadgeDelta deltaType={deltaType} />
        <Flex justifyContent="start" className="space-x-1 truncate">
          <Text color={deltaColors[deltaType]}>{delta}</Text>
          <Text className="truncate">dal periodo precedente</Text>
        </Flex>
      </Flex>
    </Card>
  );
}

function OutcomesTable({ data, className }) {
  // Modify data to show DealOutcomeBadge
  const total = data.reduce((acc, item) => acc + item.value, 0);
  return (
    <Table
      className={clsxm(
        "scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100",
        className
      )}
    >
      <TableHead className="sticky top-0 bg-white">
        <TableRow>
          <TableHeaderCell />
          <TableHeaderCell>Esito</TableHeaderCell>
          <TableHeaderCell>Numero</TableHeaderCell>
          <TableHeaderCell>%</TableHeaderCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((row, index) => (
          <TableRow key={row.id}>
            {/* Index column */}
            <TableCell>
              <span>{index + 1}</span>
            </TableCell>
            {/* Iterate over the columns */}
            <TableCell>
              <DealOutcomeBadge outcome={row.name} />
            </TableCell>
            <TableCell>{row.value}</TableCell>
            <TableCell>{formatters.percentage(row.value / total)}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function BackofficeKPIs({ data, className }) {
  // 3 kpi cards to show some data
  return (
    <div className={clsxm("flex flex-col gap-4", className)}>
      {data.map((item) => (
        <SimpleKpiCard
          key={item.title}
          {...formatKpiDataPoint(item)}
          className="w-full"
        />
      ))}
    </div>
  );
}

function Charts({ chartKey }) {
  const { historicalData } = useAnalytics();

  // Check if historicalData is still being fetched
  if (Object.keys(historicalData).length === 0) {
    return <SkeletonCharts className="mt-5" />;
  }

  // Validate if the chartKey exists in historicalData
  if (!historicalData[chartKey]) {
    return <div>No data available for the given key.</div>;
  }

  // Correct the color slicing based on the number of categories
  const getColors = (colorsAmount) => {
    const colors = ["blue", "orange", "green", "purple", "red", "yellow"];
    return colors.slice(0, colorsAmount);
  };

  // Define the formatters
  const formatters = {
    currency: (value) => `€${formatNumber(value, 0)}`,
    percentage: (value) => `${formatNumber(value * 100, 1)}%`,
    int: (value) => formatNumber(value, 0),
  };

  const basicChartArgs = (chart) => {
    return {
      className: "h-[450px]",
      showAnimation: true,
      data: chart.data,
      showLegend: false,
      valueFormatter: formatters[chart.format],
      yAxisWidth: 56,
    };
  };

  const areaChartArgs = (chart) => {
    // Get the categories from the first item
    const categories = Object.keys(chart.data?.[0] || {}).filter(
      (key) => key !== "date"
    );
    // Return the chart arguments
    return {
      ...basicChartArgs(chart),
      index: "date",
      categories: categories,
      stack: chart.stacked,
      colors: getColors(categories.length),
    };
  };

  // Return the chart
  return (
    <div className="grid grid-cols-2 gap-5 mt-5">
      {historicalData[chartKey].map((chart) => {
        return (
          <Card
            className={clsxm(chart.size === "sm" ? "col-span-1" : "col-span-2")}
          >
            <div className="flex flex-col gap-1 mb-4 justify-between">
              <Flex
                className="space-x-0.5"
                justifyContent="start"
                alignItems="center"
              >
                <Title>{chart.title}</Title>
              </Flex>
              <Text>{chart.description || ""}</Text>
            </div>
            {
              // Render the right chart based on the type
              {
                area: <AreaChart {...areaChartArgs(chart)} />,
                bar: <BarChart {...areaChartArgs(chart)} />,
                // Custom table for outcomes
                outcomes_table: (
                  <OutcomesTable
                    data={chart.data}
                    // To mantain consistency with the other charts
                    className={basicChartArgs(chart).className}
                  />
                ),
                backoffice_kpis: (
                  <BackofficeKPIs
                    data={chart.data}
                    className={basicChartArgs(chart).className}
                  />
                ),
              }[chart.type]
            }
          </Card>
        );
      })}
    </div>
  );
}

function Tables({ tableKey }) {
  const { tabularData } = useAnalytics();

  // Check if tabularData is still being fetched
  if (Object.keys(tabularData).length === 0) {
    return <SkeletonCharts className="mt-5" />;
  }

  // Validate if the tableKey exists in tabularData
  if (!tabularData[tableKey]) {
    return <></>;
  }

  // Return the table
  return (
    <div className="grid grid-cols-2 gap-5 mt-5">
      {tabularData[tableKey].map((table) => {
        return (
          <Card
            className={clsxm(
              table?.size === "sm" ? "col-span-1" : "col-span-2"
            )}
          >
            <div className="flex flex-col gap-1 mb-4 justify-between">
              <Flex
                className="space-x-0.5"
                justifyContent="start"
                alignItems="center"
              >
                <Title>{table.title}</Title>
              </Flex>
              <Text>{table.description || ""}</Text>
            </div>

            <Table>
              <TableHead>
                <TableRow>
                  <TableHeaderCell />
                  {table.columns.map((column) => (
                    <TableHeaderCell
                      key={column.name}
                      className={clsxm(
                        "text-left font-semibold text-zinc-500",
                        column.align === "right" && "text-right"
                      )}
                    >
                      {column.label}
                    </TableHeaderCell>
                  ))}
                </TableRow>
              </TableHead>
              <TableBody>
                {/* `data` is a list of objects */}
                {table.data.map((row, index) => (
                  <TableRow key={row.id}>
                    {/* Index column */}
                    <TableCell>
                      <span
                        className={clsxm(
                          "flex w-6 h-6 rounded-full items-center justify-center text-white text-xs",
                          {
                            "bg-amber-400 ring ring-amber-200": index === 0,
                            "bg-gray-400 ring ring-gray-200": index === 1,
                            "bg-orange-800 ring ring-orange-200": index === 2,
                            "font-bold": index < 3,
                            "text-zinc-400": index > 2,
                          }
                        )}
                      >
                        {index + 1}
                      </span>
                    </TableCell>
                    {/* Iterate over the columns */}
                    {table.columns.map((column) => (
                      <TableCell
                        key={column.name}
                        className={clsxm(
                          "text-left",
                          column.align === "right" && "text-right"
                        )}
                      >
                        {{
                          // Format the data based on the type
                          user: <UserLink user={row} />,
                          currency: formatters.currency(row[column.name]),
                        }[column.type] || row[column.name]}
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </Card>
        );
      })}
    </div>
  );
}

export default function Dashboard() {
  const { analytics } = useAnalytics();

  if (Object.keys(analytics).length === 0) {
    return <SkeletonDashboard />;
  }

  return (
    <>
      <TabGroup className="mt-1">
        <TabList>
          {Object.keys(analytics).map((category) => (
            <Tab key={category}>{category}</Tab>
          ))}
        </TabList>
        <TabPanels>
          {Object.entries(analytics).map(([category, item]) => (
            <TabPanel key={item.title}>
              <Grid
                numItemsLg={
                  // 3 items if more than 2, 2 items if 1
                  item.length > 2 ? 3 : item.length === 1 ? 2 : item.length
                }
                className="gap-5 mt-5"
              >
                {item.map((dataPoint, index) => (
                  <div className="">
                    <SimpleKpiCard
                      key={index}
                      {...formatKpiDataPoint(dataPoint)}
                    />
                  </div>
                ))}
              </Grid>
              <Charts chartKey={category} />
              <Tables tableKey={category} />
            </TabPanel>
          ))}
        </TabPanels>
      </TabGroup>
    </>
  );
}
