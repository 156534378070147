import Pagination from "@/components/form/Pagination";
import { Searchbar } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import Table from "@/components/ui/Table";
import { API_URL } from "@/config/routes";
import { useAuth } from "@/hooks/useAuth";
import { clsxm, fetcher, formatDate, objectToQueryString } from "@/utils";
import { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { MdAdd, MdFileDownload, MdReadMore } from "react-icons/md";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";
import ClientLink from "../clients/ClientLink";
import DealLink from "../deals/DealLink";
import UserLink from "../users/UserLink";

async function SWRFetcher({ url, params }) {
  return fetcher(url + "?" + objectToQueryString(params)).then((res) =>
    res.json()
  );
}

function ContractRow({ contract }) {
  return (
    <Table.Row>
      <Table.Td>{formatDate(contract.created_at, "short")}</Table.Td>
      <Table.Td className="truncate">
        <UserLink user={contract.deal.author} />
      </Table.Td>
      <Table.Td className="truncate">
        <ClientLink client={contract.deal.client} />
      </Table.Td>
      <Table.Td className="truncate">{contract.vehicle_name || "-"}</Table.Td>
      <Table.Td className="truncate">
        <DealLink deal={contract.deal} />
      </Table.Td>
      <Table.Td className="pr-6 flex items-center justify-center">
        <Link to={`/contracts/${contract.id}`}>
          <MdReadMore className="text-zinc-300 text-3xl hover:text-blue-700 hover:cursor-pointer" />
        </Link>
      </Table.Td>
    </Table.Row>
  );
}

function ContractsTableHeader({ filters, onFiltersChange }) {
  return (
    <Table.Head>
      <Table.Row>
        <Table.Th
          className="w-[15%]"
          sortable
          sortDirection={
            filters.sort_by === "created_at" ? filters.order : undefined
          }
          onClick={() =>
            onFiltersChange({
              sort_by: "created_at",
              order:
                filters.sort_by === "created_at" && filters.order === "desc"
                  ? "asc"
                  : "desc",
            })
          }
        >
          Creato il
        </Table.Th>
        <Table.Th className="w-[20%]">Produttore</Table.Th>
        <Table.Th className="w-[20%]">Cliente</Table.Th>
        <Table.Th className="w-[20%]">Veicolo</Table.Th>
        <Table.Th className="w-[15%]">Trattativa associata</Table.Th>
        <Table.Th className="w-[10%] text-center pr-6">Dettagli</Table.Th>
      </Table.Row>
    </Table.Head>
  );
}

function ContractsTable({ contracts, filters, onFiltersChange }) {
  return (
    <Table className="w-full table-fixed">
      <ContractsTableHeader
        filters={filters}
        onFiltersChange={onFiltersChange}
      />
      <Table.Body>
        {!contracts &&
          Array.from({ length: 20 }).map((_, i) => (
            <Table.SkeletonRow key={i} colsNumber={6}></Table.SkeletonRow>
          ))}
        {contracts &&
          contracts.map((contract) => (
            <ContractRow key={contract.id} contract={contract} />
          ))}
      </Table.Body>
    </Table>
  );
}

function ContractsSearchbar({ onSubmit, className }) {
  const [searchTerm, setSearchTerm] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(searchTerm);
  };

  return (
    <form
      className={clsxm("w-full flex gap-2", className)}
      onSubmit={handleSubmit}
    >
      <Searchbar
        id="search-contracts"
        value={searchTerm}
        onChange={(e) =>
          setSearchTerm(e.target.value !== "" ? e.target.value : undefined)
        }
        placeholder="Cerca per cliente o produttore"
        className="flex-grow"
      />
      <Searchbar.Button />
    </form>
  );
}

const DEFAULT_QUERY_PARAMS = {
  page: 1,
  per_page: 20,
  sort_by: "created_at",
  order: "desc",
  q: undefined,
};

function ContractsDataTableButtons() {
  const { user } = useAuth();
  const [isDownloadingContracts, setIsDownloadingContracts] = useState(false);

  const downloadContracts = () => {
    setIsDownloadingContracts(true);
    fetcher(`${API_URL}/contracts/export`, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create a new object URL for the blob
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = `contratti_genoleggia_${new Date().toISOString()}.xlsx`;
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setIsDownloadingContracts(false);
      })
      .catch(() => toast.error("Errore durante il download dei contratti"));
  };

  return (
    <div className="flex flex-row gap-2">
      {/* The button to download the contracts if the user has the permission */}
      {user.role === "admin" && (
        <Button
          icon={
            isDownloadingContracts ? (
              <CgSpinner className="text-lg animate-spin" />
            ) : (
              <MdFileDownload className="text-lg" />
            )
          }
          onClick={downloadContracts}
          className="py-4 bg-transparent border border-gray-300 hover:bg-gray-100 text-zinc-800"
        >
          Esporta contratti
        </Button>
      )}

      {/* The button to create a new contract */}
      <Link to="/contracts/new">
        <Button className="py-4" icon={<MdAdd className="text-xl" />}>
          Nuovo contratto
        </Button>
      </Link>
    </div>
  );
}

export default function ContractsDataTable() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParams] = useState({
    ...DEFAULT_QUERY_PARAMS,
    ...Object.fromEntries(searchParams),
  });
  const data = useSWR(
    { url: `${API_URL}/contracts`, params: queryParams },
    SWRFetcher
  );

  const handleParamsChange = (params) => {
    // Whenever the params change, we want to reset the page to 1
    setQueryParams({ ...queryParams, ...params, page: 1 });
  };

  useEffect(() => {
    // Whenever the query params change, we want to update the search params
    const filteredEntries = Object.entries(queryParams).filter(
      ([, value]) => value !== undefined
    );
    setSearchParams(filteredEntries);
  }, [queryParams]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        {/* The search bar for the contracts */}
        <ContractsSearchbar
          className="w-[50%]"
          onSubmit={(searchTerm) => handleParamsChange({ q: searchTerm })}
        />
      </div>
      <ContractsTable
        contracts={data.data?.items}
        filters={queryParams}
        onFiltersChange={(filters) => handleParamsChange({ ...filters })}
      />
      <div className="flex justify-end">
        <Pagination
          onPageChange={(page) => setQueryParams({ ...queryParams, page })}
          currentPage={queryParams.page}
          totalPages={data.data?.pages}
        />
      </div>
    </div>
  );
}

ContractsDataTable.Buttons = ContractsDataTableButtons;
