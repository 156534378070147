import { Page, PageHeader } from "@/components/layout/Page";
import { LoadingDots } from "@/components/ui/Loading";
import { useAuth } from "@/hooks/useAuth";
import { useParams } from "react-router-dom";
import { Card2 } from "../../components/ui/Card";
import UserChangePasswordForm from "./UserChangePasswordForm";
import UserCreateForm from "./UserCreateForm";
import UserDetailsComponent from "./UserDetails";
import UsersDataTable from "./UsersDataTable";
import useUser from "./useUser";

export const userRoutes = [
  { path: "/users", element: <UserList /> },
  { path: "/users/new", element: <UserCreate /> },
  { path: "/users/:id", element: <UserDetails /> },
  { path: "/users/change-password", element: <UserChangePassword /> },
];

function UserList() {
  return (
    <Page title="Utenti" allowedRoles={["admin", "partner", "manager"]}>
      <PageHeader
        breadcrumbs={[{ label: "Utenti" }]}
        description="Visualizza e gestisci gli utenti registrati da te o dalla tua Rete"
        topRight={<UsersDataTable.NewUserButton />}
      />
      <UsersDataTable />
    </Page>
  );
}

function UserDetails() {
  const { id } = useParams();
  const { user } = useUser(id);
  const { user: currentUser } = useAuth();
  // It's important to use the id from the page here as the user object from the context
  // is not updated yet and it would flash the UnauthorizedPage for a second
  const isCurrentUser = currentUser?.id === parseInt(id) || id === "me";

  return (
    <Page
      title={user?.name || "Utente"}
      allowedRoles={["admin", "partner", "manager", isCurrentUser && "user"]}
    >
      <PageHeader
        breadcrumbs={[
          { label: "Utenti", url: "/users" },
          { label: user?.name || <LoadingDots /> },
        ]}
        description="Qui puoi visualizzare e modificare i dettagli dell'utente"
      />
      <UserDetailsComponent />
    </Page>
  );
}

function UserCreate() {
  return (
    <Page title="Nuovo utente" allowedRoles={["admin", "partner", "manager"]}>
      <PageHeader
        breadcrumbs={[
          { label: "Utenti", url: "/users" },
          { label: "Nuovo utente" },
        ]}
      />

      <Card2 className="w-[60%]">
        <Card2.Header>Inserisci i dettagli</Card2.Header>
        <UserCreateForm />
      </Card2>
    </Page>
  );
}

function UserChangePassword() {
  const { user } = useAuth();

  return (
    <Page title={user?.name || "..."}>
      <PageHeader
        breadcrumbs={[
          { label: "Utenti", url: "/users" },
          { label: user?.name || <LoadingDots />, url: `/users/${user?.id}` },
          { label: "Cambia password" },
        ]}
      />
      <Card2 className="w-[60%]">
        <Card2.Header>Cambia password</Card2.Header>
        <UserChangePasswordForm />
      </Card2>
    </Page>
  );
}
