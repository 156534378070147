import { API_URL } from "@/config/routes";
import useSWR from "swr";

export default function useAnalytics() {
  // Fetch analytics
  const { data: analytics, isLoading } = useSWR(`${API_URL}/analytics`, {
    fallbackData: {},
  });
  // Fetch historical data
  const { data: historicalData, isLoadingHistoricalData } = useSWR(
    `${API_URL}/analytics/historical`,
    {
      fallbackData: {},
    }
  );
  // Fetch tabular data
  const { data: tabularData, isLoadingTabularData } = useSWR(
    `${API_URL}/analytics/tables`,
    {
      fallbackData: {},
    }
  );

  return {
    analytics,
    isLoading,
    historicalData,
    isLoadingHistoricalData,
    tabularData,
    isLoadingTabularData,
  };
}
