import { Page, PageHeader } from "@/components/layout/Page";
import { Button } from "@/components/ui/Button";
import { Card2 } from "@/components/ui/Card";
import { useState } from "react";
import { AiFillTag } from "react-icons/ai";
import TaskCreateForm from "./TaskCreateForm";
import TaskDetails from "./TaskDetails";
import TasksDataTable from "./TasksDataTable";
import { TaskLabelTable } from "./labels/TaskLabelTable";

export const tasksRoutes = [
  { path: "/tasks", element: <TaskList /> },
  { path: "/tasks/labels", element: <TaskLabelList /> },
  { path: "/tasks/new", element: <TaskCreate /> },
  { path: "/tasks/:id", element: <TaskDetailsPage /> },
];

function TaskList() {
  return (
    <Page title="Task di sviluppo">
      <PageHeader
        breadcrumbs={[{ label: "Assistenza e sviluppo" }]}
        description="Visualizza e gestisci le richieste di sviluppo o mantenimento della piattaforma"
        topRight={<TasksDataTable.Buttons />}
      />
      <TasksDataTable />
    </Page>
  );
}

function TaskLabelList() {
  const [showCreateNew, setShowCreateNew] = useState(false);
  return (
    <Page title="Gestione etichette">
      <PageHeader
        description={"Gestisci le etichette dei task"}
        breadcrumbs={[
          { href: "/tasks", label: "Richieste" },
          { label: "Gestione etichette" },
        ]}
        topRight={
          <Button
            className="py-4"
            icon={<AiFillTag />}
            onClick={() => setShowCreateNew(!showCreateNew)}
          >
            Crea nuova etichetta
          </Button>
        }
      />
      <div className="flex flex-col gap-2">
        <TaskLabelTable
          createNew={showCreateNew}
          setCreateNew={setShowCreateNew}
        />
      </div>
    </Page>
  );
}

function TaskCreate() {
  return (
    <Page title="Nuovo task">
      <PageHeader
        breadcrumbs={[
          { href: "/tasks", label: "Richieste" },
          { label: "Nuovo task" },
        ]}
      />
      <Card2 className="max-w-4xl">
        <Card2.Header>Inserisci una nuova richiesta</Card2.Header>
        <TaskCreateForm />
      </Card2>
    </Page>
  );
}

function TaskDetailsPage() {
  return (
    <Page title="Dettagli task">
      <PageHeader
        breadcrumbs={[
          { href: "/tasks", label: "Richieste" },
          { label: "Dettaglio richiesta" },
        ]}
      />
      <TaskDetails />
    </Page>
  );
}
