import { Page, PageHeader } from "@/components/layout/Page";
import { Button } from "@/components/ui/Button";
import { Modal } from "@/components/ui/Modal";
import { useAuth } from "@/hooks/useAuth";
import { useState } from "react";
import { BsPlus } from "react-icons/bs";
import AnnouncementForm from "./AnnouncementForm";
import AnnouncementsList from "./AnnouncementsList";

export const announcementsRoutes = [
  { path: "/announcements", element: <Announcements /> },
];

function AnnouncementButtonAndModal() {
  const { user } = useAuth();
  const [showModal, setShowModal] = useState(false);
  // We are gonna use this flag to avoid rerendering the tinyMCE editor
  const [wasModalOpen, setWasModalOpen] = useState(false);

  // Only admins can create announcements
  if (user.role !== "admin") return null;

  return (
    <div className="">
      <Button
        className="py-4"
        onClick={() => {
          setShowModal(true);
          setWasModalOpen(true);
        }}
        icon={<BsPlus className="text-3xl" />}
      >
        Nuova comunicazione
      </Button>

      <Modal
        title="Nuova comunicazione"
        render={wasModalOpen}
        isOpen={showModal}
        onClose={() => setShowModal(false)}
      >
        <AnnouncementForm onClose={() => setShowModal(false)} />
      </Modal>
    </div>
  );
}

function Announcements() {
  return (
    <Page title="Comunicazioni">
      <PageHeader
        breadcrumbs={[{ label: "Comunicazioni" }]}
        description="Visualizza le comunicazioni di Genoleggia"
        topRight={<AnnouncementButtonAndModal />}
      />
      <div className="max-w-5xl">
        <AnnouncementsList />
      </div>
    </Page>
  );
}
