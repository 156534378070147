import { clsxm } from "@/utils";
import {
  MdCancel,
  MdCircle,
  MdDone,
  MdPlayArrow,
  MdTimer,
} from "react-icons/md";

export const TASK_STATUSES = [
  "To do",
  "In coda",
  "In lavorazione",
  "Conclusa",
  "Cancellata",
];

export default function TaskStatusBadge({ status }) {
  const statusClass = {
    "To do": "text-gray-700",
    Conclusa: "text-green-700",
    "In coda": "text-yellow-700",
    "In lavorazione": "text-blue-700",
    Cancellata: "text-red-700",
  }[status];
  const statusIcon = {
    "To do": <MdCircle className="text-sm mr-1" />,
    Conclusa: <MdDone className="text-lg mr-1" />,
    "In coda": <MdTimer className="text-lg mr-1" />,
    "In lavorazione": <MdPlayArrow className="text-xl mr-0.5" />,
    Cancellata: <MdCancel className="text-lg mr-1" />,
  }[status];
  return (
    <span
      className={clsxm(
        "inline-flex items-center text-sm font-medium",
        statusClass
      )}
    >
      {statusIcon} {status}
    </span>
  );
}
