import { Button } from "@/components/ui/Button";
import { clsxm } from "@/utils";
import { UploadButton, UploadDropzone } from "@bytescale/upload-widget-react";
import { BsPlusCircle } from "react-icons/bs";
import { FaPlus, FaUpload } from "react-icons/fa6";

const bytescaleOptionsLocaleIT = {
  addAnotherFile: "Aggiungi un altro file...",
  addAnotherImage: "Aggiungi un'altra immagine...",
  cancel: "annulla",
  "cancelled!": "annullato",
  continue: "Continua",
  crop: "Ritaglia",
  done: "Fatto",
  "error!": "Errore!",
  finish: "Finito",
  finishIcon: true,
  image: "Immagine",
  maxFilesReached: "Numero massimo di file:",
  maxImagesReached: "Numero massimo di immagini:",
  maxSize: "Limite dimensione file:",
  next: "Avanti",
  of: "di",
  orDragDropFile: "...o trascina e rilascia un file.",
  orDragDropFiles: "...o trascina e rilascia dei file.",
  orDragDropImage: "...o trascina e rilascia un'immagine.",
  orDragDropImages: "...o trascina e rilascia delle immagini.",
  pleaseWait: "Attendere prego...",
  remove: "rimuovi",
  "removed!": "rimosso",
  skip: "Salta",
  unsupportedFileType: "Tipo di file non supportato.",
  uploadFile: "Carica un file",
  uploadFiles: "Carica file",
  uploadImage: "Carica un'immagine",
  uploadImages: "Carica immagini",
};

// Configuration: https://www.bytescale.com/docs/upload-widget#configuration
const defaultOptions = {
  apiKey: "public_W142iJpMX8WUpdoF6H3zU16TpLRE", // This is your API key.
  locale: bytescaleOptionsLocaleIT,
  maxFileSizeBytes: 52428800, // 50MB (account limit)
  showFinishButton: true, // Note: You must use 'onUpdate' if you set 'showFinishButton: false' (default).
  showFinishButton: true,
  showRemoveButton: true,
  editor: {
    images: {
      crop: false,
      preview: false,
    },
  },
};

export function BytescaleUploadDropzone({
  onComplete: handleComplete,
  mimeTypes,
  maxFileCount,
  ...props
}) {
  return (
    <UploadDropzone
      options={{
        ...defaultOptions,
        mimeTypes: mimeTypes,
        maxFileCount: maxFileCount,
      }}
      onComplete={handleComplete}
      {...props}
    />
  );
}

export function BytescaleUploadButton({
  onComplete: handleComplete,
  mimeTypes,
  maxFileCount,
  children,
  className,
  ...props
}) {
  return (
    <UploadButton
      options={{
        ...defaultOptions,
        mimeTypes: mimeTypes,
        maxFileCount: maxFileCount,
      }}
      onComplete={handleComplete}
      {...props}
    >
      {({ onClick }) => (
        <Button
          icon={<FaUpload />}
          className={clsxm("py-2", className)}
          onClick={onClick}
        >
          {children}
        </Button>
      )}
    </UploadButton>
  );
}
