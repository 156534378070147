import { FormikInput } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { Form, Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { FaKey, FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";

function LoginFormInputs({ error, setError }) {
  const { values, dirty, isSubmitting } = useFormikContext();

  useEffect(() => {
    // Reset error when form values change
    setError(null);
  }, [values]);

  return (
    <>
      <div className="flex gap-2 items-center w-full">
        <FaUser className="text-xl text-zinc-500" />
        <FormikInput
          name="email"
          type="email"
          placeholder="Inserisci la tua email"
          required
        />
      </div>
      <div className="flex gap-2 items-center w-full mt-2">
        <FaKey className="text-xl text-zinc-500" />
        <FormikInput
          name="password"
          type="password"
          placeholder="Inserisci la tua password"
          required
        />
      </div>
      {error && (
        <div
          className="mt-5 bg-red-100 text-red-700 px-4 py-3 rounded-xl relative"
          role="alert"
        >
          <span
            className="block sm:inline"
            dangerouslySetInnerHTML={{ __html: error }}
          />
        </div>
      )}
      <Button
        className="mt-6 w-48"
        type="submit"
        isLoading={isSubmitting}
        disabled={!dirty || isSubmitting || error}
      >
        Accedi
      </Button>
    </>
  );
}

export default function LoginForm() {
  const { login } = useAuth();
  const [error, setError] = useState(null);

  const handleSubmit = async (values) => {
    // Use the login function from the useAuth hook to login the user.
    try {
      await login(values.email, values.password);
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div className="w-full flex flex-col items-center">
      <Formik
        initialValues={{ email: "", password: "" }}
        onSubmit={handleSubmit}
      >
        <Form className="flex flex-col w-full items-center justify-center">
          <LoginFormInputs error={error} setError={setError} />
        </Form>
      </Formik>
      <Link to="/password-reset-request" className="mt-5">
        <span className="text-xs font-medium text-gray-400 hover:text-blue-500 hover:underline">
          Hai dimenticato la password? Clicca qui.
        </span>
      </Link>
    </div>
  );
}
