import {
  CreatableSelect,
  FormikSelect,
  Select,
} from "@/components/form/inputs";
import { API_URL } from "@/config/routes";
import useSWR from "swr";

function useVehicleVersionOptions({ modelId }) {
  // Only fetch if brandId is defined
  const shouldFetch = modelId !== undefined;

  const { data: versions } = useSWR(
    shouldFetch ? `${API_URL}/vehicles/models/${modelId}/versions` : null,
    { fallbackData: [] }
  );

  return (
    versions?.map((version) => ({
      value: version.id,
      label: version.version, // This is confusing
      slug: version.slug,
    })) || []
  );
}

export function VehicleVersionSelect({ modelId, ...props }) {
  const options = useVehicleVersionOptions({ modelId });
  return <Select options={options} {...props} />;
}

export function CreatableVehicleVersionSelect({ modelId, ...props }) {
  const options = useVehicleVersionOptions({ modelId });
  return <CreatableSelect options={options} {...props} />;
}

export function FormikVehicleVersionSelect({ modelId, ...props }) {
  const options = useVehicleVersionOptions({ modelId });
  return <FormikSelect options={options} {...props} />;
}
