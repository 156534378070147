import { clsxm } from "@/utils";
import {
  MdAlarm,
  MdCancel,
  MdDone,
  MdMoney,
  MdNewReleases,
  MdPlayArrow,
} from "react-icons/md";

export const DEAL_STATUSES = [
  "Nuova richiesta",
  "In trattativa",
  "In attesa del fornitore",
  "Al credito",
  "Conclusa",
  "Non conclusa",
];

export default function DealStatusBadge({ status }) {
  const statusClass = {
    Conclusa: "bg-green-100 text-green-800",
    "Nuova richiesta": "bg-yellow-100 text-yellow-800",
    "In trattativa": "bg-blue-100 text-blue-800",
    "In attesa del fornitore": "bg-stone-100 text-stone-800",
    "Al credito": "bg-purple-100 text-purple-800",
    "Non conclusa": "bg-red-100 text-red-800",
  }[status];
  const statusIcon = {
    Conclusa: <MdDone className="text-lg mr-1" />,
    "Nuova richiesta": <MdNewReleases className="text-lg mr-1" />,
    "In trattativa": <MdPlayArrow className="text-xl mr-0.5" />,
    "In attesa del fornitore": <MdAlarm className="text-lg mr-1" />,
    "Al credito": <MdMoney className="text-lg mr-1" />,
    "Non conclusa": <MdCancel className="text-lg mr-1" />,
  }[status];
  return (
    <span
      className={clsxm(
        "inline-flex items-center px-1 pr-1.5 py-0.5 rounded text-sm font-semibold",
        statusClass
      )}
    >
      {statusIcon} {status}
    </span>
  );
}
