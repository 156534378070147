import { useAuth } from "@/hooks/useAuth";
import { clsxm } from "@/utils";
import { Link } from "react-router-dom";

const USER_ROLE = {
  admin: {
    label: "Amministratore",
    icon: "🔑",
  },
  manager: {
    label: "Manager",
    icon: "👔",
  },
  partner: {
    label: "Socio",
    icon: "🤝",
  },
  user: {
    label: "Utente",
    icon: "",
  },
};

export default function UserLink({ user, className }) {
  const { user: currentUser } = useAuth();

  // If this link points to the parent of the current user, don't link it
  if (currentUser.parent?.id === user.id) {
    return <span>{user.name}</span>;
  }

  return (
    <Link to={`/users/${user.id}`}>
      <span
        className={clsxm(
          "text-blue-900 font-semibold decoration-blue-100 underline decoration-2 hover:decoration-blue-300",
          className
        )}
      >
        {user.name} {currentUser.id === user.id && "(Me)"}{" "}
        {USER_ROLE[user.role].icon}
      </span>
    </Link>
  );
}
