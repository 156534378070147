import { Page, PageHeader } from "@/components/layout/Page";
import { Tooltip } from "@/components/ui/Tooltip";
import { API_URL } from "@/config/routes";
import { fetcher, formatNumber } from "@/utils";
import { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import FastDeliveryDataTable from "./FastDeliveryDataTable";
import FastDeliveryUpdateForm from "./FastDeliveryUpdateForm";

export const fastDeliveryRoutes = [
  { path: "/fast-delivery", element: <FastDeliveryPage /> },
  { path: "/stock", element: <StockPage /> },
  {
    path: "/fast-delivery/update",
    element: <FastDeliveryUpdatePage />,
  },
];

function FastDeliveryTitle({ title, number }) {
  return (
    <div className="flex items-center gap-2">
      <span>{title}</span>
      <Tooltip
        content="Numero di veicoli disponibili complessivamente in questa categoria"
        placement="bottom"
      >
        <span className="font-normal text-zinc-400 flex items-center">
          (
          {number ? (
            formatNumber(number, 0)
          ) : (
            <CgSpinner className="animate-spin" />
          )}
          )
        </span>
      </Tooltip>
    </div>
  );
}

const useMetadata = () => {
  const [metadata, setMetadata] = useState({});

  useEffect(() => {
    fetcher(`${API_URL}/fast-delivery/metadata`)
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw new Error(res.statusText);
        }
      })
      .then((json) => {
        setMetadata(json);
      })
      .catch((err) => {
        console.error(err);
      });
  }, []);

  return metadata;
};

function FastDeliveryPage() {
  const metadata = useMetadata();

  return (
    <Page title="Pronta consegna">
      <PageHeader
        breadcrumbs={[
          {
            label: (
              <FastDeliveryTitle
                title="Pronta consegna"
                number={
                  parseInt(metadata.total_has_license) +
                  parseInt(metadata.total_has_chassis) +
                  parseInt(metadata.total_available)
                }
              />
            ),
          },
        ]}
        topRight={<FastDeliveryDataTable.Buttons />}
        description="I veicoli in pronta consegna sono targati e quindi ordinabili da subito"
      />
      <FastDeliveryDataTable
        status={["HAS_LICENSE", "HAS_CHASSIS", "AVAILABLE"]}
      />
    </Page>
  );
}

function StockPage() {
  const metadata = useMetadata();

  return (
    <Page title="Stock">
      <PageHeader
        breadcrumbs={[
          {
            label: (
              <FastDeliveryTitle title="Stock" number={metadata.total_stock} />
            ),
          },
        ]}
        topRight={<FastDeliveryDataTable.Buttons />}
        description="I veicoli in stock sono tutti i veicoli ordinabili ma non ancora targati"
      />
      <FastDeliveryDataTable status={["STOCK"]} />
    </Page>
  );
}

function FastDeliveryUpdatePage() {
  return (
    <Page title="Aggiorna dati pronta consegna">
      <PageHeader
        breadcrumbs={[
          { label: "Pronta consegna", to: "/fast-delivery" },
          { label: "Upload file per aggiornamento" },
        ]}
        description="Aggiorna i veicoli in pronta consegna con i file dei fornitori"
      />
      <FastDeliveryUpdateForm />
    </Page>
  );
}
