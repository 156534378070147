import { clsxm } from "@/utils";
import ReactDropzone from "react-dropzone";
import { ImUpload } from "react-icons/im";
import { Card2 } from "../ui/Card";
import FileChip from "../ui/FileChip";

export default function Dropzone({
  files,
  setFiles,
  subtitle, // optional subtitle
  accept = undefined, // optional file types to accept
  maxFiles = 0, // optional max number of files to accept
  ...props
}) {
  const handleDrop = (acceptedFiles) => {
    setFiles(acceptedFiles);
  };

  // Caption based on the max number of files to accept
  const captions = {
    0: "Trascina qui i file da caricare",
    1: "Trascina qui il file da caricare",
    default: `Trascina qui fino a ${maxFiles} file da caricare`,
  };

  return (
    <ReactDropzone
      onDrop={handleDrop}
      accept={accept}
      maxFiles={maxFiles}
      {...props}
    >
      {({ getRootProps, getInputProps, isDragActive }) => (
        <section>
          {!files?.length ? (
            <div
              className={clsxm(
                "flex flex-col items-center justify-center p-10 h-full w-full border-2 border-dashed border-gray-300 rounded-lg cursor-pointer",
                isDragActive && "bg-gray-50 border-gray-400"
              )}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <ImUpload className="text-6xl text-gray-300" />
              <p className="text-gray-500 mt-4">
                {captions[maxFiles] || captions.default}
              </p>
              {subtitle && (
                <p className="text-gray-500 mt-2 text-sm">{subtitle}</p>
              )}
            </div>
          ) : (
            <Card2 className="m-0">
              <Card2.Header className="text-base text-gray-500">
                File selezionati
              </Card2.Header>
              <div className="flex flex-row gap-2 flex-wrap">
                {files.map((file) => (
                  <div className="overflow-x-hidden max-w-[32%]">
                    <FileChip name={file.name} id={file?.id} />
                  </div>
                ))}
              </div>
            </Card2>
          )}
        </section>
      )}
    </ReactDropzone>
  );
}
