import { API_URL } from "@/config/routes";
import { fetcher } from "@/utils";
import { toast } from "react-toastify";
import useSWR from "swr";

export default function useAnnouncements() {
  const { data, isLoading, error, mutate } = useSWR(
    `${API_URL}/announcements`,
    {
      fallbackData: [],
    }
  );

  const createAnnouncement = async (announcement) => {
    const res = await fetcher(`${API_URL}/announcements`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(announcement),
    });

    const newAnnouncement = await res.json();

    if (res.ok) {
      const msg = (
        <span>
          Comunicazione <b>#{newAnnouncement.id}</b> creata correttamente
        </span>
      );
      toast.success(msg);
      mutate([newAnnouncement, ...data], false);
    } else {
      toast.error("Errore nella creazione della comunicazione");
    }
  };

  return {
    announcements: data,
    isLoading,
    error,
    createAnnouncement,
  };
}
