import aldLogo from "/images/renting-companies/ald-logo.png";
import alphabetLogo from "/images/renting-companies/alphabet-logo.png";
import arvalLogo from "/images/renting-companies/arval-logo.png";
import athlonLogo from "/images/renting-companies/athlon-logo.svg";
import drivaliaLogo from "/images/renting-companies/drivalia-logo.png";
import leaseplanLogo from "/images/renting-companies/leaseplan-logo.png";
import leasysLogo from "/images/renting-companies/leasys-logo.png";
import volkswagenLeasingLogo from "/images/renting-companies/volkswagen-leasing-logo.svg";

export const logos = {
  ALD: aldLogo,
  Alphabet: alphabetLogo,
  Arval: arvalLogo,
  Athlon: athlonLogo,
  Drivalia: drivaliaLogo,
  Leaseplan: leaseplanLogo,
  Leasys: leasysLogo,
  "Volkswagen Leasing": volkswagenLeasingLogo,
};
