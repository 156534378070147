import Pagination from "@/components/form/Pagination";
import { Searchbar } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { FallbackSpinner } from "@/components/ui/Loading";
import Table from "@/components/ui/Table";
import Tabs from "@/components/ui/Tabs";
import { API_URL } from "@/config/routes";
import { useAuth } from "@/hooks/useAuth";
import {
  clsxm,
  fetcher,
  formatDate,
  formatRelativePastDate,
  objectToQueryString,
} from "@/utils";
import { useState } from "react";
import { FiUserPlus } from "react-icons/fi";
import { MdReadMore } from "react-icons/md";
import { Link } from "react-router-dom";
import useSWR from "swr";
import UserLink from "./UserLink";
import UserStatusSpan from "./UserStatusSpan";

const ROLES_MAP = {
  admin: "Amministratore",
  manager: "Manager",
  partner: "Socio",
  user: "Utente",
};

const DEFAULT_QUERY_PARAMS = {
  page: 1,
  per_page: 20,
  sort_by: "created_at",
  order: "desc",
  q: undefined,
  status: undefined,
};

async function SWRFetcher({ url, params }) {
  return fetcher(url + "?" + objectToQueryString(params)).then((res) =>
    res.json()
  );
}

const useMetadata = () => {
  const { data: metadata, isLoading } = useSWR(`${API_URL}/users/metadata`);
  return { metadata, isLoading };
};

function UsersSearchbar({ filters, setFilters, className }) {
  const [searchTerm, setSearchTerm] = useState(filters.q || undefined);

  const handleSearch = (e) => {
    e.preventDefault();
    setFilters({ q: searchTerm });
  };

  return (
    <form
      className={clsxm("w-full flex gap-2", className)}
      onSubmit={handleSearch}
    >
      <Searchbar
        value={searchTerm}
        onChange={(e) =>
          setSearchTerm(e.target.value !== "" ? e.target.value : undefined)
        }
        placeholder="Cerca per nome, cognome o e-mail"
        className={"flex-grow"}
      />
      <Searchbar.Button />
    </form>
  );
}

function UsersStatusTabs({ filters, setFilters }) {
  const { metadata } = useMetadata();

  return (
    <ul className="flex flex-row justify-between items-center bg-zinc-50 rounded-md">
      {Object.entries({
        Tutti: undefined,
        Invitati: "pending",
      }).map(([label, status]) => (
        <Tabs.Component
          key={status}
          active={filters.status === status}
          onClick={() => setFilters({ status: status })}
          className="rounded-md"
        >
          {label}
          <span className="ml-1 font-medium brightness-150">
            (
            <FallbackSpinner
              className="inline"
              isLoading={metadata === undefined}
            >
              {
                {
                  undefined: metadata?.total,
                  pending: metadata?.by_status?.pending,
                }[status]
              }
            </FallbackSpinner>
            )
          </span>
        </Tabs.Component>
      ))}
    </ul>
  );
}

function UsersDataTableNewUserButton() {
  return (
    <Link to="/users/new">
      <Button className="py-4" icon={<FiUserPlus />}>
        Crea sottoutenza
      </Button>
    </Link>
  );
}

function UsersRoleTabs({ filters, setFilters }) {
  const { metadata } = useMetadata();

  return (
    <ul className="flex flex-row justify-between items-center bg-zinc-50 rounded-md">
      {[["all", "Tutti"], ...Object.entries(ROLES_MAP)].map(([role, label]) => (
        <Tabs.Component
          key={role}
          active={
            filters.role === role ||
            (filters.role === undefined && role === "all")
          }
          onClick={() =>
            setFilters({ role: role === "all" ? undefined : role })
          }
          className="rounded-md"
        >
          {label}
          <span className="ml-1 font-medium brightness-150">
            (
            <FallbackSpinner
              className="inline"
              isLoading={metadata === undefined}
            >
              {
                {
                  all: metadata?.total,
                  ...metadata?.by_role,
                }[role]
              }
            </FallbackSpinner>
            )
          </span>
        </Tabs.Component>
      ))}
    </ul>
  );
}

function UserRow({ user }) {
  const { user: currentUser } = useAuth();
  return (
    <Table.Row>
      <Table.Td>{formatDate(user.created_at, "short")}</Table.Td>
      <Table.Td>{formatRelativePastDate(user.last_login_at, "short")}</Table.Td>
      <Table.Td>
        <UserStatusSpan status={user.status} />
      </Table.Td>
      <Table.Td className="truncate">
        <UserLink user={user} />
      </Table.Td>
      {currentUser.role === "admin" && (
        <Table.Td className="truncate">
          {user?.network?.partner ? (
            <UserLink user={user.network.partner} />
          ) : (
            "-"
          )}
        </Table.Td>
      )}
      <Table.Td className="pr-6">
        <span className="w-full flex justify-center">
          <Link to={`/users/${user.id}`}>
            <MdReadMore className="text-zinc-300 text-3xl hover:text-blue-700 hover:cursor-pointer" />
          </Link>
        </span>
      </Table.Td>
    </Table.Row>
  );
}

function UsersTable({ users, isLoading, filters, setFilters }) {
  const { user } = useAuth();
  return (
    <Table>
      <Table.Head>
        <Table.Row>
          <Table.Th
            sortable
            sortDirection={
              filters.sort_by === "created_at" ? filters.order : undefined
            }
            onClick={() =>
              setFilters({
                sort_by: "created_at",
                order:
                  filters.sort_by === "created_at" && filters.order === "desc"
                    ? "asc"
                    : "desc",
              })
            }
          >
            Creato il
          </Table.Th>
          <Table.Th
            sortable
            sortDirection={
              filters.sort_by === "last_login_at" ? filters.order : undefined
            }
            onClick={() =>
              setFilters({
                sort_by: "last_login_at",
                order:
                  filters.sort_by === "last_login_at" &&
                  filters.order === "desc"
                    ? "asc"
                    : "desc",
              })
            }
          >
            Ultimo accesso
          </Table.Th>
          <Table.Th>Stato</Table.Th>
          <Table.Th className={user.role === "admin" ? "w-1/4" : "w-1/3"}>
            Nome
          </Table.Th>
          {user.role === "admin" && <Table.Th className="w-1/4">Rete</Table.Th>}
          <Table.Th className="text-center">Dettagli</Table.Th>
        </Table.Row>
      </Table.Head>
      <Table.Body>
        {isLoading
          ? // Loading skeleton
            Array.from({ length: 20 }).map((_, i) => (
              <Table.SkeletonRow key={i} colsNumber={6} />
            ))
          : users?.map((user) => <UserRow key={user.id} user={user} />)}
      </Table.Body>
    </Table>
  );
}

export default function UsersDataTable() {
  const [queryParams, setQueryParams] = useState(DEFAULT_QUERY_PARAMS);
  const { user } = useAuth();
  const { data: users, isLoading } = useSWR(
    { url: `${API_URL}/users`, params: queryParams },
    SWRFetcher
  );

  const handleParamsChange = (params) => {
    // Whenever the params change, we want to reset the page to 1
    setQueryParams({ ...queryParams, ...params, page: 1 });
  };

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full">
        <UsersSearchbar
          filters={queryParams}
          setFilters={handleParamsChange}
          className="w-[65%]"
        />
      </div>

      <div className="flex gap-2 justify-between">
        <div className="flex gap-2">
          <UsersStatusTabs
            filters={queryParams}
            setFilters={handleParamsChange}
          />
          {user.role === "admin" && (
            <UsersRoleTabs
              filters={queryParams}
              setFilters={handleParamsChange}
            />
          )}
        </div>
        <Pagination
          onPageChange={(page) => setQueryParams({ ...queryParams, page })}
          currentPage={queryParams.page}
          totalPages={users?.pages}
        />
      </div>

      <UsersTable
        users={users?.items}
        isLoading={isLoading}
        filters={queryParams}
        setFilters={handleParamsChange}
      />
      <div className="flex justify-end">
        <Pagination
          onPageChange={(page) => setQueryParams({ ...queryParams, page })}
          currentPage={queryParams.page}
          totalPages={users?.pages}
        />
      </div>
    </div>
  );
}

UsersDataTable.NewUserButton = UsersDataTableNewUserButton;
