import { Page, PageHeader } from "@/components/layout/Page";
import OfferCreateForm from "./OfferCreateForm";
import OfferDetailsPage from "./OfferDetails";
import OfferListPage from "./OfferList";

export const offerRoutes = [
  { path: "/offers/new", element: <OfferCreatePage /> },
  { path: "/offers/:slug", element: <OfferDetailsPage /> },
  { path: "/offers", element: <OfferListPage /> },
];

function OfferCreatePage() {
  return (
    <Page title="Nuova offerta">
      <PageHeader
        description="Qui puoi creare una nuova offerta, che verrà aggiunta alla pagina delle offerte."
        breadcrumbs={[
          { label: "Offerte", href: "/offers" },
          { label: "Nuova offerta" },
        ]}
      />
      <OfferCreateForm />
    </Page>
  );
}
