import {
  FormikInput,
  FormikSelect,
  FormikTextarea,
} from "@/components/form/inputs";
import { UnauthorizedPage } from "@/components/layout/Page";
import { Card2, SkeletonCard } from "@/components/ui/Card";
import { verticalTableFromRows } from "@/components/ui/Table";
import { API_URL } from "@/config/routes";
import { useAuth } from "@/hooks/useAuth";
import { clsxm, fetcher, formatDate, formatRelativePastDate } from "@/utils";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { ImWhatsapp } from "react-icons/im";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import UserLink from "./UserLink";
import { FormikUserRoleSelect } from "./UserRoleSelect";
import { FormikUserSelect } from "./UserSelect";
import UserStatusSpan from "./UserStatusSpan";
import useUser from "./useUser";

function UserCreatedBySelect({ user, readOnly }) {
  const { user: currentUser } = useAuth();

  if (!readOnly && currentUser.role === "admin") {
    return (
      <FormikUserSelect
        name="parent_id"
        readOnly={readOnly}
        value={user.parent?.id}
      />
    );
  }
  if (user.parent) {
    return <UserLink user={user.parent} />;
  }
  return <i>Admin</i>;
}

function UserStatusSelect({ readOnly }) {
  const { user: currentUser } = useAuth();

  return (
    <FormikSelect
      name="status"
      readOnly={!currentUser.role === "admin" || readOnly}
      options={[
        { label: <UserStatusSpan status="active" />, value: "active" },
        { label: <UserStatusSpan status="blocked" />, value: "blocked" },
        { label: <UserStatusSpan status="pending" />, value: "pending" },
      ]}
    />
  );
}

function UserPhoneInput({ user, readOnly, name }) {
  return (
    <div className="flex gap-3 items-baseline">
      <FormikInput
        placeholder="Numero di telefono"
        readOnly={readOnly}
        name={name}
        required
      />
      {user.phone && readOnly && (
        <a
          href={`https://wa.me/${user.phone}`}
          target="_blank"
          className="text-white font-medium text-sm flex items-center gap-1 hover:bg-green-600 bg-green-500 rounded-sm px-1 py-0.5 ring-1 ring-green-300"
        >
          Messaggia su <ImWhatsapp className="ml-0.5 text-base" />
          WhatsApp
        </a>
      )}
    </div>
  );
}

function UserCommissionInput({ user, readOnly }) {
  const { user: currentUser } = useAuth();

  // Only admins, managers and partners can edit the commission percentage
  // And only admins can change their own commission percentage
  const canEdit =
    (["manager", "partner"].includes(currentUser.role) &&
      user.id !== currentUser.id) ||
    currentUser.role === "admin";

  return (
    <FormikInput name="commission_percentage" readOnly={!canEdit || readOnly} />
  );
}

export default function UserDetails({
  className,
  userId,
  stayEditing,
  onSave,
}) {
  const { id } = userId ? { id: userId } : useParams();
  const { user, isLoading, mutate, error } = useUser(id);
  const { user: currentUser } = useAuth();
  const [isEditing, setIsEditing] = useState(false);

  useEffect(() => {
    if (stayEditing) {
      setIsEditing(true);
    }
  }, [stayEditing]);

  const handleSubmit = (values) => {
    // PATCH request to update the user
    fetcher(`${API_URL}/users/${id}`, {
      method: "PATCH",
      body: JSON.stringify(values),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          toast.success("Utente aggiornato con successo!");
          !stayEditing && setIsEditing(false);
          mutate();
          onSave && onSave();
        } else {
          toast.error("Si è verificato un errore");
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error("Si è verificato un errore");
      });
  };

  if (isLoading) {
    return <SkeletonCard className={clsxm("w-[60%]", className)} />;
  }

  if (error) {
    return <UnauthorizedPage />;
  }

  const initialValues = {
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    phone: user.phone,
    status: user.status,
    parent_id: user.parent?.id,
    role: user.role,
    commission_percentage: user.commission_percentage,
    admin_notes: user.admin_notes,
  };

  return (
    <Card2 className={clsxm("w-[60%]", className)}>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        <Form>
          <div className="flex px-1 mb-1">
            <Card2.Header>Dettagli utente</Card2.Header>
            <Card2.EditButton
              noCancelButton={stayEditing}
              isEditing={isEditing}
              setIsEditing={stayEditing ? undefined : setIsEditing}
            />
          </div>
          {/* Make table with helper method */}
          {verticalTableFromRows(
            {
              labelClassName: "w-1/4 w-min-content",
              valueClassName: "whitespace-normal",
            },
            [
              {
                label: "Creato il",
                value: formatDate(user.created_at),
              },
              {
                label: "Ultimo accesso",
                value: (
                  <span>
                    {formatDate(user.last_login_at)} (
                    {formatRelativePastDate(user.last_login_at)})
                  </span>
                ),
              },
              {
                label: "Creato da",
                value: (
                  <UserCreatedBySelect user={user} readOnly={!isEditing} />
                ),
              },
              {
                label: "Nome",
                value: (
                  <FormikInput
                    placeholder="Nome dell'utente"
                    readOnly={!isEditing}
                    name="first_name"
                    required
                  />
                ),
              },
              {
                label: "Cognome",
                value: (
                  <FormikInput
                    placeholder="Cognome dell'utente"
                    readOnly={!isEditing}
                    name="last_name"
                    required
                  />
                ),
              },
              {
                label: "E-mail",
                value: (
                  <FormikInput
                    placeholder="Indirizzo e-mail"
                    readOnly={!isEditing}
                    name="email"
                    required
                  />
                ),
              },
              {
                label: "Telefono",
                value: (
                  <UserPhoneInput
                    readOnly={!isEditing}
                    user={user}
                    name="phone"
                  />
                ),
              },
              user.id !== currentUser.id && {
                label: "Stato",
                value: <UserStatusSelect user={user} readOnly={!isEditing} />,
              },
              user.id !== currentUser.id && {
                label: "Tipo utente",
                value: (
                  <FormikUserRoleSelect
                    name="role"
                    readOnly={!isEditing}
                    user={user}
                  />
                ),
              },
              user.id !== currentUser.id && {
                label: "Commissione %",
                value: (
                  <UserCommissionInput readOnly={!isEditing} user={user} />
                ),
              },
              currentUser.role === "admin" && {
                label: "Note amministrazione",
                value: (
                  <FormikTextarea
                    placeholder="Eventuali note amministrative"
                    readOnly={!isEditing}
                    name="admin_notes"
                  />
                ),
              },
            ]
          )}
        </Form>
      </Formik>
    </Card2>
  );
}
