import { formatDate } from "@/utils";
import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";

export default function OfferPDF({ data, selectedOffer }) {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* Header */}
        <View style={{ flexDirection: "row" }}>
          <View style={{ width: "80%", justifyContent: "left" }}>
            <Image
              src={"/images/logo-full.png"}
              style={{
                height: "auto",
                width: "50%",
                marginTop: "10px",
                marginBottom: "10px",
                marginLeft: "15px",
              }}
            />
          </View>
          <View style={{ width: "40%" }}>
            <View
              style={{
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <View style={{ lineHeight: "1.3" }}>
                <Text
                  style={{
                    fontWeight: "bold",
                    marginBottom: "3px",
                    textAlign: "right",
                    display: "flex",
                    alignSelf: "flex-end",
                  }}
                >
                  Genoleggia S.p.A.
                </Text>
                <Text style={styles.headerSubtitle}>P.IVA 02824900993</Text>
                <Text style={styles.headerSubtitle}>
                  Piazza della Vittoria 9, 16121 Genova
                </Text>
                <Text style={styles.headerSubtitle}>
                  direzione@genoleggia.it
                </Text>
              </View>
            </View>
          </View>
        </View>

        {/* Vehicle image */}
        <Image
          src={data.vehicle.model.image}
          style={{
            margin: "15px auto",
            display: "block",
            height: "200px",
            width: "auto",
            padding: "0 40px",
          }}
        />
        <Text
          style={{
            textAlign: "center",
            fontSize: 6,
            color: "#a8a29e",
            marginBottom: "20px",
          }}
        >
          Il colore del veicolo in foto è puramente indicativo
        </Text>

        {/* Vehicle details */}
        <Text style={{ textAlign: "center", fontSize: 20, fontWeight: 800 }}>
          {data.vehicle.brand.name} {data.vehicle.model.name}
        </Text>
        <Text style={{ textAlign: "center", fontSize: 18, marginTop: "10px" }}>
          {data.vehicle.version}
        </Text>

        {/* Configuration details */}
        <View style={{ marginTop: "30px", padding: "0 40px" }}>
          <View style={{ flexDirection: "row" }}>
            <View style={styles.cell}>
              <Text style={styles.cellTitle}>Durata</Text>
              <Text>{selectedOffer.duration} mesi</Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTitle}>Distanza</Text>
              <Text>
                {Intl.NumberFormat("it-IT", {
                  minimumFractionDigits: 0,
                  maximumFractionDigits: 0,
                }).format(selectedOffer.distance) + " km/anno"}
              </Text>
            </View>
            <View style={styles.cell}>
              <Text style={styles.cellTitle}>Anticipo</Text>
              <Text>
                {Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(selectedOffer.deposit)}
              </Text>
            </View>
            <View style={[styles.cell, { borderRight: "1px solid #e2e8f0" }]}>
              <Text style={styles.cellTitle}>Canone</Text>
              <Text>
                {Intl.NumberFormat("it-IT", {
                  style: "currency",
                  currency: "EUR",
                  maximumFractionDigits: 0,
                  minimumFractionDigits: 0,
                }).format(selectedOffer.price) + "/mese"}
              </Text>
            </View>
          </View>
        </View>

        {/* Subtitle to warn about missing VAT */}
        <Text
          style={{
            fontSize: 6,
            color: "#a8a29e",
            marginTop: "5px",
            paddingLeft: "40px",
          }}
        >
          Tutti gli importi sono da intendersi IVA esclusa. Offerta valida fino
          al {formatDate(selectedOffer?.valid_until)}.
        </Text>

        {/* Services */}
        <View style={{ marginTop: "15px", padding: "0 40px" }}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={[
                styles.cell,
                { width: "100%", borderRight: "1px solid #e2e8f0" },
              ]}
            >
              <Text style={styles.cellTitle}>Servizi inclusi</Text>
              <Text
                style={{ fontSize: 8, color: "#3f3f46", textAlign: "justify" }}
              >
                {data.included_services?.map((s) => s.name).join(", ")}
              </Text>
            </View>
          </View>
        </View>

        {/* Accessories */}
        <View style={{ marginTop: "15px", padding: "0 40px" }}>
          <View style={{ flexDirection: "row" }}>
            <View
              style={[
                styles.cell,
                { width: "100%", borderRight: "1px solid #e2e8f0" },
              ]}
            >
              <Text style={styles.cellTitle}>Equipaggiamento veicolo</Text>
              <Text
                style={{
                  fontSize: 8,
                  maxLines: 9,
                  textOverflow: "ellipsis",
                  color: "#3f3f46",
                  textAlign: "justify",
                }}
              >
                {data.vehicle.stock_equipment?.map((a) => a).join(", ")}
              </Text>
            </View>
          </View>
        </View>

        {/* Terms and conditions */}
        <View style={{ marginTop: "auto", padding: "0 40px" }}>
          <Text
            style={{
              fontWeight: "bold",
              fontSize: 9,
              marginBottom: "5px",
            }}
          >
            Termini e condizioni
          </Text>
          <Text style={{ fontSize: 6, color: "#52525b", textAlign: "justify" }}>
            Offerta economica limitata valida fino ad esaurimento scorte, da
            considerarsi unicamente a titolo informativo ed in ogni caso
            subordinata alla effettiva disponibilità del veicolo sopra
            descritto. Il presente documento non costituisce offerta
            contrattuale. I canoni, le dotazioni di serie e i servizi inclusi
            potrebbero essere suscettibili di variazioni. Tutti gli importi sono
            da intendersi IVA esclusa. L'imposta da applicare è pari al 22%
            salvo differenti disposizioni di legge. I tempi di consegna del
            veicolo sono indicativi e potrebbero subire variazioni imputabili in
            modo esclusivo alle case costruttrici.
          </Text>
        </View>
      </Page>
    </Document>
  );
}

// Register fonts
Font.register({
  family: "Inter",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyfMZhrib2Bg-4.ttf",
      fontWeight: 200,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuLyfMZhrib2Bg-4.ttf",
      fontWeight: 400,
    },
    {
      src: "https://fonts.gstatic.com/s/inter/v12/UcCO3FwrK3iLTeHuS_fvQtMwCp50KnMw2boKoduKmMEVuDyYMZhrib2Bg-4.ttf",
      fontWeight: 800,
    },
  ],
});

// Create styles
const styles = StyleSheet.create({
  page: {
    paddingTop: 35,
    paddingBottom: 65,
    paddingHorizontal: 35,
    fontFamily: "Inter",
    fontSize: 10,
  },
  cell: {
    width: "25%",
    border: "1px solid #e2e8f0",
    borderRight: "none",
    padding: "10px",
    paddingTop: "0px",
  },
  cellTitle: {
    fontSize: 9,
    fontWeight: "bold",
    borderBottom: "1px solid #e2e8f0",
    margin: "0 -10px 9px -10px",
    padding: "5px 10px 6px 10px",
    backgroundColor: "#f7fafc",
  },
  headerSubtitle: {
    fontSize: 9,
    textAlign: "right",
    display: "flex",
    alignSelf: "flex-end",
  },
});
