import { clsxm } from "@/utils";
import murmur from "murmurhash-js";

function stringToColor(str) {
  const hash = murmur.murmur3(str); // Using MurmurHash3 for better distribution

  // Convert the hash to 6-digit hexadecimal and take the last 6 characters.
  // Prepend with zeroes if the length is less than 6.
  let color = (hash & 0xffffff).toString(16).toUpperCase();
  color = "000000".substring(0, 6 - color.length) + color;

  // Adjust the brightness to produce darker colors.
  // Here we're simply reducing each RGB component by a factor.
  const r = Math.floor(parseInt(color.substring(0, 2), 16) * 0.7);
  const g = Math.floor(parseInt(color.substring(2, 4), 16) * 0.7);
  const b = Math.floor(parseInt(color.substring(4, 6), 16) * 0.7);

  const darkColor = `${r.toString(16).padStart(2, "0")}${g
    .toString(16)
    .padStart(2, "0")}${b.toString(16).padStart(2, "0")}`;

  return darkColor;
}

function getColorAndBgFromText(text) {
  const color = stringToColor(text);
  const bgColor = `#${color}33`; // Append '33' for 20% opacity

  return {
    color: `#${color}`,
    bgColor,
  };
}

export default function DealOutcomeBadge({ outcome }) {
  const { color, bgColor } = getColorAndBgFromText(outcome);

  return (
    <span
      className={clsxm(
        "inline-flex items-center px-1.5 py-0.5 rounded text-sm font-semibold"
      )}
      style={{ color: color, backgroundColor: bgColor }}
    >
      {outcome}
    </span>
  );
}
