import {
  FormikCheckbox,
  FormikDateInput,
  FormikInput,
} from "@/components/form/inputs";
import { BytescaleUploadButton } from "@/components/form/inputs/BytescaleUpload";
import { Card2, SkeletonCard } from "@/components/ui/Card";
import FileChip, { EphemeralFileChip } from "@/components/ui/FileChip";
import { verticalTableFromRows } from "@/components/ui/Table";
import { formatDate } from "@/utils";
import { Form, Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ClientLink from "../clients/ClientLink";
import useContract from "../contracts/useContract";
import DealLink from "../deals/DealLink";
import ContractLink from "./ContractLink";

function ContractDateInput({ name, readOnly, required }) {
  return (
    <FormikDateInput
      readOnly={readOnly}
      required={required}
      name={name}
      min="2022-01-01" // The company was founded in 2022
      max="2030-12-31"
    />
  );
}

const getFilenameFromUrl = (url) =>
  url.split("/")?.pop()?.split("-")?.slice(1).join("-");

function UploadDocuments({ documents, setDocuments, readOnly }) {
  if (readOnly) {
    return (
      <div className="flex flex-wrap gap-2">
        {documents.length > 0 && (
          <>
            {documents.map((documentUrl) => (
              <FileChip
                size="small"
                key={documentUrl}
                url={documentUrl}
                name={getFilenameFromUrl(documentUrl)}
              />
            ))}
          </>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-wrap gap-2">
      {documents.length > 0 && (
        <>
          {documents.map((documentUrl) => (
            <EphemeralFileChip
              key={documentUrl}
              name={getFilenameFromUrl(documentUrl)}
              onDelete={() => {
                setDocuments((prev) =>
                  prev.filter((prevUrl) => prevUrl !== documentUrl)
                );
              }}
            />
          ))}
        </>
      )}
      <BytescaleUploadButton
        maxFileCount={16}
        onComplete={(files) => {
          setDocuments((prev) => [
            ...prev,
            ...files.map((file) => file.fileUrl),
          ]);
        }}
      >
        Carica {documents.length > 0 ? "altri documenti" : "documenti"}
      </BytescaleUploadButton>
    </div>
  );
}

function AdditionalServicesCheckboxes({ readOnly }) {
  return (
    <div className="flex flex-col gap-2">
      <FormikCheckbox label="Gomme" name="tires_included" readOnly={readOnly} />
      <FormikCheckbox
        label="Veicolo sostitutivo"
        name="replacement_car_included"
        readOnly={readOnly}
      />
    </div>
  );
}

function ContractDetailsRows({ contract, isEditing }) {
  const [documents, setDocuments] = useState(contract?.documents || []);
  const { setFieldValue } = useFormikContext();

  // Update the formik state of documents on change
  useEffect(() => {
    setFieldValue("documents", documents);
  }, [documents]);

  return verticalTableFromRows(
    {
      labelClassName: "w-[30%]",
      valueClassName: "whitespace-normal",
    },
    [
      {
        label: "Codice identificativo",
        value: <ContractLink contract={contract} />,
      },
      {
        label: "Data creazione",
        value: formatDate(contract.created_at),
      },

      {
        label: "Trattativa associata",
        value: <DealLink deal={contract.deal} />,
      },
      {
        label: "Cliente associato",
        value: <ClientLink client={contract.deal.client} />,
      },
      {
        label: "Veicolo",
        subLabel: "Nome o descrizione del veicolo",
        value: (
          <FormikInput
            readOnly={!isEditing}
            name="vehicle_name"
            placeholder="Fiat Panda, BMW X5, ..."
          />
        ),
      },
      {
        label: "Km (annui)",
        value: (
          <FormikInput
            readOnly={!isEditing}
            name="distance"
            type="number"
            required
            suffix="km"
            min={0}
            max={100000}
            step={1000}
            placeholder="Inserisci il numero di km percorsi annualmente"
          />
        ),
      },
      {
        label: "Durata (mesi)",
        value: (
          <FormikInput
            readOnly={!isEditing}
            name="duration"
            type="number"
            suffix="mesi"
            min={12}
            max={60}
            step={12}
            required
            placeholder="Inserisci il numero di mesi del contratto"
          />
        ),
      },
      {
        label: "Canone (€)",
        value: (
          <FormikInput
            readOnly={!isEditing}
            name="price"
            type="number"
            suffix="€"
            min={0}
            step={0.01}
            placeholder="Inserisci la rata mensile"
            required
          />
        ),
      },
      {
        label: "Anticipo (€)",
        value: (
          <FormikInput
            readOnly={!isEditing}
            name="deposit"
            required
            suffix="€"
            type="number"
            min={0}
            step={0.01}
            placeholder="Inserisci l'anticipo"
          />
        ),
      },
      {
        label: "Costo km extra (€)",
        value: (
          <FormikInput
            readOnly={!isEditing}
            name="extra_distance_price"
            type="number"
            suffix="€"
            min={0}
            step={0.01}
            placeholder="Inserisci il costo per km extra"
          />
        ),
      },
      {
        label: "Società di noleggio",
        value: (
          <FormikInput required name="renting_company" readOnly={!isEditing} />
        ),
      },
      {
        label: "Società di intermediazione",
        value: (
          <FormikInput
            name="renting_intermediary"
            placeholder="La società che ha intermediato questo contratto (Genoleggia, iDeal, ...)"
            readOnly={!isEditing}
          />
        ),
      },
      {
        label: "Servizi aggiuntivi",
        value: <AdditionalServicesCheckboxes readOnly={!isEditing} />,
      },
      {
        label: "Provvigioni Genoleggia",
        value: (
          <FormikInput
            required
            name="genoleggia_commission"
            readOnly={!isEditing}
            min={0}
            step={0.01}
            type="number"
            suffix="€"
          />
        ),
      },
      {
        label: "Provvigioni Socio",
        value: (
          <FormikInput
            placeholder="Quanto viene retrocesso alla rete di vendita"
            name="partner_commission"
            readOnly={!isEditing}
            min={0}
            step={0.01}
            type="number"
            suffix="€"
          />
        ),
      },
      {
        label: "Data firma contratto",
        value: (
          <ContractDateInput
            required={true}
            name="contract_close_date"
            readOnly={!isEditing}
          />
        ),
      },
      {
        label: "Data consegna al cliente",
        value: (
          <ContractDateInput
            name="client_delivery_date"
            readOnly={!isEditing}
          />
        ),
      },
      {
        label: "Data incasso provvigioni Genoleggia",
        value: (
          <ContractDateInput
            name="genoleggia_commission_received_date"
            readOnly={!isEditing}
          />
        ),
      },
      {
        label: "Data incasso provvigioni Socio",
        value: (
          <ContractDateInput
            name="partner_commission_received_date"
            readOnly={!isEditing}
          />
        ),
      },
      {
        label: "Documenti",
        value: (
          <UploadDocuments
            documents={documents}
            setDocuments={setDocuments}
            readOnly={!isEditing}
          />
        ),
      },
    ]
  );
}

export default function ContractDetails() {
  const { id } = useParams();
  const { contract, isLoading, updateContract } = useContract(id);
  const [isEditing, setIsEditing] = useState(false);

  if (isLoading) {
    return <SkeletonCard />;
  }

  const initialValues = {
    deal_id: contract?.deal_id,
    vehicle_name: contract?.vehicle_name,
    distance: contract?.distance,
    duration: contract?.duration,
    deposit: contract?.deposit,
    price: contract?.price,
    extra_distance_price: contract?.extra_distance_price,
    renting_company: contract?.renting_company,
    renting_intermediary: contract?.renting_intermediary,
    genoleggia_commission: contract?.genoleggia_commission,
    partner_commission: contract?.partner_commission,
    tires_included: contract?.tires_included,
    replacement_car_included: contract?.replacement_car_included,
    contract_close_date: contract?.contract_close_date,
    client_delivery_date: contract?.client_delivery_date,
    genoleggia_commission_received_date:
      contract?.genoleggia_commission_received_date,
    partner_commission_received_date:
      contract?.partner_commission_received_date,
    documents: contract?.documents,
  };

  return (
    <Card2>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          updateContract(values)
            .then(() => {
              setIsEditing(false);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {() => (
          <Form>
            <div className="flex px-1 mb-1">
              <Card2.Header>Dettagli contratto</Card2.Header>
              <Card2.EditButton
                className="bg-blue-500 hover:bg-blue-700"
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </div>
            <ContractDetailsRows contract={contract} isEditing={isEditing} />
          </Form>
        )}
      </Formik>
    </Card2>
  );
}
