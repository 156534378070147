import { FormikInput } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { useAuth } from "@/hooks/useAuth";
import { Form, Formik } from "formik";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";

export default function PasswordResetRequestForm() {
  const { requestPasswordReset } = useAuth();

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    try {
      await requestPasswordReset(values.email);
      toast.success(
        "Ti abbiamo inviato una mail con le istruzioni per resettare la password"
      );
      resetForm();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="flex flex-col w-full">
      <Formik initialValues={{ email: "" }} onSubmit={handleSubmit}>
        {({ dirty, isSubmitting }) => (
          <Form className="w-full">
            <FormikInput
              className="mb-2"
              label="Email"
              name="email"
              type="email"
              placeholder="Inserisci la tua email"
              required
            />
            <span className="text-zinc-500 text-sm">
              Inserisci il tuo indirizzo e-mail per ripristinare la tua
              password. Riceverai un e-mail con un link,{" "}
              <b>clicca sul link per resettare la tua password</b>.
            </span>
            <Button
              className="mt-4 w-full"
              type="submit"
              isLoading={isSubmitting}
              disabled={!dirty || isSubmitting}
            >
              <span className="w-full text-center">Conferma</span>
            </Button>
          </Form>
        )}
      </Formik>
      <Link to="/login" className="mt-5 w-fit">
        <span className="text-xs font-medium text-gray-400 hover:text-blue-500 hover:underline">
          ← Torna alla pagina di login
        </span>
      </Link>
    </div>
  );
}
