import clsxm from "clsx";
import { useField } from "formik";
import Label from "../Label";

export function Textarea({ label, readOnly, className, error, ...props }) {
  // If the input is not editable, just display the value
  if (readOnly) {
    return (
      <>
        <span className="flex gap-1">
          {props?.value || props?.value === 0 ? props.value : "-"}
        </span>
        <input type="hidden" name={props?.name} value={props?.value} />
      </>
    );
  }
  return (
    <div className={clsxm("flex flex-col", className)}>
      {label && <Label>{label}</Label>}
      <textarea
        {...props}
        className={clsxm(
          "w-full leading-tight text-zinc-900 focus:outline-1 focus:outline-blue-100 rounded-lg p-2 bg-white border hover:border-gray-300",
          error && "border-red-500",
          props?.isDisabled &&
            "bg-zinc-100 hover:cursor-not-allowed pointer-events-none"
        )}
      />
      {error && <div className="text-red-500 text-xs italic">{error}</div>}
    </div>
  );
}

export function FormikTextarea({ name, ...props }) {
  // Access formik's props
  const [field, meta, helpers] = useField(name, { type: "textarea" });

  return (
    <Textarea
      {...field}
      {...props}
      name={name}
      error={meta.touched && meta.error}
      onChange={(e) => {
        helpers.setValue(e.target.value);
        if (props?.onChange) {
          props.onChange(e);
        }
      }}
    />
  );
}
