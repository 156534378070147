import { clsxm } from "@/utils";
import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

const DropdownContext = createContext();

export const useDropdown = () => {
  return useContext(DropdownContext);
};

export const Dropdown = ({ children, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [position, setPosition] = useState({});
  const containerRef = useRef(null);

  // Toggle the dropdown
  const toggle = (triggerRef) => {
    const triggerRect = triggerRef.current.getBoundingClientRect();
    const containerRect = containerRef.current.getBoundingClientRect();
    // Calculate the position of the dropdown
    setPosition({
      top:
        triggerRect.top -
        containerRect.top -
        containerRect.height -
        2 * triggerRect.height - // I don't know why this is needed
        25, // gap between trigger and dropdown
      left: triggerRect.left - containerRect.left,
      width: triggerRect.width,
    });
    setIsOpen(!isOpen);
  };

  // Close the dropdown
  const close = () => setIsOpen(false);

  // Close the dropdown when the user clicks outside of it
  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (e.target.closest(".relative")) return;
      close();
    };
    window.addEventListener("mousedown", handleOutsideClick);
    return () => window.removeEventListener("mousedown", handleOutsideClick);
  }, []);

  return (
    <div
      className={clsxm("relative text-zinc-700", className)}
      ref={containerRef}
    >
      <DropdownContext.Provider value={{ isOpen, toggle, close }}>
        {/* Render DropdownTrigger */}
        {React.Children.map(children, (child) => {
          if (child.type === DropdownTrigger) {
            return React.cloneElement(child);
          }
          return null;
        })}
        {/* Render DropdownItem */}
        {isOpen && (
          <div
            style={position}
            className="absolute border py-2 rounded-lg shadow-md bg-white"
          >
            {React.Children.map(children, (child) => {
              if (child.type !== DropdownTrigger) {
                return React.cloneElement(child);
              }
              return null;
            })}
          </div>
        )}
      </DropdownContext.Provider>
    </div>
  );
};

export const DropdownTrigger = ({ children, className }) => {
  const { toggle } = useDropdown();
  const triggerRef = useRef(null);

  // Trigger a re-render when the triggerRef is set
  useEffect(() => {
    triggerRef.current && triggerRef.current.getBoundingClientRect();
  }, []);

  return (
    <button
      ref={triggerRef}
      onClick={() => toggle(triggerRef)} // Pass the triggerRef to the toggle function
      className={clsxm("px-4 py-2 w-full bg-white rounded-lg", className)}
    >
      {children}
    </button>
  );
};

export const DropdownItem = ({
  children,
  icon,
  className,
  onClick,
  as: AsElement,
  ...props
}) => {
  const { isOpen } = useDropdown();
  const ElementToRender = AsElement || "div";
  return isOpen ? (
    <ElementToRender
      {...props}
      onClick={onClick}
      className={clsxm(
        "flex gap-2 p-2 mx-4 hover:bg-gray-100 rounded-lg cursor-pointer",
        className
      )}
    >
      {icon && <div className="text-lg">{icon}</div>}
      {children}
    </ElementToRender>
  ) : null;
};

export const DropdownHeader = ({ children, className }) => {
  const { isOpen } = useDropdown();
  return isOpen ? (
    <div className={clsxm("p-2 px-4", className)}>{children}</div>
  ) : null;
};

export const DropdownDivider = () => {
  const { isOpen } = useDropdown();
  return isOpen ? <div className="border-b my-2"></div> : null;
};

Dropdown.Item = DropdownItem;
Dropdown.Trigger = DropdownTrigger;
Dropdown.Divider = DropdownDivider;
Dropdown.Header = DropdownHeader;
export default Dropdown;
