import { clsxm } from "@/utils";
import { Link } from "react-router-dom";

export default function DealLink({ deal, className }) {
  return (
    <Link to={`/deals/${deal.id}`}>
      <span
        className={clsxm(
          "text-blue-900 font-semibold decoration-blue-100 underline decoration-2 hover:decoration-blue-300",
          className
        )}
      >
        {deal.pretty_id}
      </span>
    </Link>
  );
}
