import { API_URL } from "@/config/routes";
import UserDetails from "@/features/users/UserDetails";
import { useAuth } from "@/hooks/useAuth";
import { clsxm, fetcher, formatDate } from "@/utils";
import { Metric, Text, Title } from "@tremor/react";
import { useEffect, useState } from "react";
import { MdOutlineWarning } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { Loading } from "../ui/Loading";
import { Modal } from "../ui/Modal";
import { ErrorBoundary } from "./ErrorBoundary";

export function Page({ children, title, allowedRoles, className, fullScreen }) {
  // Simple wrapper component for pages to render the title
  // and a container around the page content. `allowedRoles`
  // is an array of roles that are allowed to view the page.
  const { user, isLoading, mutate } = useAuth();
  const [userIsPending, setUserIsPending] = useState(false);

  // Set the title of the page
  useEffect(() => {
    document.title = `${title || "Genoleggia"}`;
  }, [title]);

  if (allowedRoles) {
    // If user is loading show a loading indicator
    if (isLoading || !user) {
      return (
        <div className="flex flex-col items-center justify-center w-full h-full">
          <Loading />
        </div>
      );
    }
    // If user does not have the required role show a warning
    if (!allowedRoles.includes(user.role)) {
      return <UnauthorizedPage />;
    }
  }

  // Check if the user is missing some data in order to determine whether to show the modal or not
  const userIsMissingData = (user) => {
    if (!user || !user.name || !user.last_name || !user.phone) {
      return true;
    } else {
      return false;
    }
  };

  // Function to activate a user
  const activateUser = async (userId) => {
    try {
      const response = await fetcher(`${API_URL}/users/${userId}/activate`, {
        method: "POST",
      });
      if (response.ok) {
        toast.success("Utente attivato con successo");
        mutate();
      } else {
        toast.error("Errore durante l'attivazione dell'utente");
      }
    } catch (error) {
      toast.error("Errore durante l'attivazione dell'utente");
    }
  };

  useEffect(() => {
    setUserIsPending(user && user.status === "pending");
  }, [user]);

  // If the user is pending and is not missing data, activate it
  useEffect(() => {
    if (user && userIsPending && !userIsMissingData(user)) {
      activateUser(user?.id);
    }
  }, [userIsPending]);

  return (
    <div className={clsxm("flex flex-col h-full", fullScreen && "h-screen")}>
      <main className={clsxm("py-12 px-12", className)}>
        <ErrorBoundary>
          {children}
          {user?.status === "pending" && (
            <Modal
              isOpen={true}
              title="Completa i dati mancanti per proseguire"
              onClose={() => {}} // Prevent closing
            >
              <UserDetails
                userId={user?.id}
                stayEditing={true}
                onSave={() => {
                  fetcher(`${API_URL}/users/${user.id}/activate`, {
                    method: "POST",
                  }).then((res) => {
                    if (res.ok) {
                      toast.success("Utenza attivata con successo!");
                      mutate();
                    } else {
                      toast.error(
                        "Si è verificato un errore. Prova a ricaricare la pagina."
                      );
                    }
                  });
                }}
                className="m-0 border-none rounded-none w-full p-4"
              />
            </Modal>
          )}
        </ErrorBoundary>
      </main>
      <Footer />
    </div>
  );
}

function FooterLink({ href, children }) {
  return (
    <a
      className="text-gray-400 font-medium hover:text-blue-500 decoration-gray-200 underline decoration-offset-2 decoration-2 hover:decoration-blue-400"
      href={href}
    >
      {children}
    </a>
  );
}

export function Footer() {
  return (
    <footer className="flex flex-col md:flex-row w-full py-6 border-t gap-2 items-center justify-center text-gray-400 font-semibold text-xs bg-gray-50 mt-auto">
      <span>©2024 Genoleggia S.p.A.</span>
      <span>·</span>
      <span className="font-normal">
        <FooterLink href="https://www.genoleggia.com">genoleggia.it</FooterLink>
      </span>
      <span>·</span>
      <span className="font-normal">
        v.{" "}
        <FooterLink
          href={`https://www.github.com/965311532/genoleggiapp-frontend/commit/${
            import.meta.env.VITE_COMMIT_HASH_LONG
          }`}
        >
          {import.meta.env.VITE_COMMIT_HASH_LONG.slice(0, 7)}
        </FooterLink>{" "}
        ({formatDate(import.meta.env.VITE_COMMIT_DATE, "short")})
      </span>
      <span>·</span>
      <span className="font-normal">
        <span className="text-green-500 mr-1">⬤</span>
        <FooterLink href="https://status.genoleggia.com">
          Stato sistemi
        </FooterLink>
      </span>
    </footer>
  );
}

export function PageHeader({ breadcrumbs, description, topRight, className }) {
  // If no breadcrumbs are passed, make an empty array
  breadcrumbs = breadcrumbs || [];

  const formatBreadcrumb = (breadcrumb, breadcrumbsLength, breadcrumbIndex) => {
    if (breadcrumbsLength === 1) {
      // If there is only one breadcrumb, use metric (big text)
      return <Metric>{breadcrumb.label}</Metric>;
    } else if (breadcrumbIndex === breadcrumbsLength - 1) {
      // If it's the last breadcrumb, use custom formatting (slighly smaller than metric)
      return (
        <h1 className="text-2xl font-semibold text-slate-800">
          {breadcrumb.label}
        </h1>
      );
    } else {
      // If it's not the last breadcrumb, use title (smaller than metric)
      return <Title>{breadcrumb.label}</Title>;
    }
  };

  return (
    <div
      className={clsxm(
        "flex justify-between items-center mb-4",
        !description && "mb-6",
        className
      )}
    >
      {/* Left side */}
      <div className="flex-col flex pl-1">
        <div className="flex justify-between items-center">
          <div className="flex items-baseline">
            {breadcrumbs.map((breadcrumb, index) => (
              <>
                {index !== 0 && (
                  <span className="mx-2 text-gray-400">&gt;</span>
                )}
                <Link
                  to={breadcrumb.href || breadcrumb.to || breadcrumb.url || "#"}
                >
                  {formatBreadcrumb(breadcrumb, breadcrumbs.length, index)}
                </Link>
              </>
            ))}
          </div>
        </div>
        {description && <Text className="mt-2">{description}</Text>}
      </div>
      {/* Right side */}
      {topRight && (
        <div className="flex-grow flex justify-end items-center">
          {topRight}
        </div>
      )}
    </div>
  );
}

export function UnauthorizedPage() {
  return (
    <div className="flex flex-col items-center justify-center w-full grow h-full">
      <div className="flex flex-col items-center justify-center bg-red-50 p-10 rounded-3xl">
        <MdOutlineWarning className="text-6xl text-red-500" />
        <h1 className="mt-2 text-lg font-semibold text-red-800">
          Non hai i permessi per visualizzare questa pagina
        </h1>
      </div>
    </div>
  );
}
