import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export function clsxm(...args) {
  // Combine clsx and twMerge to merge Tailwind classes conditionally
  return twMerge(clsx(...args));
}

export function disableScroll() {
  // Sometimes we need to disable scrolling on the body element, we could do this
  // with only CSS, but we get layout shift when we do that. This function disables
  // scrolling and adds margin to the body element to prevent layout shift.

  // If the document height is greater than the window height, disable scrolling
  if (document.body.scrollHeight - 1 > window.innerHeight) {
    // Get the width of the scrollbar
    var scrollBarWidth = parseInt(
      window.innerWidth - document.body.clientWidth
    );
    // Set the body to hidden and add margin
    document.body?.setAttribute(
      "style",
      `overflow: hidden; margin-right: ${scrollBarWidth}px;`
    );
  }
}

export function enableScroll() {
  // Re-enable scrolling and remove margin
  document.body?.setAttribute("style", "");
}

function getCookie(name) {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop().split(";").shift();
}

export async function fetcher(url, options = {}) {
  // Add CSRF token to headers
  options.headers = {
    "X-CSRF-TOKEN": getCookie("csrf_access_token"),
    ...options.headers,
  };
  // Add all other options
  options = {
    credentials: "include", // Send cookies
    ...options, // Override any options
  };
  return fetch(url, options);
}

export function formatDate(date, fashion = "long") {
  const fashionMap = {
    long: {
      year: "numeric",
      month: "long",
      day: "numeric",
    },
    short: {
      year: "2-digit",
      month: "short",
      day: "numeric",
    },
    numeric: {
      year: "2-digit",
      month: "numeric",
      day: "numeric",
    },
  };
  // Format date to legible format
  return new Date(date).toLocaleDateString("it-IT", fashionMap[fashion]);
}

export function formatRelativePastDate(date, fashion = "long") {
  // If date is null return "mai"
  if (!date) return "mai";

  // Format date to relative format based on how long ago the date was
  const diff = new Date() - new Date(date[-1] === "Z" ? date : date + "Z"); // Add Z if missing
  const seconds = Math.floor(diff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);

  // Define fashion map
  const fashionMap = {
    long: {
      minutes: (minutes) => `${minutes} minut${minutes === 1 ? "o" : "i"}`,
      hours: (hours) => `${hours} or${hours === 1 ? "a" : "e"}`,
      days: (days) => `${days} giorn${days === 1 ? "o" : "i"}`,
    },
    short: {
      minutes: (minutes) => `${minutes}m`,
      hours: (hours) => `${hours}h`,
      days: (days) => `${days}g${days >= 1 && "g"}`,
    },
  };

  // If the date was less than a minute ago, return "adesso"
  if (seconds < 60) return "adesso";
  // If the date was less than an hour ago, return "x minuti fa"
  if (minutes < 60) return fashionMap[fashion].minutes(minutes) + " fa";
  // If the date was less than a day ago, return "x ore fa"
  if (hours < 24) return fashionMap[fashion].hours(hours) + " fa";
  // Otherwise, return the number of days ago
  return `${fashionMap[fashion].days(days)} fa`;
}

export function objectToQueryString(obj) {
  // Convert an object to a query string
  const searchParams = new URLSearchParams();
  // Delete all undefined values
  Object.keys(obj).forEach((key) => {
    if (obj[key] === undefined) delete obj[key];
  });
  // For each key-value pair
  Object.entries(obj).forEach(([key, values]) => {
    // If a value is an array, append each value
    if (Array.isArray(values)) {
      values.forEach((value) => {
        searchParams.append(key, value);
      });
    }
    // Otherwise, append the value
    else searchParams.append(key, values);
  });
  return searchParams.toString();
}

export function formatNumber(number, digits = 2) {
  // Format a number to a legible format
  return new Intl.NumberFormat("it-IT", {
    minimumFractionDigits: digits,
    maximumFractionDigits: digits,
  }).format(number);
}
