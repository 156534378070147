import { clsxm } from "@/utils";

function Skeleton({ className }) {
  return (
    <div
      className={clsxm("bg-gray-200 animate-pulse rounded-md", className)}
    ></div>
  );
}

export function SkeletonCharts({ className }) {
  return (
    <div className={clsxm("grid grid-cols-2 gap-4", className)}>
      {/* Two big cards */}
      <Skeleton className="h-[450px]" />
      <Skeleton className="h-[450px]" />
    </div>
  );
}

export default function SkeletonDashboard() {
  return (
    <div className="grid grid-cols-1 gap-4">
      {/* Skeleton Tabs pills */}
      <div className="flex gap-4">
        <Skeleton className="w-20 h-6" />
        <Skeleton className="w-20 h-6" />
        <Skeleton className="w-20 h-6" />
        <Skeleton className="w-20 h-6" />
        <Skeleton className="w-20 h-6" />
      </div>
      {/* Three Skeleton Cards */}
      <div className="grid grid-cols-3 gap-4">
        <Skeleton className="h-32" />
        <Skeleton className="h-32" />
        <Skeleton className="h-32" />
      </div>
      {/* Skeleton Charts */}
      <SkeletonCharts />
      <SkeletonCharts />
    </div>
  );
}
