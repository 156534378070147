import Label from "@/components/form/Label";
import { Button } from "@/components/ui/Button";
import { Modal } from "@/components/ui/Modal";
import { formatDate, formatRelativePastDate } from "@/utils";
import { HiPaperClip } from "react-icons/hi2";
import { Link } from "react-router-dom";

const STATUS = {
  HAS_LICENSE: "Veicolo targato (consegna entro 60-90 giorni)",
  AVAILABLE: "Veicolo disponibile (consegna entro 60-90 giorni)",
  HAS_CHASSIS:
    "Veicolo telaiato (immatricolazione e consegna entro 90-120 giorni)",
  default: "Veicolo in arrivo (consegna entro 90-120 giorni dall'ordine)",
};

const formatAvailableFrom = (availableFromDate) => {
  if (!availableFromDate) return "-";
  if (new Date(availableFromDate) <= new Date()) return "Oggi";
  return formatDate(availableFromDate);
};

function VehicleDetail({ label, children }) {
  return (
    <div className="flex flex-col pl-2 border-l-2 border-zinc-300">
      <Label className="underline w-fit text-zinc-500">{label}</Label>
      <span className="text-zinc-900">{children}</span>
    </div>
  );
}

export function FastDeliveryVehicleDetails({ vehicle }) {
  return (
    <div className="flex flex-col gap-4 p-8 pt-6">
      <VehicleDetail label="Colore">{vehicle.color || "-"}</VehicleDetail>

      <VehicleDetail label="Numero veicoli di questo tipo disponibili">
        {vehicle.quantity}
      </VehicleDetail>

      <VehicleDetail label="Ordinabile dal giorno">
        {formatAvailableFrom(vehicle.available_from)}
      </VehicleDetail>

      <VehicleDetail label="Stato veicolo">
        {STATUS[vehicle.status] || STATUS["default"]}
      </VehicleDetail>

      <VehicleDetail label="Noleggiatore">
        {vehicle.renting_company}
      </VehicleDetail>

      <VehicleDetail label="Ultimo aggiornamento">
        {formatRelativePastDate(vehicle.created_at)}
      </VehicleDetail>
    </div>
  );
}

export function FastDeliveryDetailModal({ isOpen, vehicle, onClose }) {
  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      render={false}
      title={
        <span className="pr-10">
          <span className="text-zinc-500 font-light">#{vehicle.id}</span>
          &nbsp;&nbsp;
          {vehicle.full_name}
        </span>
      }
      className="min-w-[40%] w-fit md:max-w-full"
    >
      <FastDeliveryVehicleDetails vehicle={vehicle} />
      <div className="bg-zinc-50 border-t flex justify-end gap-2 p-4 ">
        <Button className="bg-zinc-400 text-white" onClick={onClose}>
          Chiudi
        </Button>
        <Link to={`/deals/new?vehicle_id=${vehicle.id}`}>
          <Button icon={<HiPaperClip />}>Richiedi preventivo</Button>
        </Link>
      </div>
    </Modal>
  );
}
