import Pagination from "@/components/form/Pagination";
import { Searchbar } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { FallbackSpinner } from "@/components/ui/Loading";
import Table from "@/components/ui/Table";
import Tabs from "@/components/ui/Tabs";
import { API_URL } from "@/config/routes";
import { useAuth } from "@/hooks/useAuth";
import {
  clsxm,
  fetcher,
  formatDate,
  formatRelativePastDate,
  objectToQueryString,
} from "@/utils";
import { useEffect, useState } from "react";
import { CgSpinner } from "react-icons/cg";
import { MdAdd, MdFileDownload, MdReadMore } from "react-icons/md";
import { Link, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import useSWR from "swr";
import ClientLink from "../clients/ClientLink";
import UserLink from "../users/UserLink";
import DealStatusBadge from "./DealStatusBadge";
import { useDealsMetadata } from "./useDeal";

async function SWRFetcher({ url, params }) {
  return fetcher(url + "?" + objectToQueryString(params)).then((res) =>
    res.json()
  );
}

function DealRow({ deal }) {
  return (
    <Table.Row>
      <Table.Td>{formatDate(deal.created_at, "short")}</Table.Td>
      <Table.Td>{formatRelativePastDate(deal.updated_at)}</Table.Td>
      <Table.Td className="truncate">
        <UserLink user={deal.author} />
      </Table.Td>
      <Table.Td className="truncate">
        <ClientLink client={deal.client} />
      </Table.Td>
      <Table.Td className="truncate">{deal.vehicle_full_name}</Table.Td>
      <Table.Td>
        <span className="w-full flex justify-center">
          <DealStatusBadge status={deal.status} />
        </span>
      </Table.Td>
      <Table.Td className="pr-6">
        <Link to={`/deals/${deal.id}`}>
          <span className="w-full flex justify-center group">
            <span className="relative">
              <MdReadMore className="text-zinc-300 text-3xl group-hover:text-blue-700 hover:cursor-pointer" />
              {deal.unread_messages_count > 0 && (
                <span className="absolute -top-1 -right-1">
                  <span className="bg-red-500 text-white text-xs rounded-full px-1 font-semibold">
                    {deal.unread_messages_count}
                  </span>
                </span>
              )}
            </span>
          </span>
        </Link>
      </Table.Td>
    </Table.Row>
  );
}

function DealsTableHeader({ filters, onFiltersChange }) {
  return (
    <Table.Head>
      <Table.Row>
        <Table.Th
          sortable
          sortDirection={
            filters.sort_by === "created_at" ? filters.order : undefined
          }
          onClick={() =>
            onFiltersChange({
              sort_by: "created_at",
              order:
                filters.sort_by === "created_at" && filters.order === "desc"
                  ? "asc"
                  : "desc",
            })
          }
        >
          Creato il
        </Table.Th>
        <Table.Th
          sortable
          sortDirection={
            filters.sort_by === "updated_at" ? filters.order : undefined
          }
          onClick={() =>
            onFiltersChange({
              sort_by: "updated_at",
              order:
                filters.sort_by === "updated_at" && filters.order === "desc"
                  ? "asc"
                  : "desc",
            })
          }
        >
          Ultima modifica
        </Table.Th>
        <Table.Th className="w-[20%]">Richiedente</Table.Th>
        <Table.Th className="w-[20%]">Cliente</Table.Th>
        <Table.Th className="w-[20%]">Veicolo</Table.Th>
        <Table.Th className="w-[10%] text-center">Stato</Table.Th>
        <Table.Th className="w-[10%] text-center pr-6">Dettagli</Table.Th>
      </Table.Row>
    </Table.Head>
  );
}

function DealsTable({ deals, filters, onFiltersChange }) {
  return (
    <Table className="w-full table-fixed">
      <DealsTableHeader filters={filters} onFiltersChange={onFiltersChange} />
      <Table.Body>
        {!deals &&
          Array.from({ length: 20 }).map((_, i) => (
            <Table.SkeletonRow key={i} colsNumber={7}></Table.SkeletonRow>
          ))}
        {deals && deals.map((deal) => <DealRow key={deal.id} deal={deal} />)}
      </Table.Body>
    </Table>
  );
}

function DealsTabs({ filters, onFilterChange }) {
  const { metadata } = useDealsMetadata();

  return (
    <ul className="flex flex-row justify-between items-center bg-zinc-50 rounded-md">
      {Object.entries({
        all: "Tutte",
        unread: "Con nuovi messaggi",
        active: "Attive",
        "Al credito": "Al credito",
        Conclusa: "Concluse",
        "Non conclusa": "Non concluse",
      }).map(([status, label]) => (
        <Tabs.Component
          key={status}
          active={filters.status === status}
          onClick={() => onFilterChange({ status: status })}
          className="rounded-md"
        >
          {label}
          <span className="ml-1 font-medium brightness-150">
            (
            <FallbackSpinner
              isLoading={metadata === undefined}
              className="inline"
            >
              {metadata?.[status]}
            </FallbackSpinner>
            )
          </span>
        </Tabs.Component>
      ))}
    </ul>
  );
}

function DealsSearchbar({ onSubmit, className }) {
  const [searchTerm, setSearchTerm] = useState();

  const handleSubmit = (e) => {
    e.preventDefault();
    onSubmit(searchTerm);
  };

  return (
    <form
      className={clsxm("w-full flex gap-2", className)}
      onSubmit={handleSubmit}
    >
      <Searchbar
        id="search-deals"
        value={searchTerm}
        onChange={(e) =>
          setSearchTerm(e.target.value !== "" ? e.target.value : undefined)
        }
        placeholder="Cerca per veicolo, cliente o richiedente"
        className="flex-grow"
      />
      <Searchbar.Button />
    </form>
  );
}

const DEFAULT_QUERY_PARAMS = {
  page: 1,
  per_page: 20,
  sort_by: "updated_at",
  order: "desc",
  q: undefined,
  status: "active",
};

function DealsDataTableButtons() {
  const { user } = useAuth();
  const [isDownloadingDeals, setIsDownloadingDeals] = useState(false);

  const downloadDeals = () => {
    setIsDownloadingDeals(true);
    fetcher(`${API_URL}/deals/export`, {
      method: "GET",
    })
      .then((response) => response.blob())
      .then((blob) => {
        // Create a new object URL for the blob
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        // the filename you want
        a.download = "trattative.xlsx";
        document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setIsDownloadingDeals(false);
      })
      .catch(() => toast.error("Errore durante il download delle trattative"));
  };

  return (
    <div className="flex flex-row gap-2">
      {/* The button to download the deals if the user has the permission */}
      {user.role === "admin" && (
        <Button
          icon={
            isDownloadingDeals ? (
              <CgSpinner className="text-lg animate-spin" />
            ) : (
              <MdFileDownload className="text-lg" />
            )
          }
          onClick={downloadDeals}
          className="py-4 bg-transparent border border-gray-300 hover:bg-gray-100 text-zinc-800"
        >
          Scarica trattative
        </Button>
      )}

      {/* The button to create a new deal */}
      <Link to="/deals/new">
        <Button className="py-4" icon={<MdAdd className="text-xl" />}>
          Nuova trattativa
        </Button>
      </Link>
    </div>
  );
}

export default function DealsDataTable() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [queryParams, setQueryParams] = useState({
    ...DEFAULT_QUERY_PARAMS,
    ...Object.fromEntries(searchParams),
  });
  const data = useSWR(
    { url: `${API_URL}/deals`, params: queryParams },
    SWRFetcher
  );

  const handleParamsChange = (params) => {
    // Whenever the params change, we want to reset the page to 1
    setQueryParams({ ...queryParams, ...params, page: 1 });
  };

  useEffect(() => {
    // Whenever the query params change, we want to update the search params
    const filteredEntries = Object.entries(queryParams).filter(
      ([, value]) => value !== undefined
    );
    setSearchParams(filteredEntries);
  }, [queryParams]);

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-row justify-between">
        {/* The search bar for the deals */}
        <DealsSearchbar
          className="w-[50%]"
          onSubmit={(searchTerm) => handleParamsChange({ q: searchTerm })}
        />
      </div>
      {/* The filters for the deals */}
      <div className="flex flex-row justify-between">
        <DealsTabs
          filters={queryParams}
          onFilterChange={(filters) => handleParamsChange({ ...filters })}
        />
        <div className="flex justify-end">
          <Pagination
            onPageChange={(page) => setQueryParams({ ...queryParams, page })}
            currentPage={queryParams.page}
            totalPages={data.data?.pages}
          />
        </div>
      </div>
      <DealsTable
        deals={data.data?.items}
        filters={queryParams}
        onFiltersChange={(filters) => handleParamsChange({ ...filters })}
      />
      <div className="flex justify-end">
        <Pagination
          onPageChange={(page) => setQueryParams({ ...queryParams, page })}
          currentPage={queryParams.page}
          totalPages={data.data?.pages}
        />
      </div>
    </div>
  );
}

DealsDataTable.Buttons = DealsDataTableButtons;
