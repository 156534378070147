import { clsxm, formatNumber } from "@/utils";
import { useField } from "formik";
import Label from "../Label";

function isNumber(n) {
  return !isNaN(parseFloat(n)) && !isNaN(n - 0);
}

export function Input({ label, readOnly, suffix, className, error, ...props }) {
  if (readOnly) {
    var value = props?.value;

    // Special case to format numbers correctly
    if (props?.type === "number" && isNumber(value)) {
      const step = props?.step || 0.01; // Get the step from the input props
      const digits = (step + "").split(".")[1]?.length || 0;
      console.log(digits);
      value = formatNumber(value, digits);
    }

    return (
      <>
        <span className="flex gap-1">
          {value || "-"}
          {value && suffix && (
            <span className="text-gray-500 text-sm">{suffix}</span>
          )}
        </span>
        <input type="hidden" name={props?.name} value={value} />
      </>
    );
  }
  return (
    <div className={clsxm("flex flex-col w-full", className)}>
      {label && <Label>{label}</Label>}
      <div className="relative h-full w-full">
        <input
          className={clsxm(
            "w-full leading-tight text-zinc-900 bg-transparent appearance-none focus:outline-1 focus:outline-blue-100 rounded-lg p-2 bg-white border h-full hide-number-spin hover:border-gray-300",
            error && "border-red-500",
            props?.isDisabled &&
              "bg-gray-100 hover:cursor-not-allowed pointer-events-none"
          )}
          {...props}
        />
        {suffix && (
          <span
            className="absolute right-3 pr-2 text-sm text-gray-400"
            style={{ top: "50%", transform: "translateY(-50%)" }}
          >
            {suffix}
          </span>
        )}
        {props?.required && (
          <span
            className="absolute -top-2 -right-2.5 flex items-center pr-2 text-lg select-none text-gray-400"
            title="Campo obbligatorio"
          >
            *
          </span>
        )}
      </div>

      {error && (
        <div className="mt-1 text-red-600 text-sm font-medium">{error}</div>
      )}
    </div>
  );
}

export function FormikInput({ name, ...props }) {
  // Access formik's props
  const [field, meta] = useField(name);

  return (
    <Input
      {...field}
      {...props}
      error={meta.touched && meta.error ? meta.error : null}
    />
  );
}
