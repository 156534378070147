import RichTextEditor from "@/components/form/RichTextEditor";
import { Checkbox, Input } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { useState } from "react";
import { BsSave2Fill, BsTelegram } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import useAnnouncements from "./useAnnouncements";

const FORM_INITIAL_STATE = {
  title: "",
  content: "",
  sendEmail: false,
};

export default function AnnouncementForm({ onClose }) {
  const [content, setContent] = useState(FORM_INITIAL_STATE.content);
  const [title, setTitle] = useState(FORM_INITIAL_STATE.title);
  const [sendEmail, setSendEmail] = useState(FORM_INITIAL_STATE.sendEmail);
  const [isSaving, setIsSaving] = useState(false);
  const { createAnnouncement } = useAnnouncements();

  const resetForm = () => {
    setContent(FORM_INITIAL_STATE.content);
    setTitle(FORM_INITIAL_STATE.title);
    setSendEmail(FORM_INITIAL_STATE.sendEmail);
  };

  const handleSave = () => {
    setIsSaving(true);
    createAnnouncement({ title, content, sendEmail }).then(() => {
      resetForm();
      onClose();
      setIsSaving(false);
    });
  };

  return (
    <div className="flex flex-col">
      <Input
        placeholder="Titolo della comunicazione"
        value={title}
        required
        className={"text-2xl font-medium p-3"}
        onChange={(e) => setTitle(e.target.value)}
      />
      <div className="p-3 pt-0">
        <RichTextEditor content={content} setContent={setContent} />
      </div>
      <div className="flex flex-row justify-between gap-3 p-3 pl-5 border-t px-4">
        <Checkbox
          label="Invia comunicazione via email a tutti gli utenti"
          checked={sendEmail}
          onChange={(e) => setSendEmail(e.target.checked)}
        />
        <div className="flex flex-row gap-3">
          <Button
            className="bg-gray-400 hover:bg-gray-500 text-white "
            onClick={resetForm}
          >
            Reset
          </Button>
          <Button
            onClick={handleSave}
            disabled={!title || !content || isSaving}
            icon={
              isSaving ? (
                <CgSpinner className="animate-spin" />
              ) : sendEmail ? (
                <BsTelegram />
              ) : (
                <BsSave2Fill />
              )
            }
          >
            {sendEmail ? "Salva e invia comunicazione" : "Salva comunicazione"}
          </Button>
        </div>
      </div>
    </div>
  );
}
