import { clsxm } from "@/utils";
import { Tooltip } from "./Tooltip";

export function Badge({ label, className, icon, tooltip }) {
  return (
    <Tooltip content={tooltip}>
      <span
        className={clsxm(
          "px-2 py-1 font-semibold text-xl leading-tight rounded-full flex flex-row items-center ring-2 ring-offset-2",
          className
        )}
      >
        {icon && <span>{icon}</span>}
        {label && <span className="ml-1">{label}</span>}
      </span>
    </Tooltip>
  );
}
