import { clsxm, disableScroll, enableScroll } from "../../utils";

function ModalHeader({ title, onClose }) {
  return (
    <div className="w-full flex items-center py-4 px-6 justify-center border-b border-gray-200">
      <h1 className="text-2xl font-semibold mt-1 text-zinc-700">{title}</h1>
      <button
        className="ml-auto p-2 rounded-full hover:bg-gray-200 mt-1"
        onClick={onClose}
      >
        <svg
          className="w-6 h-6 text-gray-600"
          fill="none"
          stroke="currentColor"
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>
    </div>
  );
}

function ModalContent({ children, className }) {
  return (
    <div
      className={clsxm(
        "bg-white rounded m-2 w-full md:max-w-5xl h-auto flex flex-col shadow-md",
        className
      )}
    >
      {children}
    </div>
  );
}

function ModalOverlay({ isOpen, render, children }) {
  // Disable scrolling when the modal is open
  // isOpen ? disableScroll() : enableScroll();
  // If the modal is closed and render is falsy, don't render anything
  if (!isOpen && !render) return null;

  return (
    <div
      className={clsxm(
        "fixed top-0 left-0 w-full h-full bg-black bg-opacity-20 flex items-center justify-center z-50",
        isOpen ? "fixed" : "hidden" // Hide the modal if it's closed
      )}
    >
      {children}
    </div>
  );
}

export function Modal({ title, children, isOpen, render, onClose, className }) {
  return (
    // The overlay is always the same, so we pass className to the content
    <ModalOverlay isOpen={isOpen} render={render}>
      <ModalContent className={className}>
        <ModalHeader title={title} onClose={onClose} />
        {children}
      </ModalContent>
    </ModalOverlay>
  );
}
