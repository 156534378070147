import Dropzone from "@/components/form/Dropzone";
import { Button } from "@/components/ui/Button";
import { Card2 } from "@/components/ui/Card";
import FileChip from "@/components/ui/FileChip";
import { LoadingDots } from "@/components/ui/Loading";
import { Modal } from "@/components/ui/Modal";
import { API_URL } from "@/config/routes";
import { useAuth } from "@/hooks/useAuth";
import { clsxm, fetcher } from "@/utils";
import { useEffect, useState } from "react";
import { BsTrash } from "react-icons/bs";
import { CgSpinner, CgTrash } from "react-icons/cg";
import { FiInbox } from "react-icons/fi";
import {
  RiAttachment2,
  RiChatSmile3Line,
  RiCloseLine,
  RiSendPlane2Line,
} from "react-icons/ri";
import { useParams } from "react-router-dom";
import UserLink from "../users/UserLink";
import { useDealChat } from "./DealChatProvider";
import useDeal from "./useDeal";

const generateColorSchemaFromMessages = (messages) => {
  // Get all the senders and assign them a color
  const uniqueSendersIds = [...new Set(messages.map((m) => m.sender.id))];
  const colorCombinations = [
    { bg: "bg-blue-400", border: "border-blue-400" },
    { bg: "bg-teal-400", border: "border-teal-400" },
    { bg: "bg-green-400", border: "border-green-400" },
    { bg: "bg-yellow-400", border: "border-yellow-400" },
    { bg: "bg-red-400", border: "border-red-400" },
    { bg: "bg-indigo-400", border: "border-indigo-400" },
    { bg: "bg-purple-400", border: "border-purple-400" },
  ];
  const colorSchema = {};
  uniqueSendersIds.forEach((id, i) => {
    colorSchema[id] = colorCombinations[i % colorCombinations.length];
  });
  return colorSchema;
};

function DeleteMessageModal({ isOpen, onClose, message }) {
  const { deleteMessage } = useDealChat();
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDeleteMessage = () => {
    // If the message is being deleted, do nothing
    if (isDeleting) return;
    setIsDeleting(true);
    deleteMessage(message.id).then(() => {
      setIsDeleting(false);
      // Close the modal
      onClose();
    });
  };

  return (
    <Modal
      title="Cancella messaggio"
      isOpen={isOpen}
      onClose={onClose}
      className="md:max-w-2xl"
    >
      <span className="p-5">
        Sei sicuro di voler eliminare questo messaggio?
      </span>
      <div className="w-full border-t p-4">
        <button
          disabled={isDeleting}
          className={clsxm(
            "py-3 px-6 bg-red-600 rounded-lg text-white font-semibold hover:bg-red-700 ml-auto flex items-center",
            isDeleting && "bg-red-400 hover:bg-red-400"
          )}
          type="button"
          onClick={handleDeleteMessage}
        >
          <div className="inline-block mr-1 text-xl">
            {isDeleting && <LoadingDots centered />}
            {!isDeleting && <CgTrash />}
          </div>
          <span className="mr-2">Elimina</span>
        </button>
      </div>
    </Modal>
  );
}

function FileUploadModal({ isOpen, onClose }) {
  const [isUploading, setIsUploading] = useState(false);
  const [files, setFiles] = useState([]);
  const { sendMessage } = useDealChat();

  const handleUploadFile = (e) => {
    e.preventDefault();
    // If the file is being uploaded, do nothing
    if (isUploading) return;
    setIsUploading(true);
    const formData = new FormData();
    // Append all the files to the form data
    files.forEach((file) => {
      formData.append("file", file);
    });
    fetcher(`${API_URL}/media/`, {
      method: "POST",
      body: formData,
    })
      .then((response) => {
        response.json().then((data) => {
          // We want to send a message with the
          // attachment ID
          for (const file of data.files) {
            sendMessage({ text: "", attachment_id: file.id });
          }
        });
      })
      .finally(() => {
        // Reset the files
        setFiles([]);
        // Reset the loading state
        setIsUploading(false);
        // Close the modal
        onClose();
      });
  };

  return (
    <Modal title="Carica file" isOpen={isOpen} onClose={onClose}>
      <form onSubmit={handleUploadFile}>
        <div className="flex flex-col p-5">
          <Dropzone files={files} setFiles={setFiles} />
        </div>
        <div className="flex w-full gap-2 border-t p-4 justify-end">
          <Button
            type="button"
            className="bg-gray-200 text-gray-700 hover:bg-gray-300"
            onClick={() => setFiles([])}
          >
            Rimuovi tutti i file
          </Button>
          <Button
            type="submit"
            icon={<RiSendPlane2Line />}
            disabled={isUploading || files.length === 0}
          >
            Conferma e invia
          </Button>
        </div>
      </form>
    </Modal>
  );
}

function Message({ message, previousMessage, colorSchema }) {
  const { user: currentUser } = useAuth();
  const [showDeleteMessageModal, setShowDeleteMessageModal] = useState(false);
  const isUserMessage = message.sender.id === currentUser.id;
  const isSameUserAsPreviousMessage =
    previousMessage?.sender.id === message.sender.id;
  const messageTime = new Date(message.created_at + "Z").toLocaleTimeString(
    "it-IT",
    {
      hour: "numeric",
      minute: "2-digit",
      timeZone: "Europe/Rome",
    }
  );
  const messageDateTime = new Date(message.created_at + "Z").toLocaleString(
    "it-IT",
    {
      timeZone: "Europe/Rome",
    }
  );

  return (
    <>
      <DeleteMessageModal
        isOpen={showDeleteMessageModal}
        onClose={() => setShowDeleteMessageModal(false)}
        message={message}
      />

      <div className="py-1 group text-sm">
        {!isSameUserAsPreviousMessage && (
          <div
            className={clsxm(
              "flex flex-row-reverse text-sm py-2 px-1",
              !isUserMessage && "flex-row"
            )}
          >
            <UserLink
              user={message.sender}
              className={clsxm(
                "p-1 px-2 text-white rounded-full no-underline hover:underline hover:decoration-white",
                colorSchema[message.sender.id].bg
              )}
            />
          </div>
        )}
        <div
          className={clsxm(
            "relative px-5 py-4 border border-y-zinc-200 shadow-inner bg-white w-full",
            colorSchema[message.sender.id].border,
            isUserMessage
              ? "border-r-4 ml-auto rounded-br-none border-l-zinc-200"
              : "rounded-bl-none border-l-4 border-r-zinc-200"
          )}
        >
          {message.attachment && (
            <FileChip
              name={message.attachment.filename}
              id={message.attachment.id}
            />
          )}
          {message.text}
          {/* Message time */}
          <span className="text-[0.65rem] font-semibold text-white absolute bottom-0 right-0 px-1 bg-zinc-300">
            <span className="group-hover:hidden">{messageTime}</span>
            <span className="hidden group-hover:inline-block">
              {messageDateTime}
            </span>
          </span>
          {/* Delete message button */}
          {currentUser.role == "admin" &&
            currentUser.id == message.sender.id && (
              <span
                className="hidden group-hover:inline-block font-semibold text-white absolute top-0 right-0 p-1 bg-zinc-300 cursor-pointer hover:bg-red-600"
                onClick={() => setShowDeleteMessageModal(true)}
              >
                <BsTrash />
              </span>
            )}
        </div>
      </div>
    </>
  );
}

function ChatInputBar() {
  const [text, setText] = useState("");
  const { sendMessage, textareaRef, isSending } = useDealChat();
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);

  const handleSendMessage = () => {
    // If the message is empty, do nothing
    if (!text) return;
    sendMessage({ text, attachment_id: null }).then(() => setText(""));
  };

  return (
    <>
      <FileUploadModal
        isOpen={showUploadFileModal}
        onClose={() => setShowUploadFileModal(false)}
      />
      <div className="px-12 py-4">
        <div className="flex flex-row w-full bg-slate-200 shadow-sm border rounded-xl">
          <button
            type="button"
            className="w-16 text-xl px-4 flex items-center justify-center"
            onClick={() => setShowUploadFileModal(true)}
          >
            <RiAttachment2 />
          </button>
          <textarea
            ref={textareaRef}
            autoFocus
            value={text}
            onChange={(e) => setText(e.target.value)}
            name="message"
            disabled={isSending}
            className="w-full h-full p-4 min-h-[4rem]"
            placeholder="Scrivi un messaggio..."
            rows={2}
            onKeyDown={(e) => {
              // If both CTRL and ENTER are pressed, send the message
              if (e.ctrlKey && e.key === "Enter") {
                handleSendMessage();
              }
            }}
          ></textarea>
          <button
            type="button"
            onClick={handleSendMessage}
            className="w-16 text-xl px-4 flex items-center justify-center"
          >
            {!isSending && <RiSendPlane2Line />}
            {isSending && <CgSpinner className="animate-spin" />}
          </button>
        </div>
      </div>
    </>
  );
}

function SkeletonMessage() {
  // Render a couple of skeleton messages while the chat is loading
  return (
    <div className="mt-1 relative px-5 py-4 border border-y-zinc-200 shadow-inner bg-white w-full rounded-bl-none border-l-4 border-r-zinc-200 animate-pulse">
      <div className="w-1/2 h-4 bg-gray-200 rounded"></div>
      <div className="w-1/3 h-4 bg-gray-200 rounded mt-2"></div>
      <div className="w-1/4 h-4 bg-gray-200 rounded mt-2"></div>
    </div>
  );
}

export default function DealChat() {
  const { messages, isLoading, endOfMessagesRef, scrollToLatestMessage } =
    useDealChat();

  useEffect(() => {
    // Scroll to the bottom of the chat on first load
    if (messages) scrollToLatestMessage();
  }, [messages]);

  // Generate a color schema for the users
  const colorSchema = isLoading
    ? null
    : generateColorSchemaFromMessages(messages);

  return (
    <div className="flex flex-col h-full overflow-hidden">
      <div className="overflow-y-auto overflow-x-hidden scrollbar-thin scrollbar-thumb-gray-200 scrollbar-track-gray-100 pl-12 pr-10 whitespace-pre-line break-words flex-grow">
        {isLoading ? (
          <>
            <SkeletonMessage />
            <SkeletonMessage />
          </>
        ) : !messages.length ? (
          <div className="flex flex-col items-center justify-center h-full">
            <FiInbox className="text-gray-400 text-3xl" />
            <p className="text-gray-400 mt-1">Non ci sono ancora messaggi</p>
          </div>
        ) : (
          <>
            {messages.map((message, i) => (
              <Message
                key={i}
                message={message}
                colorSchema={colorSchema}
                previousMessage={messages[i - 1] || null}
              />
            ))}
            <div ref={endOfMessagesRef} />
          </>
        )}
      </div>
      <ChatInputBar />
    </div>
  );
}

export function DealChatFloatingButton({ className }) {
  const [open, setOpen] = useState(false);
  const { id } = useParams();
  const { deal } = useDeal(id);

  return (
    <>
      <button
        type="button"
        onClick={() => setOpen(!open)}
        className={clsxm("fixed bottom-4 right-4 z-50", className)}
      >
        <span className="flex relative gap-2 items-center text-2xl font-semibold shadow-lg bg-blue-500 text-white rounded-full py-3 px-6 pr-8">
          {deal?.unread_messages_count > 0 && (
            <span className="absolute top-0 left-0 -mt-3 -mr-4 bg-red-500 text-white rounded-full px-2 text-lg">
              {deal.unread_messages_count}
            </span>
          )}

          {!open ? (
            <span className="flex items-center gap-2">
              <RiChatSmile3Line className="text-4xl" />
              Apri chat
            </span>
          ) : (
            <span className="flex items-center gap-2">
              <RiCloseLine className="text-4xl" />
              Chiudi chat
            </span>
          )}
        </span>
      </button>
      {open && (
        <div className="fixed inset-0 h-screen overflow-hidden bg-black bg-opacity-20 z-30">
          <Card2 className="fixed bottom-16 right-5 w-[60%] z-40 h-[85%] px-0 py-4 shadow-lg bg-zinc-50">
            <DealChat />
          </Card2>
        </div>
      )}
    </>
  );
}
