import { SpeedInsights } from "@vercel/speed-insights/react";
import { React } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import { SWRConfig } from "swr";
import { SidebarLayout } from "./components/layout/Sidebar";
import { LoadingDots } from "./components/ui/Loading";
import { accessLogsRoutes } from "./features/accessLogs";
import { announcementsRoutes } from "./features/announcements";
import { authRoutes } from "./features/auth";
import { bookmarksRoutes } from "./features/bookmarks";
import { clientRoutes } from "./features/clients";
import { contractRoutes } from "./features/contracts";
import { dashboardRoutes } from "./features/dashboard";
import { dealsRoutes } from "./features/deals";
import { fastDeliveryRoutes } from "./features/fastDelivery";
import { offerRoutes } from "./features/offers";
import { tasksRoutes } from "./features/tasks";
import { userRoutes } from "./features/users";
import { useAuth } from "./hooks/useAuth";
import { fetcher } from "./utils";

function CustomSWRConfig({ children }) {
  return (
    <SWRConfig
      // This makes sure that the custom fetcher is used for
      // all SWR calls that don't specify a fetcher.
      value={{
        fetcher: (...args) =>
          fetcher(...args).then((res) => {
            if (!res.ok) throw res;
            return res.json();
          }),
      }}
    >
      {children}
    </SWRConfig>
  );
}

function PrivateWrapper() {
  const { isUserAuthenticated, isLoading } = useAuth();
  // If the auth state is loading, display an empty page.
  if (isLoading)
    return (
      <div className="w-full h-screen flex items-center justify-center bg-gray-100">
        <LoadingDots className="text-3xl text-zinc-600" />
      </div>
    );
  else if (isUserAuthenticated) return <Outlet />;
  else {
    return <Navigate to="/login" replace />;
  }
}

export default function App() {
  return (
    <CustomSWRConfig>
      <SpeedInsights />
      {/* Private routes */}
      <Routes>
        <Route element={<PrivateWrapper />}>
          <Route element={<SidebarLayout />}>
            {dashboardRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            {dashboardRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            {clientRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            {userRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            {dealsRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            {bookmarksRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            {announcementsRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            {tasksRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            {offerRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            {fastDeliveryRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            {accessLogsRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
            {contractRoutes.map((route) => (
              <Route key={route.path} {...route} />
            ))}
          </Route>
        </Route>
        {/* Public routes */}
        {authRoutes.map((route) => (
          <Route key={route.path} {...route} />
        ))}
        {/* Redirect everything to this */}
        <Route path="*" element={<Navigate to="/deals" replace />} />
      </Routes>
    </CustomSWRConfig>
  );
}
