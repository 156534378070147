import { createContext, useContext, useState } from "react";
import { clsxm } from "../../utils.js";

const TabsContext = createContext();

function Tabs({ children, className }) {
  const [activeTab, setActiveTab] = useState(children[0].props.title);

  return (
    <TabsContext.Provider value={{ activeTab, setActiveTab }}>
      <div>
        <ul
          className={clsxm(
            "flex flex-wrap -mb-px font-medium text-gray-500 text-center",
            className
          )}
        >
          {children.map((child, index) => (
            <TabsItem
              key={index}
              title={child.props.title}
              active={child.props.title === activeTab}
              icon={child.props.icon}
            />
          ))}
        </ul>
        <div className="p-4">
          {children.map((child) => {
            if (child.props.title === activeTab) return child.props.children;
          })}
        </div>
      </div>
    </TabsContext.Provider>
  );
}

function TabsComponent({ active, disabled, children, onClick, className }) {
  return (
    <li
      className={clsxm(
        "w-auto flex-grow",
        "cursor-pointer",
        "outline-none",
        "hover:bg-zinc-100",
        "text-gray-600",
        active && "text-white bg-blue-600 hover:bg-blue-700",
        disabled && "opacity-50 cursor-not-allowed",

        className
      )}
    >
      <button
        onClick={onClick}
        className={clsxm(
          "w-full",
          "py-2",
          "px-1 md:px-4",
          "text-xs md:text-sm",
          "font-medium",
          "leading-5",
          "focus:outline-none",
          "focus:shadow-outline"
        )}
        disabled={disabled}
      >
        {children}
      </button>
    </li>
  );
}

function TabsItem({ title, active, icon, disabled }) {
  const { setActiveTab } = useContext(TabsContext);

  return (
    <TabsComponent
      active={active}
      disabled={disabled}
      onClick={() => setActiveTab(title)}
    >
      <span className="flex items-center justify-center">
        {icon && <span className="mr-2">{icon}</span>}
        {title}
      </span>
    </TabsComponent>
  );
}

Tabs.Group = Tabs;
Tabs.Item = TabsItem;
Tabs.Component = TabsComponent;

export default Tabs;
