import { clsxm } from "@/utils";

export default function Logo({ className }) {
  return (
    <div className={clsxm("inline-block", className)}>
      <svg
        viewBox="0 0 258 36"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M221.497 24.771C218.829 24.771 216.214 24.807 213.603 24.7584C211.94 24.7279 210.254 24.7063 208.625 24.4331C206.47 24.0719 205.398 22.9989 205.258 20.9915C205.127 19.1098 205.22 17.2011 205.408 15.3195C205.58 13.5959 206.749 12.5032 208.604 12.2013C209.831 12.0018 211.089 11.8886 212.334 11.8814C215.14 11.8652 217.957 11.8311 220.754 12.0054C224.199 12.2193 225.72 13.7397 225.808 16.9621C225.895 20.1935 225.883 23.4285 225.81 26.6599C225.751 29.1832 224.36 30.5383 221.553 30.6946C219.06 30.833 216.55 30.7234 213.871 30.7234C213.871 29.8392 213.815 28.9711 213.91 28.1174C213.933 27.9036 214.437 27.5854 214.73 27.5747C216.209 27.5189 217.689 27.5495 219.17 27.5459C221.452 27.5441 221.892 27.0553 221.497 24.771ZM216.15 21.678C216.15 21.6816 216.15 21.6834 216.15 21.687C217.703 21.687 219.256 21.6762 220.808 21.6924C221.361 21.6978 221.659 21.545 221.646 20.9789C221.613 19.6831 221.665 18.3837 221.582 17.0897C221.51 15.97 220.931 15.3752 219.727 15.3464C216.891 15.2781 214.049 15.2925 211.211 15.3338C210.093 15.35 209.498 16.0114 209.432 17.0268C209.376 17.9003 209.354 18.7791 209.376 19.6525C209.411 21.1191 209.905 21.6133 211.494 21.6654C213.043 21.7193 214.598 21.678 216.15 21.678Z" />
        <path d="M190.205 30.6928C190.205 29.6307 190.205 28.6602 190.205 27.5495C192.004 27.5495 193.738 27.5531 195.473 27.5495C197.739 27.5441 198.152 27.0714 197.759 24.7728C197.344 24.7728 196.904 24.7764 196.462 24.7728C193.046 24.7386 189.625 24.7926 186.215 24.6416C182.901 24.4942 181.501 23.0708 181.464 20.021C181.445 18.4448 181.494 16.8561 181.687 15.2925C181.895 13.6229 183.002 12.5625 184.8 12.2103C185.578 12.0593 186.376 11.9191 187.165 11.9191C190.503 11.9155 193.849 11.8598 197.179 12.0288C200.315 12.1887 201.841 13.5546 201.996 16.4787C202.184 19.9832 202.155 23.5076 202.025 27.0157C201.942 29.273 200.651 30.4826 198.125 30.6551C195.53 30.8312 192.907 30.6928 190.205 30.6928ZM197.86 21.6528C197.86 20.003 197.908 18.4664 197.842 16.9333C197.799 15.9521 197.102 15.3662 196.066 15.35C193.19 15.3033 190.313 15.3015 187.437 15.3446C186.372 15.3608 185.779 16.0006 185.725 16.9765C185.671 17.9542 185.648 18.9372 185.698 19.9149C185.76 21.137 186.163 21.6043 187.493 21.6313C190.893 21.6978 194.297 21.6528 197.86 21.6528Z" />
        <path d="M66.2142 11.5848C64.4968 11.5848 62.9848 11.5741 61.4709 11.5866C58.5904 11.6136 57.8829 12.2606 57.8713 14.8845C57.8635 16.7123 57.8461 18.5401 57.8965 20.3678C57.9469 22.2028 58.6273 22.9001 60.5695 22.9414C63.5295 23.0061 66.4933 23.0043 69.4533 22.9378C71.2638 22.8965 71.9887 22.1848 72.0605 20.5044C72.1264 18.93 72.074 17.3503 72.074 15.6843C73.553 15.6843 74.9351 15.6843 76.5111 15.6843C76.3909 18.2723 76.8658 20.9106 75.9625 23.468C75.2414 25.5079 73.3437 26.2142 71.3045 26.2735C67.0962 26.3975 62.8743 26.4388 58.6699 26.2681C55.3378 26.1333 53.7211 24.4619 53.6107 21.3832C53.5137 18.6461 53.5157 15.9 53.6107 13.1628C53.7153 10.1255 55.299 8.53856 58.5536 8.26898C60.8177 8.08207 63.105 8.1234 65.3807 8.1234C65.6579 8.1234 66.1522 8.47925 66.1755 8.70211C66.2685 9.60431 66.2142 10.5173 66.2142 11.5848Z" />
        <path d="M29.4328 0H9.39548C4.22768 0 0 3.91972 0 8.7111V27.2889C0 32.0803 4.22768 36 9.39548 36H29.4328C34.6007 36 38.8283 32.0803 38.8283 27.2889V8.7111C38.8283 3.91972 34.6007 0 29.4328 0ZM32.7243 25.5708C31.8462 28.0563 29.5356 28.9154 27.0505 28.9891C21.9234 29.14 16.7808 29.1904 11.6595 28.9819C7.60051 28.8165 5.63108 26.7821 5.49733 23.0331C5.37909 19.6975 5.38103 16.3547 5.49733 13.019C5.62527 9.32035 7.55399 7.38655 11.518 7.05766C14.2745 6.82941 17.0619 6.88153 19.8338 6.87974C20.1711 6.87974 20.774 7.31286 20.8011 7.58604C20.9135 8.68234 20.8476 9.79302 20.8476 11.0942C18.7561 11.0942 16.9126 11.0798 15.0712 11.0978C11.5626 11.1301 10.7 11.9191 10.6865 15.1164C10.6768 17.3431 10.6555 19.5699 10.7175 21.7948C10.7795 24.0306 11.6091 24.8788 13.974 24.931C17.5795 25.0082 21.1907 25.0082 24.7962 24.9256C27.0001 24.8752 27.886 24.009 27.9713 21.962C28.0527 20.0425 27.9887 18.1195 27.9887 16.0905C29.7895 16.0905 31.4721 16.0905 33.393 16.0905C33.2496 19.2428 33.8253 22.458 32.7243 25.5708Z" />
        <path d="M130.767 26.1009C128.216 25.7074 126.937 24.3181 126.768 21.9098C126.642 20.1288 126.638 18.328 126.757 16.547C126.927 13.9698 128.228 12.3846 130.951 12.2031C134.828 11.9425 138.755 11.9479 142.634 12.1977C145.294 12.3684 146.517 13.7235 146.806 16.2037C147.021 18.0422 147.063 19.9311 146.879 21.7715C146.579 24.7512 144.96 26.1009 141.671 26.3094C140.043 26.4101 132.737 26.4047 130.767 26.1009ZM136.79 23.0115C136.79 23.0097 136.79 23.0061 136.79 23.0043C137.966 23.0043 139.147 23.0654 140.318 22.99C141.855 22.8893 142.553 22.2693 142.657 20.8872C142.745 19.7334 142.745 18.5652 142.65 17.4114C142.541 16.0977 141.909 15.4524 140.484 15.3985C138.021 15.3069 135.549 15.3105 133.086 15.4021C131.733 15.4525 131.116 16.0941 131.006 17.3629C130.907 18.5131 130.893 19.6813 130.984 20.8315C131.103 22.3034 131.775 22.8947 133.372 22.9954C134.508 23.069 135.65 23.0115 136.79 23.0115Z" />
        <path d="M178.495 20.6248C177.049 20.6248 175.767 20.6248 174.484 20.6248C170.879 20.6248 167.273 20.6087 163.668 20.6446C163.199 20.65 162.383 20.2618 162.319 21.1352C162.261 21.9314 162.235 22.839 163.263 23.1912C163.815 23.38 164.449 23.4123 165.048 23.4231C166.638 23.4536 168.229 23.4339 169.923 23.4339C169.923 24.4223 169.923 25.3389 169.923 26.4065C167.037 26.3004 164.156 26.7461 161.334 25.9787C159.248 25.4108 158.207 23.9677 158.112 22.0644C158.004 19.897 158.054 17.7115 158.225 15.5477C158.366 13.7361 159.597 12.6308 161.508 12.2282C162.171 12.0881 162.856 11.9623 163.53 11.9605C167.132 11.9461 170.741 11.8472 174.335 12.0216C177.369 12.1689 178.477 13.4557 178.493 16.2432C178.503 17.6451 178.495 19.0487 178.495 20.6248ZM174.19 18.0854C174.19 17.3395 174.18 16.7482 174.192 16.1587C174.207 15.4255 173.788 14.987 173.044 14.9816C170.01 14.9564 166.975 14.9259 163.943 15.0175C162.714 15.0535 161.811 16.7303 162.342 17.7601C162.427 17.9236 162.751 18.0728 162.964 18.0728C166.639 18.0908 170.313 18.0854 174.19 18.0854Z" />
        <path d="M91.3787 23.4339C91.3787 24.4834 91.3787 25.3785 91.3787 26.5556C88.5505 26.3903 85.7864 26.3813 83.0745 26.0237C80.9791 25.7469 79.7346 24.3037 79.6106 22.3519C79.4691 20.1162 79.4788 17.8607 79.6397 15.6268C79.7734 13.7505 80.9617 12.6272 82.9698 12.2372C84.1484 12.009 85.3735 11.8904 86.5792 11.8832C89.6496 11.867 92.7278 11.8293 95.7886 12.0144C98.797 12.1959 99.9232 13.4701 99.9542 16.2504C99.9659 17.3737 99.9116 18.5005 99.9717 19.6202C100.012 20.4074 99.7158 20.6428 98.8668 20.6356C94.3154 20.6015 89.762 20.6051 85.2107 20.6392C84.7086 20.6428 83.91 20.233 83.7413 21.137C83.5397 22.2082 84.0534 23.0978 85.0323 23.2955C85.6177 23.4141 86.2361 23.4177 86.8389 23.4231C88.3082 23.4465 89.7834 23.4339 91.3787 23.4339ZM89.6865 18.0908C91.3167 18.0908 92.9469 18.1015 94.5771 18.0818C94.895 18.0782 95.4745 17.9739 95.4842 17.8787C95.5598 17.1221 95.6858 16.3097 95.4532 15.6124C95.3427 15.2799 94.4918 14.9852 93.9665 14.9726C91.3516 14.9079 88.7347 14.9259 86.1198 14.9492C84.3345 14.9654 83.8887 15.4291 83.7278 17.0825C83.6483 17.8931 83.9546 18.1285 84.7958 18.1051C86.426 18.0566 88.0563 18.0908 89.6865 18.0908Z" />
        <path d="M253.608 17.3773C253.765 15.4578 253.137 14.7659 251.298 14.712C249.894 14.6707 248.489 14.685 247.084 14.6707C247.016 14.6707 246.95 14.6185 246.727 14.5233C246.727 13.8637 246.68 13.1322 246.756 12.4116C246.777 12.2139 247.169 11.8886 247.38 11.894C249.613 11.9569 251.871 11.9155 254.07 12.2229C256.419 12.5517 257.679 13.826 257.863 15.979C258.045 18.1069 258.049 20.2708 257.853 22.3987C257.627 24.8716 256.186 26.1585 253.329 26.2645C249.047 26.4244 244.748 26.4173 240.464 26.2861C237.723 26.2016 236.599 24.8699 236.593 22.2962C236.581 18.4736 237.351 17.6037 241.489 17.4312C245.091 17.282 248.704 17.3827 252.311 17.3737C252.72 17.3773 253.131 17.3773 253.608 17.3773ZM253.699 19.8952C253.318 19.861 253.097 19.8251 252.876 19.8251C249.495 19.8215 246.114 19.8107 242.736 19.8269C241.272 19.8341 240.886 20.233 240.873 21.6169C240.857 23.3188 241.152 23.6873 242.751 23.707C245.448 23.7394 248.144 23.7466 250.84 23.707C253.108 23.6729 253.701 23.0618 253.699 20.9429C253.699 20.6284 253.699 20.3139 253.699 19.8952Z" />
        <path d="M107.31 26.3472C105.822 26.3472 104.509 26.3472 103.166 26.3472C103.118 26.1638 103.052 26.0326 103.052 25.9015C103.054 22.9486 102.994 19.994 103.089 17.043C103.191 13.8493 104.827 12.2067 108.274 12.0395C111.527 11.8814 114.802 11.8814 118.055 12.0341C121.595 12.2013 123.204 13.8062 123.318 17.0699C123.419 19.9149 123.359 22.7635 123.393 25.6103C123.401 26.1908 123.182 26.3903 122.543 26.4226C119.071 26.5988 119.071 26.6132 119.071 23.4015C119.071 21.5738 119.092 19.7442 119.063 17.9182C119.034 16.0941 118.334 15.3895 116.406 15.3392C114.281 15.2853 112.151 15.2853 110.026 15.3392C108.115 15.3877 107.382 16.1012 107.345 17.9128C107.297 20.3031 107.318 22.6934 107.309 25.0837C107.31 25.4629 107.31 25.8439 107.31 26.3472Z" />
        <path d="M154.67 26.34C153.2 26.34 151.818 26.34 150.366 26.34C150.366 19.9671 150.366 13.666 150.366 7.26973C151.795 7.26973 153.2 7.26973 154.67 7.26973C154.67 13.6067 154.67 19.9077 154.67 26.34Z" />
        <path d="M229.192 12.3001C230.613 12.3001 231.968 12.3001 233.449 12.3001C233.476 12.6344 233.526 12.9687 233.526 13.303C233.532 17.3072 233.503 21.3114 233.552 25.3138C233.563 26.207 233.251 26.4837 232.321 26.4244C231.309 26.3597 230.287 26.4101 229.192 26.4101C229.192 21.6726 229.192 17.0538 229.192 12.3001Z" />
        <path d="M231.332 11.0115C229.266 11.0151 229.113 10.8839 229.111 9.11726C229.109 7.16369 229.202 7.07563 231.268 7.07563C233.35 7.07383 233.532 7.23378 233.528 9.05616C233.526 10.8084 233.301 11.0097 231.332 11.0115Z" />
      </svg>
    </div>
  );
}
