import { clsxm } from "@/utils";
import { Link } from "react-router-dom";

export default function ClientLink({ client, className }) {
  const emojis = {
    person: "🧑",
    company: "🏢",
    freelancer: "🧑‍💻",
  };
  return (
    <Link to={`/clients/${client.id}`}>
      <span
        className={clsxm(
          "text-neutral-900 font-semibold decoration-neutral-100 underline decoration-2 hover:decoration-neutral-300",
          className
        )}
      >
        {emojis[client.type]} {client.name}
      </span>
    </Link>
  );
}
