import Label from "@/components/form/Label";
import { Button } from "@/components/ui/Button";
import { Card2, SkeletonCard } from "@/components/ui/Card";
import Table from "@/components/ui/Table";
import { API_URL } from "@/config/routes";
import { fetcher, formatNumber } from "@/utils";
import { Card, CategoryBar, Flex, Metric, Text } from "@tremor/react";
import { useEffect, useState } from "react";
import {
  BsDashCircleFill,
  BsFillArrowDownCircleFill,
  BsFillArrowUpCircleFill,
  BsSearch,
} from "react-icons/bs";
import { toast } from "react-toastify";

function MissingDataCard({ onClick }) {
  return (
    <Card2>
      <Card2.Header>Analisi rischio</Card2.Header>
      <div className="flex flex-col gap-3">
        <span className="text-sm text-zinc-800 px-4 bg-slate-200 py-3 rounded border-l-4 border-amber-400">
          Sembra che il sistema non abbia mai raccolto dati sul rischio e la
          performance di questa azienda. Prova ad avviare una nuova analisi per
          aggiornare i dati.
        </span>
        <Button icon={<BsSearch />} className="w-fit" onClick={onClick}>
          Acquisisci dati sull'azienda
        </Button>
      </div>
    </Card2>
  );
}

export default function ClientRiskDetailsCard({ client }) {
  const [creditsafeData, setCreditsafeData] = useState();
  const [creditsafeDataLoading, setCreditsafeDataLoading] = useState(false);

  useEffect(() => {
    if (!client) return;
    if (client.type !== "company") return;
    if (creditsafeData) return;
    setCreditsafeDataLoading(true);
    fetcher(`${API_URL}/creditsafe/clients/${client.id}`)
      .then((res) => {
        if (res.status === 404) return null;
        return res.json();
      })
      .then((data) => {
        setCreditsafeData(data);
        setCreditsafeDataLoading(false);
      });
  }, [client]);

  const updateCreditsafeData = () => {
    setCreditsafeDataLoading(true);
    fetcher(`${API_URL}/creditsafe/clients/${client.id}/fresh`, {})
      .then((res) => {
        if (res.status === 404) {
          toast.error("Non è stato possibile trovare l'azienda su Creditsafe");
          return null;
        }
        toast.success("Dati aggiornati con successo");
        return res.json();
      })
      .then((data) => {
        setCreditsafeData(data);
        setCreditsafeDataLoading(false);
      });
  };

  if (!client) return null;
  if (client.type !== "company") return null;
  if (creditsafeDataLoading) return <SkeletonCard className="ml-0" />;
  if (!creditsafeData && !creditsafeDataLoading)
    return <MissingDataCard onClick={updateCreditsafeData} />;

  const riskValue = parseInt(
    creditsafeData.report.creditScore.currentCreditRating.providerValue.value
  );
  const riskDescription =
    creditsafeData.report.creditScore.currentCreditRating.commonDescription;

  return (
    <div className="flex flex-col gap-4">
      {/* Activity */}
      <Card2>
        <Card2.Header>Attività azienda</Card2.Header>
        <div className="w-full bg-zinc-50 py-2 px-2 rounded shadow-inner border">
          {creditsafeData.report.companySummary.mainActivity.description ? (
            <span className="text-zinc-800 font-medium">
              {creditsafeData.report.companySummary.mainActivity.description} (
              {creditsafeData.report.companySummary.mainActivity.code})
            </span>
          ) : (
            <span className="text-zinc-500 italic">Non disponibile</span>
          )}
        </div>
      </Card2>
      {/* Credit score */}
      <Card2>
        <Card2.Header>Credit score</Card2.Header>
        <Card>
          <Text>Valutazione: {riskDescription}</Text>
          <Flex
            justifyContent="start"
            alignItems="baseline"
            className="space-x-1"
          >
            <Metric>{riskValue}</Metric>
            <Text>/ 100</Text>
          </Flex>
          <CategoryBar
            values={[10, 25, 25, 40]} // Barchart areas
            colors={["rose", "orange", "yellow", "emerald"]}
            markerValue={riskValue}
            tooltip={`${riskDescription} (${riskValue}/100)`}
            showLabels={false}
            className="mt-3"
          />
        </Card>
      </Card2>
      {/* Financials */}
      <Card2>
        <Card2.Header>Dati finanziari chiave</Card2.Header>
        <Table>
          <Table.Head>
            <Table.Row>
              <Table.Th className="w-1/4">Anno</Table.Th>
              <Table.Th className="text-end">Fatturato</Table.Th>
              <Table.Th className="text-end">Utile / Perdita</Table.Th>
            </Table.Row>
          </Table.Head>
          <Table.Body>
            {creditsafeData.report.financialStatements?.map(
              (statement, index) => (
                <Table.Row key={index}>
                  <Table.Td>
                    {new Date(statement.yearEndDate).getFullYear()}
                  </Table.Td>
                  <Table.Td className="text-end">
                    €{formatNumber(statement.profitAndLoss.revenue, 0)}
                  </Table.Td>
                  <Table.Td className="text-end">
                    €{formatNumber(statement.profitAndLoss.retainedProfit, 0)}
                  </Table.Td>
                </Table.Row>
              )
            )}
          </Table.Body>
        </Table>
      </Card2>
      {/* Comments */}
      <Card2>
        <Card2.Header>Commenti</Card2.Header>
        <div className="bg-white rounded-md shadow-sm border-2 border-zinc-100">
          {creditsafeData.report.alternateSummary.commentaries?.map(
            (comment, index) => (
              <p
                key={index}
                className="text-sm text-zinc-800 p-3 px-4 border-b border-zinc-100 last:border-b-0"
              >
                {comment.positiveOrNegative === "Positive" ? (
                  <BsFillArrowUpCircleFill className="mr-1.5 -mt-0.5 inline text-green-500" />
                ) : comment.positiveOrNegative === "Negative" ? (
                  <BsFillArrowDownCircleFill className="mr-1.5 -mt-0.5 inline text-red-500" />
                ) : (
                  <BsDashCircleFill className="mr-1.5 -mt-0.5 inline text-zinc-500" />
                )}
                {comment.commentaryText}
              </p>
            )
          )}
        </div>
      </Card2>
      <Card2 className="text-xs text-zinc-500 flex flex-row">
        Dati sull'attività aggiornati al{" "}
        {Intl.DateTimeFormat("it-IT").format(
          new Date(creditsafeData.dateOfOrder)
        )}
        .{" "}
        <button
          onClick={updateCreditsafeData}
          className="underline font-medium hover:text-black"
        >
          Aggiorna.
        </button>
      </Card2>
    </div>
  );
}
