import { clsxm } from "@/utils";

export default function TaskLabelBadge({ label, className }) {
  return (
    <span
      key={label.id}
      className={clsxm(
        "text-white text-xs rounded-full font-medium px-2 py-1",
        className
      )}
      style={{ backgroundColor: label.color }}
      title={label.description}
    >
      {label.title}
    </span>
  );
}
