import { FormikSelect, Select } from "@/components/form/inputs/Select";
import { useAuth } from "@/hooks/useAuth";
import { useEffect, useState } from "react";

function RoleSubtitle({ children }) {
  return <span className="text-sm text-gray-500 ml-1">· {children}</span>;
}

const USER_ROLES = [
  {
    value: "admin",
    label: (
      <span>
        Amministratore
        <RoleSubtitle>Accesso completo</RoleSubtitle>
      </span>
    ),
    createdBy: ["admin"],
    filterKey: "amministratore",
  },
  {
    value: "manager",
    label: (
      <span>
        Manager
        <RoleSubtitle>Può creare e gestire Utenti</RoleSubtitle>
      </span>
    ),
    filterKey: "manager",
    createdBy: ["admin", "partner"],
  },
  {
    value: "partner",
    label: (
      <span>
        Socio
        <RoleSubtitle>Può creare e gestire Utenti e Manager</RoleSubtitle>
      </span>
    ),
    filterKey: "socio",
    createdBy: ["admin"],
  },
  {
    value: "user",
    label: (
      <span>
        Utente
        <RoleSubtitle>Accesso di base</RoleSubtitle>
      </span>
    ),
    createdBy: ["admin", "partner", "manager"],
    filterKey: "utente",
  },
];

// Hook to get the available roles options for a given user
// based on the current user role and special rules
const useRoleOptions = (user) => {
  const { user: currentUser } = useAuth();
  const [options, setOptions] = useState(USER_ROLES);
  const superAdmins = [1, 2, 3, 17, 45, 166]; // HARD-CODED (me, stef, giusy, bea, ewe, backoffice user)

  const handleUserChange = () => {
    // If the requested options are for the current user, we need to check
    // if the current user is a super admin, in which case we allow to change
    // the role to any other role.
    if (user?.id === currentUser.id) {
      if (superAdmins.includes(currentUser.id)) return setOptions(USER_ROLES);
      // Otherwise, we only allow to choose the current role for the current user.
      return setOptions(
        USER_ROLES.filter((role) => role.value === currentUser.role)
      );
    }
    const defaultOptions = USER_ROLES.filter((role) =>
      role.createdBy.includes(currentUser.role)
    );
    // If `defaultOptions` is empty, it means that the current user is not allowed to
    // change the role of the requested user, so we only allow to choose its current role.
    if (defaultOptions.length === 0 && user?.role) {
      return setOptions(USER_ROLES.filter((role) => role.value === user.role));
    }
    return setOptions(defaultOptions);
  };

  useEffect(() => {
    handleUserChange();
  }, [user, currentUser]);

  return options;
};

export function UserRoleSelect({ user, ...props }) {
  const options = useRoleOptions(user);
  return <Select options={options} {...props} />;
}

export function FormikUserRoleSelect({ user, ...props }) {
  const options = useRoleOptions(user);
  return <FormikSelect options={options} {...props} />;
}
