import React, { useState } from "react";
import {
  BsCheckSquareFill,
  BsFillBookmarkStarFill,
  BsFillPeopleFill,
} from "react-icons/bs";
import { FaPaperclip, FaShippingFast, FaStar } from "react-icons/fa";
import { HiDocumentChartBar } from "react-icons/hi2";
import { IoStatsChartSharp } from "react-icons/io5";
import { MdAccountTree, MdAnnouncement, MdLocalOffer } from "react-icons/md";
import { RiDashboardFill } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import { TbPackages } from "react-icons/tb";
import { Link, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "../../hooks/useAuth";
import { clsxm } from "../../utils";
import Logo from "../ui/Logo";
import UserDropdown from "./UserDropdown";
import LogoCarNecting from "/images/logo-carnecting.webp";

const CARNECTING_NETWORK = 41; // Car Necting network ID

const sidebarLinks = [
  {
    href: "/dashboard",
    icon: <RiDashboardFill />,
    children: "Dashboard",
  },
  {
    href: "/offers",
    icon: <MdLocalOffer />,
    children: "Offerte",
  },
  {
    href: "/fast-delivery",
    icon: <FaShippingFast className="text-lg" />,
    children: "Pronta consegna",
  },
  {
    href: "/stock",
    icon: <TbPackages className="text-xl" />,
    children: "Stock",
  },
  {
    href: "/deals",
    icon: <FaPaperclip />,
    children: "Trattative",
  },
  {
    href: "/contracts",
    icon: <HiDocumentChartBar />,
    children: "Contratti",
    allowedRoles: ["admin"],
  },
  {
    href: "/clients",
    icon: <BsFillPeopleFill />,
    children: "Clienti",
  },
  {
    href: "/users",
    icon: <MdAccountTree />,
    children: "Rete",
    allowedRoles: ["admin", "manager", "partner"],
  },
  {
    href: "/bookmarks",
    icon: <BsFillBookmarkStarFill />,
    children: "Risorse",
  },
  {
    href: "/announcements",
    icon: <MdAnnouncement />,
    children: "Comunicazioni",
  },
  {
    href: "/tasks",
    icon: <BsCheckSquareFill className="text-base" />,
    children: "Todos",
  },
  {
    href: "/access-logs",
    icon: <IoStatsChartSharp className="text-base" />,
    children: "Log accessi",
    allowedRoles: ["admin"],
  },
];

function SidebarLink({ href, children, icon, className, onClick, new: isNew }) {
  // Set icon className to the proper Tailwind CSS classes
  const location = useLocation();
  const selected = location.pathname.split("/")[1] === href.split("/")[1];

  return (
    <Link
      to={href}
      onClick={onClick}
      className={clsxm(
        "flex items-center p-2 px-4 rounded-lg hover:bg-zinc-200 text-zinc-600 hover:text-zinc-800 active:bg-zinc-300 transition-colors duration-200 ease-in-out",
        selected &&
          "bg-[#d50000] text-white hover:text-white hover:bg-[#d50000] shadow-inner shadow-black/20",
        className
      )}
    >
      {icon && <span className="text-lg">{icon}</span>}
      <span className="ml-3 mr-auto">{children}</span>
      {isNew && (
        <FaStar
          className={clsxm("text-lg text-[#d50000]", selected && "text-white")}
        />
      )}
    </Link>
  );
}

function SidebarToggleButton({ onClick }) {
  return (
    <button
      onClick={onClick}
      type="button"
      className="inline-flex items-center p-2 mt-1 text-xl text-gray-500 md:hidden"
    >
      <RxHamburgerMenu />
    </button>
  );
}

function SidebarMobileHeader({ onToggleClick }) {
  return (
    <>
      <div className="fixed w-full z-10">
        <div className="flex items-center justify-between px-4 py-2 bg-gray-50 md:hidden w-full shadow">
          <SidebarToggleButton onClick={onToggleClick} />
          <Logo />
          <div className="w-8 h-8"></div>
        </div>
      </div>
      <div className="mb-14"></div>
    </>
  );
}

function Sidebar() {
  // Set up a state to keep track of whether the sidebar is open or not
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const { user } = useAuth();

  function closeSidebar(e) {
    e.stopPropagation();
    setIsSidebarOpen(false);
  }

  return (
    <>
      {/* The sidebar header for mobile */}
      <SidebarMobileHeader
        onToggleClick={() => setIsSidebarOpen(!isSidebarOpen)}
      />

      {/* Set up a transparent div that covers the screen so that we can use
      it to detect whether the user is trying to close the sidebar once it's toggled on mobile */}
      <div
        className={clsxm(
          "fixed top-0 right-0 w-screen h-screen bg-transparent md:hidden z-10 transition duration-500 ease-in-out select-none",
          { "bg-black/20": isSidebarOpen },
          { "pointer-events-none": !isSidebarOpen },
          { "pointer-events-auto": isSidebarOpen }
        )}
        onClick={closeSidebar}
        onTouchMove={closeSidebar}
      ></div>

      {/* Spacer for the sidebar */}
      <div className="m-0 md:ml-64"></div>
      {/* The actual sidebar */}
      <aside
        className={clsxm(
          "fixed inset-y-0 z-10 md:opacity-100 flex-shrink-0 w-64 transition duration-75 ease-in-out transform bg-white border-r border-gray-200 md:inset-0 md:z-auto md:overflow-y-auto md:shadow-none md:transform-none md:flex md:flex-col md:w-64",
          { "-translate-x-full opacity-0": !isSidebarOpen }
        )}
        aria-label="Sidebar"
      >
        <div className="flex flex-col h-full px-4 py-4 overflow-y-auto bg-gray-50">
          <div className="flex flex-col items-center flex-shrink-0 px-4 py-3 pt-6 pl-7">
            <Logo className="w-[90%] text-[#d50000]" />
            {user?.network_id === CARNECTING_NETWORK && (
              <img
                src={LogoCarNecting}
                alt="Car Necting"
                className="w-full h-auto mt-4 -mb-4 bg-zinc-200/75 px-4 py-2 pt-3 rounded-lg shadow-inner"
              />
            )}
          </div>

          <ul className="space-y-0.5 px-3 py-3 flex-grow">
            {/* The sidebar links */}
            {sidebarLinks
              .filter(
                (link) =>
                  // Filter for right roles
                  (!link.allowedRoles ||
                    link.allowedRoles.includes(user?.role)) &&
                  // Filter out wrong users
                  (!link.hideFromUsers || !link.hideFromUsers.includes(user.id))
              )
              .map((link) => (
                <li key={link.href} onClick={() => setIsSidebarOpen(false)}>
                  <SidebarLink
                    href={link.href}
                    icon={link.icon}
                    className={link.className}
                    new={link.new}
                  >
                    {link.children}
                  </SidebarLink>
                </li>
              ))}
          </ul>
        </div>
        <div className="bg-gray-50 p-4">
          <UserDropdown />
        </div>
      </aside>
    </>
  );
}

export function SidebarLayout() {
  return (
    <>
      <div className="flex flex-col md:flex-row">
        <Sidebar />
        <div className="flex flex-col grow min-h-screen">
          <Outlet />
        </div>
      </div>
    </>
  );
}
