import { clsxm, formatNumber } from "@/utils";
import { useEffect, useState } from "react";

const buttonClasses =
  "bg-white hover:bg-blue-700 text-blue-700 font-semibold hover:text-white px-2 select-none font-mono";
const activeButtonClasses = "bg-blue-600 text-white font-semibold";
const separatorClasses = "text-blue-400 select-none";

export default function Pagination({
  onPageChange,
  currentPage,
  totalPages,
  maxPages = 5,
  perPage,
  showTotal = false,
}) {
  const [pages, setPages] = useState([]);

  // Make sure all values are ints
  currentPage = parseInt(currentPage);
  totalPages = parseInt(totalPages);
  maxPages = parseInt(maxPages);

  useEffect(() => {
    // If totalPages is falsy, we don't want to do anything
    if (!totalPages) return;

    let startPage = Math.max(currentPage - Math.floor(maxPages / 2), 1);
    let endPage = Math.min(startPage + maxPages - 1, totalPages);

    if (endPage - startPage + 1 < maxPages && startPage > 1) {
      startPage = endPage - maxPages + 1;
    }

    setPages(
      [...Array(endPage - startPage + 1).keys()].map((i) => i + startPage)
    );
  }, [currentPage, totalPages, maxPages]);

  // If totalPages is falsy, don't render anything
  if (!totalPages) return null;

  return (
    <div className="flex gap-6 items-center">
      {showTotal && (
        <span className="text-sm text-gray-400">
          <b>{currentPage * perPage - perPage + 1}</b>-
          <b>{currentPage * perPage}</b> di ~{" "}
          <b>{formatNumber(totalPages * perPage, 0)}</b> risultati
        </span>
      )}
      <nav className="flex flex-row justify-center">
        <ul className="flex flex-row items-center">
          {currentPage > 1 && (
            <li>
              <button
                className={buttonClasses}
                onClick={() => onPageChange(currentPage - 1)}
              >
                {"<"}
              </button>
            </li>
          )}
          {pages[0] > 1 && <li className={separatorClasses}>...</li>}
          {pages.map((page) => (
            <li key={page}>
              <button
                className={clsxm(
                  buttonClasses,
                  page === currentPage && activeButtonClasses
                )}
                onClick={() => onPageChange(page)}
              >
                {page}
              </button>
            </li>
          ))}
          {pages[pages.length - 1] < totalPages && (
            <li className={separatorClasses}>...</li>
          )}
          {currentPage < totalPages && (
            <li>
              <button
                className={buttonClasses}
                onClick={() => onPageChange(currentPage + 1)}
              >
                {">"}
              </button>
            </li>
          )}
        </ul>
      </nav>
    </div>
  );
}
