import {
  CreatableSelect,
  FormikSelect,
  Select,
} from "@/components/form/inputs";
import { API_URL } from "@/config/routes";
import useSWR from "swr";

function useVehicleBrandOptions() {
  const { data: brands } = useSWR(`${API_URL}/vehicles/brands`, {
    fallbackData: [],
  });

  return brands?.map((brand) => ({
    value: brand.id,
    label: brand.name,
  }));
}

export function VehicleBrandSelect(props) {
  const options = useVehicleBrandOptions();
  return <Select options={options} {...props} />;
}

export function CreatableVehicleBrandSelect(props) {
  const options = useVehicleBrandOptions();
  return <CreatableSelect options={options} {...props} />;
}

export function FormikVehicleBrandSelect(props) {
  const options = useVehicleBrandOptions();
  return <FormikSelect options={options} {...props} />;
}
