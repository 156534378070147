import { FormikMultiSelect, MultiSelect } from "@/components/form/inputs";
import TaskLabelBadge from "./TaskLabelBadge";
import useTaskLabels from "./useTaskLabels";

export default function TaskLabelsMultiSelect({ ...props }) {
  const { labels } = useTaskLabels();
  return (
    <MultiSelect
      isMulti={true}
      options={labels.map((label) => ({
        value: label.id,
        label: <TaskLabelBadge label={label} />,
      }))}
      {...props}
    />
  );
}

export function FormikTaskLabelsMultiSelect({ name, ...props }) {
  const { labels } = useTaskLabels();
  return (
    <FormikMultiSelect
      name={name}
      isMulti={true}
      options={labels.map((label) => ({
        value: label.id,
        label: <TaskLabelBadge label={label} />,
      }))}
      {...props}
    />
  );
}
