import Label from "@/components/form/Label";
import { Input, Select, Textarea } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { Card2 } from "@/components/ui/Card";
import { API_URL } from "@/config/routes";
import { CreatableVehicleBrandSelect } from "@/features/vehicles/VehicleBrandSelect";
import { CreatableVehicleModelSelect } from "@/features/vehicles/VehicleModelSelect";
import { CreatableVehicleVersionSelect } from "@/features/vehicles/VehicleVersionSelect";
import { fetcher } from "@/utils";
import { UploadDropzone } from "@bytescale/upload-widget-react";
import React, { useEffect, useRef } from "react";
import { CgSpinner } from "react-icons/cg";
import { FaPlus, FaRegCopy } from "react-icons/fa";
import { MdDeleteForever, MdOutlineSave } from "react-icons/md";
import { toast } from "react-toastify";

// Configuration: https://www.bytescale.com/docs/upload-widget#configuration
const options = {
  apiKey: "public_W142iJpMX8WUpdoF6H3zU16TpLRE", // This is your API key.
  maxFileCount: 1,
  locale: {
    addAnotherFile: "Aggiungi un altro file...",
    addAnotherImage: "Aggiungi un'altra immagine...",
    cancel: "annulla",
    "cancelled!": "annullato",
    continue: "Continua",
    crop: "Ritaglia",
    done: "Fatto",
    "error!": "Errore!",
    finish: "Finito",
    finishIcon: true,
    image: "Immagine",
    maxFilesReached: "Numero massimo di file:",
    maxImagesReached: "Numero massimo di immagini:",
    maxSize: "Limite dimensione file:",
    next: "Avanti",
    of: "di",
    orDragDropFile: "...o trascina e rilascia un file.",
    orDragDropFiles: "...o trascina e rilascia dei file.",
    orDragDropImage: "...o trascina e rilascia un'immagine.",
    orDragDropImages: "...o trascina e rilascia delle immagini.",
    pleaseWait: "Attendere prego...",
    remove: "rimuovi",
    "removed!": "rimosso",
    skip: "Salta",
    unsupportedFileType: "Tipo di file non supportato.",
    uploadFile: "Carica un file",
    uploadFiles: "Carica file",
    uploadImage: "Carica un'immagine",
    uploadImages: "Carica immagini",
  },
  showFinishButton: true, // Note: You must use 'onUpdate' if you set 'showFinishButton: false' (default).
  mimeTypes: ["image/jpeg", "image/png"],
  styles: {
    colors: {
      primary: "#377dff",
    },
  },
};

const VehicleInputRow = React.memo(({ label, subtitle, input }) => {
  return (
    <div className="flex flex-col w-96 flex-grow">
      <Label className="block mb-1">{label}</Label>
      {input}
      {subtitle && <p className="mt-1 text-sm text-gray-500">{subtitle}</p>}
    </div>
  );
});

const OfferInput = ({ children }) => {
  return <div className="flex-grow">{children}</div>;
};

const INITIAL_OFFER = {
  offer_price: "",
  offer_target: "all",
  offer_fast_delivery: false,
  offer_duration: "",
  offer_distance: "",
  offer_deposit: "",
  offer_commission: "",
  offer_vendor: "",
};

export default function OfferCreateForm() {
  const formRef = useRef(null);
  const [offers, setOffers] = React.useState([{ ...INITIAL_OFFER }]);
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const [image, setImage] = React.useState(null);
  const [vehicle, setVehicle] = React.useState({
    brand: null,
    model: null,
    version: null,
  });

  useEffect(() => {
    // If the model is set, set the image to the model image
    if (vehicle.model) {
      setImage(vehicle.model.image);
    } else {
      setImage(null);
    }
  }, [vehicle.model]);

  useEffect(() => {
    // If the version is set, autofill the optionals and market value field
    if (vehicle.version) {
      fetcher(`${API_URL}/vehicles/${vehicle.version.slug}`)
        .then((response) => {
          if (!response.ok) throw new Error("Response was not ok");
          return response.json();
        })
        .then((vehicleData) => {
          const stockEquipment = vehicleData?.stock_equipment || [];
          const marketValue = vehicleData?.details?.market_value?.value || null;
          formRef.current.vehicle_optionals.value = stockEquipment.join(", ");
          formRef.current.vehicle_market_value.value = marketValue;
        })
        .catch((error) => {
          // Reset the optionals and market value field
          formRef.current.vehicle_optionals.value = null;
          formRef.current.vehicle_market_value.value = null;
        });
    } else {
      // Reset the optionals and market value field
      formRef.current.vehicle_optionals.value = null;
      formRef.current.vehicle_market_value.value = null;
    }
  }, [vehicle.version]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitting(true);
    const formData = new FormData(formRef.current);
    const vehicleKeys = [
      "vehicle_brand_name",
      "vehicle_model_name",
      "vehicle_version_name",
      "vehicle_market_value",
      "vehicle_optionals",
      "vehicle_image",
    ];

    // Get the relevant vehicle data from the form
    const data = Object.fromEntries(
      vehicleKeys.map((key) => [key, formData.get(key)])
    );

    // Get the offers in the right format and add them to the vehicle data
    const offersData = offers.map((offer) => ({ ...offer }));
    data.offers = offersData;

    // Call the API to create the offer
    fetcher(`${API_URL}/offers`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        const lastChar = data.offers.length === 1 ? "a" : "e";
        const successMessage = (
          <span>
            [<b>{data.offers.length}</b>] nuov{lastChar} offert
            {lastChar} aggiunt{lastChar} al veicolo [
            <b>
              {`${data.vehicle_brand_name} ${data.vehicle_model_name} ${data.vehicle_version_name}`}
            </b>
            ]
          </span>
        );
        if (response.ok) {
          toast.success(successMessage);
          // Reset the form
          formRef.current.reset();
          setOffers([{ ...INITIAL_OFFER }]);
          setImage(null);
          setVehicle({
            brand: null,
            model: null,
            version: null,
          });
        } else {
          toast.error("Errore nella creazione dell'offerta");
        }
      })
      .catch((error) => {
        toast.error("Errore nella creazione dell'offerta: " + error.message);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  const updateOffer = (index, key, value) => {
    setOffers((offers) => {
      const newOffers = [...offers];
      newOffers[index][key] = value;
      return newOffers;
    });
  };

  const deleteOffer = (index) => {
    setOffers((offers) => offers.filter((_, i) => i !== index));
  };

  const duplicateOffer = (index) => {
    setOffers((offers) => {
      const newOffers = [...offers, { ...offers[index] }];
      return newOffers;
    });
  };

  const addOffer = () => {
    setOffers((offers) => [...offers, { ...INITIAL_OFFER }]);
  };

  const offerInputProps = (index, key, checkbox = false) => ({
    required: checkbox ? false : true,
    checked: checkbox ? offers[index][key] : undefined,
    name: `${key}_${index}`,
    value: offers[index][key],
    onChange: (e) =>
      updateOffer(index, key, checkbox ? e.target.checked : e.target.value),
  });

  return (
    <form onSubmit={handleSubmit} ref={formRef}>
      <Card2>
        <Card2.Header>Dati veicolo</Card2.Header>
        <div className="flex gap-2">
          <div className="flex flex-col gap-4">
            <VehicleInputRow
              label="Marca"
              input={
                <CreatableVehicleBrandSelect
                  required
                  value={vehicle.brand?.label || null}
                  onChange={(e) =>
                    setVehicle({ brand: e.target, model: null, version: null })
                  }
                  name="vehicle_brand_name"
                />
              }
            />
            <VehicleInputRow
              label="Modello"
              input={
                <CreatableVehicleModelSelect
                  required
                  value={vehicle.model?.label || null}
                  onChange={(e) =>
                    setVehicle({ ...vehicle, model: e.target, version: null })
                  }
                  brandId={vehicle.brand?.value}
                  name="vehicle_model_name"
                  isDisabled={!vehicle.brand}
                />
              }
            />
            <VehicleInputRow
              label="Allestimento"
              input={
                <CreatableVehicleVersionSelect
                  required
                  modelId={vehicle.model?.value}
                  value={vehicle.version?.label || null}
                  onChange={(e) =>
                    setVehicle({ ...vehicle, version: e.target })
                  }
                  name="vehicle_version_name"
                  isDisabled={!vehicle.model}
                />
              }
            />
            <VehicleInputRow
              label="Valore di mercato"
              input={
                <Input
                  type="number"
                  name="vehicle_market_value"
                  suffix="€"
                  isDisabled={!vehicle.version}
                />
              }
            />
            <VehicleInputRow
              label="Optional"
              subtitle="Inserisci gli optional del veicolo separati da una virgola (es. ABS, ESP, Airbag). Non includere gli optional di serie."
              input={
                <Textarea
                  rows={5}
                  name="vehicle_optionals"
                  isDisabled={!vehicle.version}
                />
              }
            />
          </div>
          <div className="flex flex-col flex-grow px-3">
            <Label className="pl-4">Immagine veicolo</Label>
            {image ? (
              <img
                src={image}
                alt="Vehicle"
                className="mx-4 my-1 rounded-2xl w-fit h-96 object-cover border"
              />
            ) : (
              <>
                <UploadDropzone
                  options={options}
                  onComplete={(files) => setImage(files[0].fileUrl)}
                  width="100%"
                  height="300px"
                />
              </>
            )}
            {/* Add this hidden input here to enable the required html validator */}
            <input
              className="opacity-0 cursor-default"
              name="vehicle_image"
              value={image}
              required
            />
          </div>
        </div>
      </Card2>

      <Card2>
        <Card2.Header>Dati offerte</Card2.Header>
        {offers.map((_, index) => (
          <div className="rounded-2xl bg-zinc-100 p-5" key={index}>
            <div className="px-2 flex gap-2 items-center mb-3 text-zinc-500">
              <h3 className="font-semibold font-mono mr-2">
                Offerta #{index + 1}
              </h3>
              <button
                className="text-white px-2 h-6 rounded text-sm font-medium bg-blue-500 hover:bg-blue-700 flex gap-2 items-center leading-none"
                type="button"
                onClick={() => duplicateOffer(index)}
              >
                <FaRegCopy /> Duplica
              </button>
              <button
                type="button"
                className="text-white px-2 h-6 rounded text-sm font-medium bg-red-500 hover:bg-red-600 flex gap-2 items-center leading-none"
                onClick={() => deleteOffer(index)}
              >
                <MdDeleteForever className="text-base" /> Elimina
              </button>
            </div>
            {/* Flex row because the inputs are separated from the `fast_delivery` checkbox */}
            <div className="flex gap-4">
              {/* Then we have the two input rows stacked vertically */}
              <div className="flex flex-col gap-2 flex-grow">
                {/* First row */}
                <div className="flex flex-grow gap-3">
                  <OfferInput>
                    <Label>Noleggiatore</Label>
                    <Select
                      {...offerInputProps(index, "offer_vendor")}
                      options={[
                        { value: "ALD", label: "ALD" },
                        { value: "Arval", label: "Arval" },
                        { value: "Leasys", label: "Leasys" },
                        { value: "Leaseplan", label: "Leaseplan" },
                        { value: "Alphabet", label: "Alphabet" },
                        { value: "Drivalia", label: "Drivalia" },
                        { value: "Athlon", label: "Athlon" },
                        { value: "Unipol", label: "Unipol" },
                        { value: "Kinto", label: "Kinto" },
                        { value: "Altro", label: "Altro" },                        
                      ]}
                    />
                  </OfferInput>

                  <OfferInput>
                    <Label>A chi è rivolta</Label>
                    <Select
                      {...offerInputProps(index, "offer_target")}
                      options={[
                        { label: "Tutti", value: "all" },
                        { label: "Privati", value: "private" },
                        { label: "Partita IVA", value: "company" },
                      ]}
                    />
                  </OfferInput>
                  <OfferInput>
                    <Label>Durata</Label>
                    <Input
                      {...offerInputProps(index, "offer_duration")}
                      type="number"
                      suffix="mesi"
                      min={24}
                      max={60}
                    />
                  </OfferInput>
                  <OfferInput>
                    <Label>Distanza</Label>
                    <Input
                      {...offerInputProps(index, "offer_distance")}
                      type="number"
                      suffix="km"
                      min={10000}
                      max={200000}
                      step={1000}
                    />
                  </OfferInput>
                </div>
                {/* Second row */}
                <div className="flex gap-3">
                  <OfferInput>
                    <Label>Canone (IVA esclusa)</Label>
                    <Input
                      {...offerInputProps(index, "offer_price")}
                      type="number"
                      min={100}
                      max={3000}
                      step={0.01}
                      suffix="€"
                    />
                  </OfferInput>
                  <OfferInput>
                    <Label>Anticipo (IVA esclusa)</Label>
                    <Input
                      {...offerInputProps(index, "offer_deposit")}
                      type="number"
                      suffix="€"
                      min={0}
                      max={20000}
                      step={100}
                    />
                  </OfferInput>
                  <OfferInput>
                    <Label>Provvigioni (IVA esclusa)</Label>
                    <Input
                      {...offerInputProps(index, "offer_commission")}
                      type="number"
                      suffix="€"
                      min={0}
                      max={20000}
                      step={0.01}
                    />
                  </OfferInput>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center px-4">
                <Input
                  {...offerInputProps(index, "offer_fast_delivery", true)}
                  className="w-12 h-12 mb-3"
                  type="checkbox"
                />
                <Label>Consegna rapida</Label>
              </div>
            </div>
          </div>
        ))}

        {/* Button to add a new offer */}
        <Button
          type="button"
          className="w-fit text-sm py-2 bg-white border-2 border-blue-500 hover:text-white text-blue-500"
          onClick={() => addOffer()}
        >
          Aggiungi offerta <FaPlus />
        </Button>
      </Card2>

      <Button type="submit" disabled={isSubmitting}>
        Salva offerte
        {isSubmitting ? (
          <CgSpinner className="text-xl animate-spin" />
        ) : (
          <MdOutlineSave className="text-xl" />
        )}
      </Button>
    </form>
  );
}
