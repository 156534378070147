export default function PillDropdown({
  options,
  icon,
  prefix,
  value,
  onChange,
}) {
  return (
    <div className="text-zinc-700 select-none rounded-full bg-slate-200 flex text-sm pr-2">
      <div className="font-semibold whitespace-nowrap flex items-center pl-4 pr-3 rounded-l-full bg-gray-100">
        {icon && <div className="mr-2 text-base">{icon}</div>}
        {prefix}
      </div>
      <select
        className="px-2 py-2 text-zinc-800 hover:cursor-pointer focus:outline-none rounded-full bg-inherit"
        value={value}
        onChange={onChange}
      >
        {options.map((option, index) => (
          <option
            key={index}
            value={option.value === undefined ? option : option.value}
          >
            {option.label || option}
          </option>
        ))}
      </select>
    </div>
  );
}
