import { FormikInput } from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { verticalTableFromRows } from "@/components/ui/Table";
import { API_URL } from "@/config/routes";
import { useAuth } from "@/hooks/useAuth";
import { fetcher } from "@/utils";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { FormikUserRoleSelect } from "./UserRoleSelect";

export default function UserCreateForm() {
  const { user } = useAuth();
  const navigate = useNavigate();

  const INITIAL_VALUES = {
    email: "",
    commission_percentage: "",
    role: "",
  };

  const handleSubmit = (values, { setSubmitting }) => {
    fetcher(`${API_URL}/users`, {
      method: "POST",
      body: JSON.stringify({ ...values, parent_id: user.id }),
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((res) => {
        if (res.ok) {
          return res.json();
        } else {
          throw res;
        }
      })
      .then((newUser) => {
        toast.success(
          <span>
            A breve contatteremo <b>{values.email}</b> per completare la
            registrazione.
          </span>
        );
        navigate(`/users/${newUser.id}`);
      })
      .catch((errorRes) => {
        if (errorRes.json) {
          errorRes.json().then((errorData) => {
            toast.error(errorData.message);
          });
        } else {
          console.log("Unknown error:", errorRes);
        }
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  return (
    <Formik initialValues={INITIAL_VALUES} onSubmit={handleSubmit}>
      {({ isSubmitting, isValid, dirty, resetForm }) => (
        <Form>
          {verticalTableFromRows(
            {
              labelClassName: "w-1/3",
              valueClassName: "w-2/3",
            },
            [
              {
                label: "Email",
                value: (
                  <FormikInput
                    type="email"
                    name="email"
                    placeholder="Inserisci l'e-mail"
                    required
                  />
                ),
              },
              {
                label: "Percentuale provv.",
                subLabel:
                  "Quanto l'utente guadagna (in percentuale) sulle sue vendite.",
                value: (
                  <FormikInput
                    type="number"
                    name="commission_percentage"
                    min="0"
                    max="100"
                    suffix="%"
                    placeholder="Inserisci la percentuale provvigionale"
                    required
                  />
                ),
              },
              {
                label: "Ruolo",
                value: (
                  <FormikUserRoleSelect
                    name="role"
                    placeholder="Seleziona il tipo di utente"
                    required
                  />
                ),
              },
            ]
          )}
          <div className="mt-4 flex gap-2 justify-end">
            <Button
              type="button"
              className="bg-gray-300 hover:bg-gray-400"
              onClick={() => resetForm(INITIAL_VALUES)}
            >
              Reset
            </Button>
            <Button type="submit" disabled={isSubmitting || !isValid || !dirty}>
              Aggiungi utente
            </Button>
          </div>
        </Form>
      )}
    </Formik>
  );
}
