import { useEffect, useState } from "react";

export default function useSessionStorage(key, defaultData) {
  const [state, setState] = useState(() => {
    const storedData = sessionStorage.getItem(key);
    return storedData ? JSON.parse(storedData) : defaultData;
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
}
