import { Card2, SkeletonCard } from "@/components/ui/Card";
import useAnnouncements from "./useAnnouncements";

export default function AnnouncementsList() {
  const { announcements, isLoading } = useAnnouncements();

  const announcementDateTime = (announcement) => {
    return new Date(announcement?.created_at + "Z").toLocaleString("it-IT", {
      timeZone: "Europe/Rome",
      year: "numeric",
      month: "long",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    });
  };

  return (
    <div className="flex flex-col gap-6 w-full py-2">
      {isLoading &&
        Array.from({ length: 5 }).map((_, index) => (
          <SkeletonCard key={index} className="m-0" />
        ))}
      {announcements.length &&
        announcements.map((announcement, index) => (
          <Card2 key={index} className="m-0">
            <Card2.Header>
              <div className="flex flex-row items-center justify-between px-4">
                <div className="text-xl font-bold text-zinc-700">
                  <span className="text-gray-400 text-sm">
                    #{announcement.id}
                  </span>{" "}
                  {announcement.title}
                </div>
                <div className="text-sm text-gray-500">
                  {announcementDateTime(announcement)}
                </div>
              </div>
            </Card2.Header>
            <div className="p-5 px-8 bg-white/90  border border-gray-100 rounded-2xl rounded-tl-sm shadow">
              <div
                className="prose max-w-full"
                dangerouslySetInnerHTML={{ __html: announcement.content }}
              />
            </div>
          </Card2>
        ))}
    </div>
  );
}
