import { API_URL } from "@/config/routes";
import { fetcher } from "@/utils";
import { useState } from "react";
import { toast } from "react-toastify";
import useSWR from "swr";

export default function useDeal(id) {
  const {
    data: deal,
    isLoading,
    error,
    mutate,
  } = useSWR(`${API_URL}/deals/${id}`);
  const [isUpdating, setIsUpdating] = useState(false);

  const updateDeal = async (data) => {
    setIsUpdating(true);
    mutate({ ...deal, ...data }, false); // Optimistic update

    const response = await fetcher(`${API_URL}/deals/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    mutate(); // Revalidate

    if (!response.ok) {
      toast.error("Errore durante l'aggiornamento della trattativa");
    } else {
      toast.success("Trattativa aggiornata correttamente");
    }

    setIsUpdating(false);
  };

  return { deal, isLoading, error, updateDeal, isUpdating };
}

export function useDealsMetadata() {
  const {
    data: metadata,
    isLoading,
    error,
  } = useSWR(`${API_URL}/deals/metadata`);
  return { metadata, isLoading, error };
}
