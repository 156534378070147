import {
  FormikInput,
  FormikSelect,
  FormikTextarea,
} from "@/components/form/inputs";
import { Card2, SkeletonCard } from "@/components/ui/Card";
import FileChip from "@/components/ui/FileChip";
import { verticalTableFromRows } from "@/components/ui/Table";
import { useAuth } from "@/hooks/useAuth";
import { clsxm, formatDate, formatNumber } from "@/utils";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ClientLink from "../clients/ClientLink";
import { FormikClientSelect } from "../clients/ClientSelect";
import UserLink from "../users/UserLink";
import { FormikUserSelect } from "../users/UserSelect";
import { useDealChat } from "./DealChatProvider";
import DealLink from "./DealLink";
import { FormikOutcomeSelect } from "./DealOutcomeSelect";
import DealStatusBadge, { DEAL_STATUSES } from "./DealStatusBadge";
import useDeal from "./useDeal";

function DealCreatedBySelect({ deal, readOnly, name = "author_id" }) {
  const { user } = useAuth();
  if (!readOnly && user.role === "admin") {
    return (
      <FormikUserSelect
        name={name}
        value={deal.author.id}
        readOnly={readOnly}
      />
    );
  }
  if (deal.author.id) {
    return <UserLink user={deal.author} />;
  }
  return <i>Admin</i>;
}

function DealClientSelect({ deal, readOnly, name = "client_id" }) {
  if (!readOnly) {
    return <FormikClientSelect name={name} readOnly={readOnly} required />;
  }
  if (deal.client.id) {
    return <ClientLink client={deal.client} />;
  }
  return <i>Non disponibile</i>;
}

function DealStatusSelect({ readOnly, name = "status" }) {
  const { user } = useAuth();
  return (
    <FormikSelect
      name={name}
      readOnly={user.role !== "admin" || readOnly}
      options={
        DEAL_STATUSES.map((status) => ({
          value: status,
          label: <DealStatusBadge status={status} />,
          filterKey: status,
        })) || []
      }
    />
  );
}

function DealOutcomeSelect({ readOnly, name = "outcome" }) {
  const { user } = useAuth();
  return (
    <FormikOutcomeSelect
      name={name}
      readOnly={user.role !== "admin" || readOnly}
      required
    />
  );
}

function DealVehicleInput({ deal, readOnly }) {
  // Render three inputs if isEditing, otherwise render a single span with the full name
  if (!readOnly) {
    return (
      <div className="flex gap-1 items-center">
        <FormikInput name="vehicle_brand_name" placeholder="Marca" required />
        <FormikInput name="vehicle_model_name" placeholder="Modello" required />
        <FormikInput name="vehicle_name" placeholder="Allestimento" />
      </div>
    );
  }
  return (
    <>
      <span>{deal.vehicle_full_name}</span>
      <input
        type="hidden"
        name="vehicle_brand_name"
        value={deal.vehicle_brand_name || ""}
      />
      <input
        type="hidden"
        name="vehicle_model_name"
        value={deal.vehicle_model_name || ""}
      />
      <input
        type="hidden"
        name="vehicle_name"
        value={deal.vehicle_name || ""}
      />
    </>
  );
}

function DealDetailsRows({ values, deal, isEditing }) {
  const { user } = useAuth();
  const { messages } = useDealChat();
  const [attachments, setAttachments] = useState([]);

  useEffect(() => {
    if (messages) {
      setAttachments(
        messages
          .filter((message) => message.attachment)
          .map((message) => message.attachment)
      );
    }
  }, [messages]);

  return verticalTableFromRows(
    {
      labelClassName: "w-[30%]",
      valueClassName: "whitespace-normal",
    },
    [
      {
        label: "Codice identificativo",
        value: <DealLink deal={deal} />,
      },
      {
        label: "Data creazione",
        value: formatDate(deal.created_at),
      },
      {
        label: `Stato${values.status === "Non conclusa" ? " e esito" : ""}`,
        value: (
          <div className="flex gap-2">
            <DealStatusSelect readOnly={!isEditing} />
            {values.status === "Non conclusa" && (
              <DealOutcomeSelect readOnly={!isEditing} />
            )}
          </div>
        ),
      },
      {
        label: "Autore",
        value: <DealCreatedBySelect deal={deal} readOnly={!isEditing} />,
      },
      {
        label: "Rete di appartenenza",
        value: deal?.author?.network?.partner ? (
          <UserLink user={deal.author.network.partner} />
        ) : (
          <i>Non disponibile</i>
        ),
      },

      {
        label: "Cliente",
        value: <DealClientSelect deal={deal} readOnly={!isEditing} />,
      },
      {
        label: "Veicolo",
        value: <DealVehicleInput deal={deal} readOnly={!isEditing} />,
      },
      {
        label: "Percorrenza",
        value: (
          <FormikInput
            readOnly={!isEditing}
            placeholder="Numero di km percorsi all'anno"
            type="number"
            min={0}
            step={1000}
            name="distance"
            suffix="km/anno"
            required
          />
        ),
      },
      {
        label: "Durata contratto",
        value: (
          <FormikInput
            readOnly={!isEditing}
            placeholder="Durata del contratto"
            type="number"
            min={12}
            step={12}
            name="duration"
            suffix="mesi"
            required
          />
        ),
      },
      {
        label: "Canone richiesto",
        value: (
          <FormikInput
            readOnly={!isEditing}
            placeholder="Canone mensile richiesto"
            type="number"
            min={0}
            step={0.01}
            name="price"
            suffix="€"
            required
          />
        ),
      },
      {
        label: "Anticipo richiesto",
        value: (
          <FormikInput
            readOnly={!isEditing}
            placeholder="Anticipo richiesto"
            name="deposit"
            min={0}
            step={0.01}
            suffix="€"
            required
          />
        ),
      },
      {
        label: "Note",
        value: <FormikTextarea readOnly={!isEditing} name="notes" />,
      },
      user.role === "admin" && {
        label: "Note amministrazione",
        value: <FormikTextarea readOnly={!isEditing} name="admin_notes" />,
      },
      attachments.length > 0 && {
        label: "Allegati",
        value: (
          <div className="flex flex-wrap gap-2">
            {attachments.map((attachment) => (
              <FileChip
                size="small"
                key={attachment.id}
                name={attachment.filename}
                id={attachment.id}
              />
            ))}
          </div>
        ),
      },
    ]
  );
}

export default function DealDetails({ dealId }) {
  const { id } = useParams();
  const { deal, isLoading, updateDeal } = useDeal(dealId || id);
  const [isEditing, setIsEditing] = useState(false);

  if (isLoading) {
    return <SkeletonCard />;
  }

  const initialValues = {
    author_id: deal.author.id,
    client_id: deal.client.id,
    vehicle_brand_name: deal.vehicle_brand_name,
    vehicle_model_name: deal.vehicle_model_name,
    vehicle_name: deal.vehicle_name,
    distance: deal.distance,
    duration: deal.duration,
    price: deal.price,
    deposit: deal.deposit,
    status: deal.status,
    outcome: deal.outcome,
    notes: deal.notes,
    admin_notes: deal.admin_notes,
  };

  return (
    <Card2>
      <Formik
        enableReinitialize
        initialValues={initialValues}
        onSubmit={(values, { setSubmitting }) => {
          updateDeal(values)
            .then(() => {
              setIsEditing(false);
            })
            .finally(() => {
              setSubmitting(false);
            });
        }}
      >
        {({ values }) => (
          <Form>
            <div className="flex px-1 mb-1">
              <Card2.Header>Dettagli trattativa</Card2.Header>
              <Card2.EditButton
                className="bg-blue-500 hover:bg-blue-700"
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            </div>
            <DealDetailsRows
              values={values}
              deal={deal}
              isEditing={isEditing}
            />
          </Form>
        )}
      </Formik>
    </Card2>
  );
}
