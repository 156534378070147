import Pagination from "@/components/form/Pagination";
import { Searchbar } from "@/components/form/inputs";
import { BadgeSelect } from "@/components/form/inputs/Select";
import { Button } from "@/components/ui/Button";
import Table from "@/components/ui/Table";
import { API_URL } from "@/config/routes";
import { useAuth } from "@/hooks/useAuth";
import useDebounce from "@/hooks/useDebounce";
import { fetcher, formatDate, objectToQueryString } from "@/utils";
import { useEffect, useState } from "react";
import { AiFillTags } from "react-icons/ai";
import { BiCommentDetail } from "react-icons/bi";
import { BsGear } from "react-icons/bs";
import { MdAdd, MdReadMore } from "react-icons/md";
import { Link } from "react-router-dom";
import useSWR from "swr";
import UserLink from "../users/UserLink";
import TaskStatusBadge, { TASK_STATUSES } from "./TaskStatusBadge";
import TaskLabelBadge from "./labels/TaskLabelBadge";
import TaskLabelMultiSelect from "./labels/TaskLabelMultiSelect";

const DEFAULT_QUERY_PARAMS = {
  page: 1,
  per_page: 20,
  sort_by: "updated_at",
  order: "desc",
  status: ["In coda", "In lavorazione", "To do"],
};

async function SWRFetcher({ url, params }) {
  return fetcher(url + "?" + objectToQueryString(params)).then((res) =>
    res.json()
  );
}

function TaskRow({ task }) {
  return (
    <Table.Row>
      <Table.Td>
        <span className="text-gray-600">#{task.id}</span>
      </Table.Td>
      <Table.Td>
        <div className="flex flex-col gap-1 my-1">
          <div className="flex flex-wrap items-center gap-2">
            <div className="font-medium text-zinc-800 text-base whitespace-pre-wrap">
              <Link to={`/tasks/${task.id}`}>{task.title}</Link>
              <div className="inline-flex flex-wrap gap-1 ml-2">
                {task.labels.map((label) => (
                  <TaskLabelBadge label={label} />
                ))}
              </div>
            </div>
          </div>

          <div className="flex">
            <span className="text-gray-600 text-sm">
              creato da <UserLink user={task.author} />
              &nbsp;il&nbsp;
              {formatDate(task.created_at)}
              {task.complexity && (
                <>
                  &nbsp;·&nbsp;
                  <BsGear className="inline-block mr-1 text-sm" />
                  <span className="text-gray-600">{task.complexity}h</span>
                </>
              )}
              {task.comments_count > 0 && (
                <>
                  &nbsp;·&nbsp;
                  <span className="text-gray-600">
                    <BiCommentDetail className="inline-block mr-1 text-sm" />
                    {task.comments_count}
                  </span>
                </>
              )}
            </span>
          </div>
        </div>
      </Table.Td>
      <Table.Td>
        <span className="w-full flex justify-center">
          <TaskStatusBadge status={task.status} />
        </span>
      </Table.Td>
      <Table.Td className="pr-6">
        <Link to={`/tasks/${task.id}`}>
          <span className="w-full flex justify-center group">
            <span className="relative">
              <MdReadMore className="text-zinc-300 text-3xl group-hover:text-blue-700 hover:cursor-pointer" />
            </span>
          </span>
        </Link>
      </Table.Td>
    </Table.Row>
  );
}

function TasksTableHeader() {
  return (
    <Table.Head>
      <Table.Row>
        <Table.Th className="w-[5%]">ID</Table.Th>
        <Table.Th className="w-[70%]">Informazioni</Table.Th>
        <Table.Th className="w-[10%] text-center">Stato</Table.Th>
        <Table.Th className="w-[15%] text-center pr-6">Dettagli</Table.Th>
      </Table.Row>
    </Table.Head>
  );
}

function TasksTable({ tasks, filters }) {
  return (
    <Table className="w-full table-fixed">
      <TasksTableHeader filters={filters} />
      <Table.Body>
        {!tasks &&
          Array.from({ length: 10 }).map((_, i) => (
            <Table.SkeletonRow key={i} colsNumber={6}></Table.SkeletonRow>
          ))}
        {tasks &&
          tasks.map((task) => (
            <TaskRow key={task.id} task={task} filters={filters} />
          ))}
      </Table.Body>
    </Table>
  );
}

function TasksFilters({ filters, onFilterChange }) {
  return (
    <div className="flex flex-row gap-2 justify-between items-center">
      <BadgeSelect
        prefix="Stato"
        isMulti={true}
        className="w-72"
        options={[
          ...Object.keys(TASK_STATUSES).map((i) => ({
            value: TASK_STATUSES[i],
            label: <TaskStatusBadge status={TASK_STATUSES[i]} />,
          })),
        ]}
        value={filters.status}
        onChange={(e) => {
          onFilterChange({ status: e.map((i) => i.value) });
        }}
      />
      <BadgeSelect prefix="Etichette" className="w-72">
        {/* I'm using it this way to be able to pass the cusom multi select component */}
        <TaskLabelMultiSelect
          onChange={(labels) => {
            onFilterChange({ label: labels.map((label) => label.value) });
          }}
        />
      </BadgeSelect>
      <BadgeSelect
        prefix="Ordina"
        isSearchable={false}
        options={[
          {
            value: "created_at-desc",
            label: "Data di creazione (dal più recente)",
          },
          {
            value: "created_at-asc",
            label: "Data di creazione (dal meno recente)",
          },
          {
            value: "updated_at-desc",
            label: "Data di aggiornamento (dal più recente)",
          },
          {
            value: "updated_at-asc",
            label: "Data di aggiornamento (dal meno recente)",
          },
        ]}
        value={`${filters.sort_by}-${filters.order}`}
        onChange={(e) =>
          onFilterChange({
            sort_by: e.target.value.split("-")[0],
            order: e.target.value.split("-")[1],
          })
        }
      />
    </div>
  );
}

function TasksSearchbar({ onDebouncedChange, className }) {
  const [searchTerm, setSearchTerm] = useState();
  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  useEffect(() => {
    // When the change has been debounced, we want to call the callback and set the typing state to false
    onDebouncedChange(debouncedSearchTerm);
  }, [debouncedSearchTerm]);

  return (
    <Searchbar
      id="search-tasks"
      value={searchTerm}
      onChange={(e) =>
        setSearchTerm(e.target.value !== "" ? e.target.value : undefined)
      }
      placeholder="Cerca per titolo"
      className={className}
    />
  );
}

function TasksDataTableButtons() {
  const { user } = useAuth();

  return (
    <div className="flex gap-2">
      {/* The button to create and manage labels */}
      {user.role === "admin" && (
        <Link to="/tasks/labels">
          <Button
            icon={<AiFillTags className="text-xl" />}
            className="py-4 bg-transparent border border-blue-700 text-blue-700 hover:bg-blue-700 hover:text-white"
          >
            Gestisci etichette
          </Button>
        </Link>
      )}
      {/* The button to create a new task */}
      <Link to="/tasks/new">
        <Button className="py-4" icon={<MdAdd className="text-xl" />}>
          Nuova richiesta
        </Button>
      </Link>
    </div>
  );
}

export default function TasksDataTable() {
  const [queryParams, setQueryParams] = useState(DEFAULT_QUERY_PARAMS);
  const data = useSWR(
    { url: `${API_URL}/tasks`, params: queryParams },
    SWRFetcher
  );

  const handleParamsChange = (params) => {
    // Whenever the params change, we want to reset the page to 1
    setQueryParams({ ...queryParams, ...params, page: 1 });
  };

  return (
    <div className="flex flex-col gap-4">
      {/* The search bar for the tasks */}
      <TasksSearchbar
        className="w-[65%]"
        onDebouncedChange={(searchTerm) => {
          handleParamsChange({ ...queryParams, q: searchTerm });
        }}
      />
      {/* The filters for the tasks */}
      <div className="flex justify-between">
        <TasksFilters
          filters={queryParams}
          onFilterChange={(filters) =>
            handleParamsChange({ ...queryParams, ...filters })
          }
        />

        <div className="flex justify-end">
          <Pagination
            onPageChange={(page) => setQueryParams({ ...queryParams, page })}
            currentPage={queryParams.page}
            totalPages={data.data?.pages}
          />
        </div>
      </div>
      <TasksTable tasks={data.data?.items} filters={queryParams} />
      <div className="flex justify-end">
        <Pagination
          onPageChange={(page) => setQueryParams({ ...queryParams, page })}
          currentPage={queryParams.page}
          totalPages={data.data?.pages}
        />
      </div>
    </div>
  );
}

TasksDataTable.Buttons = TasksDataTableButtons;
