import { API_URL } from "@/config/routes";
import { clsxm, fetcher } from "@/utils";
import { FileIcon, defaultStyles } from "react-file-icon";
import { TiDelete } from "react-icons/ti";

export function EphemeralFileChip({ name, onDelete: handleDelete }) {
  return (
    <a
      onClick={(e) => e.preventDefault()}
      className="flex items-center h-full bg-zinc-50 w-fit max-w-full py-2 px-4 rounded-xl border truncate"
    >
      <span className="mr-4 shadow border rounded w-6">
        <FileIcon
          extension={name.split(".").pop()}
          {...defaultStyles[name.split(".").pop()]}
        />
      </span>
      <span className="flex flex-col overflow-x-hidden">
        <span className="truncate group-hover/file:underline">{name}</span>
      </span>
      <span
        className="ml-3 text-red-500 hover:text-red-600"
        onClick={handleDelete}
      >
        <TiDelete className="text-2xl hover:cursor-pointer" />
      </span>
    </a>
  );
}

export default function FileChip({ name, id, url, size = "large" }) {
  const urlToFetch = id ? `${API_URL}/media/${id}` : url;

  const downloadFile = () => {
    fetcher(urlToFetch, {
      method: "GET",
    }).then((response) => {
      response.blob().then((blob) => {
        let fileUrl = window.URL.createObjectURL(blob);
        let a = document.createElement("a");
        // Click the link to download the file
        a.href = fileUrl;
        a.download = name;
        a.click();
      });
    });
  };

  return (
    <a
      title={`Download "${name}"`}
      onClick={(id || url) && downloadFile}
      className={clsxm(
        "flex items-center h-full bg-zinc-50 w-fit max-w-full py-4 px-6 pr-8 rounded-xl border truncate hover:bg-zinc-100 group/file cursor-pointer",
        size === "small" && "py-2 px-4"
      )}
    >
      <span
        className={clsxm(
          "mr-6 shadow-xl border rounded",
          size === "small" ? "w-6 mr-2" : "w-12"
        )}
      >
        <FileIcon
          extension={name.split(".").pop()}
          {...defaultStyles[name.split(".").pop()]}
        />
      </span>
      <span className="flex flex-col overflow-x-hidden">
        <span className="truncate group-hover/file:underline">{name}</span>
        {size === "large" && (
          <span className="text-sm text-zinc-400">
            File .{name.split(".").pop()}
          </span>
        )}
      </span>
    </a>
  );
}
