import { API_URL } from "@/config/routes";
import { fetcher } from "@/utils";
import { useState } from "react";
import { toast } from "react-toastify";
import useSWR from "swr";

export default function useContract(id) {
  const {
    data: contract,
    isLoading,
    error,
    mutate,
  } = useSWR(`${API_URL}/contracts/${id}`);
  const [isUpdating, setIsUpdating] = useState(false);

  const updateContract = async (data) => {
    setIsUpdating(true);
    mutate({ ...contract, ...data }, false); // Optimistic update

    const response = await fetcher(`${API_URL}/contracts/${id}`, {
      method: "PATCH",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    mutate(); // Revalidate

    if (!response.ok) {
      toast.error(
        `Errore durante l'aggiornamento del contratto: ${response.body.message}`
      );
    } else {
      toast.success("Contratto aggiornato correttamente");
    }

    setIsUpdating(false);
  };

  return { contract, isLoading, error, updateContract, isUpdating };
}
