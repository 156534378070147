import { Card2 } from "@/components/ui/Card";
import { LoadingDots } from "@/components/ui/Loading";
import { useParams } from "react-router-dom";
import { Page, PageHeader } from "../../components/layout/Page";
import ClientCreateForm from "./ClientCreateForm";
import ClientDetailsCard from "./ClientDetailsCard";
import ClientRiskDetailsCard from "./ClientRiskDetailsCard";
import ClientsDataTable from "./ClientsDataTable";
import useClient from "./useClient";

export const clientRoutes = [
  { path: "/clients/new", element: <ClientCreate /> },
  { path: "/clients", element: <Clients /> },
  { path: "/clients/:id", element: <ClientDetails /> },
];

function ClientCreate() {
  return (
    <Page title="Crea cliente">
      <PageHeader
        description="Inserisci i dati del cliente per aggiungerlo al database."
        breadcrumbs={[
          { label: "Clienti", url: "/clients" },
          { label: "Crea cliente" },
        ]}
      />
      <Card2 className="w-full max-w-4xl flex gap-1">
        <Card2.Header>Aggiungi un nuovo cliente</Card2.Header>
        <ClientCreateForm />
      </Card2>
    </Page>
  );
}

function ClientDetails() {
  const { id } = useParams();
  const { client } = useClient(id);

  return (
    <Page title={client?.name}>
      <PageHeader
        description="Qui puoi visualizzare e modificare i dettagli del cliente"
        breadcrumbs={[
          { label: "Clienti", href: "/clients" },
          { label: client?.name || <LoadingDots /> },
        ]}
      />
      <div className="flex gap-4">
        <div className="lg:w-[55%] xl:w-[60%] 2xl:w-[70%]">
          <ClientDetailsCard clientId={id} />
        </div>
        <div className="lg:w-[45%] xl:w-[40%] 2xl:w-[30%]">
          <ClientRiskDetailsCard client={client} />
        </div>
      </div>
    </Page>
  );
}

export default function Clients() {
  return (
    <Page title="Clienti">
      <PageHeader
        breadcrumbs={[{ label: "Clienti" }]}
        description="Visualizza e gestisci i clienti registrati da te e dalla tua Rete"
        topRight={<ClientsDataTable.NewClientButton />}
      />
      <ClientsDataTable />
    </Page>
  );
}
