import { Page, PageHeader } from "@/components/layout/Page";
import { useAuth } from "@/hooks/useAuth";
import Dashboard from "./Dashboard";

export const dashboardRoutes = [
  { path: "/dashboard", element: <DashboardPage /> },
];

function DashboardPage() {
  const { user } = useAuth();
  const simpleUser = user.role === "user";

  return (
    <Page>
      <PageHeader
        breadcrumbs={[{ label: "Dashboard" }]}
        description={`Visualizza i dettagli della tua performance con Genoleggia ${
          simpleUser ? "" : "e quello della tua rete"
        }`}
      />
      <Dashboard />
    </Page>
  );
}
