import {
  Checkbox,
  FormikInput,
  FormikSelect,
  FormikTextarea,
} from "@/components/form/inputs";
import { Button } from "@/components/ui/Button";
import { verticalTableFromRows } from "@/components/ui/Table";
import { PROVINCES } from "@/config/provinces";
import { API_URL } from "@/config/routes";
import { fetcher } from "@/utils";
import { Form, Formik, useFormikContext } from "formik";
import { useEffect, useState } from "react";
import { BsPlusCircle } from "react-icons/bs";
import { CgSpinner } from "react-icons/cg";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

const INITIAL_FORM_VALUES = {
  tax_id: "",
  fiscal_code_for_freelancers: "",
  type: "",
  business_name: "",
  controlling_party: "",
  controlling_party_tax_id: "",
  fleet_manager: "",
  fleet_manager_tax_id: "",
  first_name: "",
  last_name: "",
  address: {
    street: "",
    postal_code: "",
    city: "",
    province: "",
  },
  email: "",
  pec: "",
  mobile_phone: "",
  phone: "",
  notes: "",
};

const isCorrectFiscalCode = (tax_id) => {
  const regex =
    /^(?:[A-Z][AEIOU][AEIOUX]|[AEIOU]X{2}|[B-DF-HJ-NP-TV-Z]{2}[A-Z]){2}(?:[\dLMNP-V]{2}(?:[A-EHLMPR-T](?:[04LQ][1-9MNP-V]|[15MR][\dLMNP-V]|[26NS][0-8LMNP-U])|[DHPS][37PT][0L]|[ACELMRT][37PT][01LM]|[AC-EHLMPR-T][26NS][9V])|(?:[02468LNQSU][048LQU]|[13579MPRTV][26NS])B[26NS][9V])(?:[A-MZ][1-9MNP-V][\dLMNP-V]{2}|[A-M][0L](?:[1-9MNP-V][\dLMNP-V]|[0L][1-9MNP-V]))[A-Z]$/i;
  return regex.test(tax_id);
};

const isCorrectVatNumber = (tax_id) => {
  const regex = /^\d{11}$/;
  return regex.test(tax_id);
};

const filterCreditsafeData = (data) => {
  const report = data.report;
  return {
    business_name: report.alternateSummary.businessName,
    controlling_party:
      report.uppData?.ultimateControllingParties?.[0]?.companyName || "",
    controlling_party_tax_id:
      report.uppData?.ultimateControllingParties?.[0]
        ?.companyRegistrationNumber || "",
    address: {
      street:
        report.alternateSummary.contactAddress.street +
        " " +
        report.alternateSummary.contactAddress.houseNumber,
      postal_code: report.alternateSummary.contactAddress.postalCode,
      city: report.alternateSummary.contactAddress.city,
      province: report.alternateSummary.contactAddress.province,
    },
    pec: report.alternateSummary?.emailAddresses || "",
    phone: report.alternateSummary?.telephone || "",
  };
};

const getTaxIdField = (values, isLoading) => {
  return {
    label: values.type === "person" ? "Codice fiscale" : "Partita IVA",
    subLabel:
      values.type === "person" ? (
        <span>
          Assicurati di scrivere il codice fiscale correttamente. Per verificare
          se il codice fiscale è valido, puoi usare{" "}
          <a
            className="text-blue-700 hover:underline"
            href="https://telematici.agenziaentrate.gov.it/VerificaCF/Scegli.do?parameter=verificaCf"
            target="_blank"
            rel="noreferrer"
          >
            questo strumento online
          </a>
          .
        </span>
      ) : (
        <span>
          Assicurati di scrivere la partita IVA correttamente. Per verificare se
          la partita IVA è valida, puoi usare{" "}
          <a
            className="text-blue-700 hover:underline"
            href="https://telematici.agenziaentrate.gov.it/VerificaPIVA/Scegli.do?parameter=verificaPiva"
            target="_blank"
            rel="noreferrer"
          >
            questo strumento online
          </a>
          .
        </span>
      ),
    value: (
      <div className="flex flex-row">
        <FormikInput
          required
          name="tax_id"
          maxLength={values.type === "person" ? 16 : 11}
          placeholder={
            values.type === "person"
              ? "Digita il codice fiscale"
              : "Digita la partita IVA"
          }
        />
        {isLoading && (
          <div className="flex items-center justify-center">
            <CgSpinner className="animate-spin text-xl ml-2" />
          </div>
        )}
      </div>
    ),
  };
};

const getNameField = (values) => {
  return {
    label: "Intestatario",
    value: (
      <div className="flex flex-col gap-2">
        <FormikInput
          name="first_name"
          placeholder="Nome del pilota effettivo del veicolo"
          required={values.type === "person"}
        />
        <FormikInput
          name="last_name"
          placeholder="Cognome del pilota effettivo del veicolo"
          required={values.type === "person"}
        />
      </div>
    ),
  };
};

const getFreelancerFields = (values) => {
  return [
    {
      label: "Codice Fiscale",
      value: (
        <FormikInput
          name="fiscal_code_for_freelancers"
          placeholder="Digita il codice fiscale"
          required
          maxLength={16}
        />
      ),
    },
  ];
};

const getCompanyFields = (values) => {
  return [
    {
      label: "Ragione sociale",
      value: (
        <FormikInput
          name="business_name"
          placeholder="Digita la ragione sociale"
          required
        />
      ),
    },
    {
      label: "Proprietario dell'attività",
      value: (
        <div className="flex flex-col gap-2">
          <FormikInput
            name="controlling_party"
            placeholder="Nome e cognome o ragione sociale del proprietario"
          />
          <FormikInput
            name="controlling_party_tax_id"
            placeholder="Codice fiscale o partita IVA del proprietario"
            maxLength={16}
          />
        </div>
      ),
    },
    {
      label: "Responsabile flotta",
      value: (
        <div className="flex flex-col gap-2">
          <FormikInput
            name="fleet_manager"
            placeholder="Nome e cognome del responsabile flotta"
          />
          <FormikInput
            name="fleet_manager_tax_id"
            placeholder="Codice fiscale o partita IVA del responsabile flotta"
            maxLength={16}
          />
        </div>
      ),
    },
  ];
};

function AddressInput() {
  return (
    <div className="grid grid-cols-2 gap-2">
      <FormikInput
        placeholder="Indirizzo"
        name="address.street"
        required={true}
      />
      <FormikInput
        placeholder="CAP"
        name="address.postal_code"
        required={true}
      />
      <FormikInput placeholder="Città" name="address.city" required={true} />
      <FormikSelect
        placeholder="Provincia"
        name="address.province"
        required={true}
        options={PROVINCES}
      />
    </div>
  );
}

function EmailInput() {
  return (
    <div className="flex flex-row gap-2">
      <FormikInput
        placeholder="Email"
        name="email"
        type="email"
        required={true}
      />
      <FormikInput placeholder="PEC" name="pec" type="email" />
    </div>
  );
}

function PhoneInput() {
  return (
    <div className="flex flex-row gap-2">
      <FormikInput
        placeholder="Cellulare"
        name="mobile_phone"
        type="tel"
        required={true}
      />
      <FormikInput placeholder="Ufficio" name="phone" type="tel" />
    </div>
  );
}

function CreateClientFormInputs() {
  const [isValidTaxId, setIsValidTaxId] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [taxIdError, setTaxIdError] = useState();
  const { values, resetForm, setFieldValue } = useFormikContext();

  // Fetch the data from Creditsafe and fill the fields, then show them
  const autoFillCompanyFields = () => {
    setIsLoading(true);
    toast.info("Stiamo cercando i dati del cliente su Creditsafe...");
    fetcher(`${API_URL}/creditsafe/${values.tax_id}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          // Throw an error with the message
          return response.json().then((json) => {
            throw new Error(json.message);
          });
        }
      })
      .then((data) => {
        const updatedFields = {
          tax_id: values.tax_id,
          type: values.type,
          // We set this here because we need to post it later
          creditsafe: data,
          // And this it to autofill the fields
          ...filterCreditsafeData(data),
        };
        // Update the form values
        Object.keys(updatedFields).forEach((key) => {
          setFieldValue(key, updatedFields[key]);
        });
        toast.info("Alcuni campi sono stati riempiti automaticamente");
      })
      .catch((err) => {
        toast.dismiss();
        toast.error(
          <code className="font-mono text-sm bg-red-100 text-red-900 p-0.5">
            {"Creditsafe: " + err.message ||
              "Si è verificato un errore durante il recupero dei dati dal database Creditsafe"}
          </code>
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  // Reset everything on change of tax_id
  useEffect(() => {
    setIsLoading(false);
    setTaxIdError();
    resetForm({
      values: {
        tax_id: values.tax_id,
        type: values.type,
      },
    });
    // Set the validity of the tax_id
    const validatorFuncs = {
      person: isCorrectFiscalCode,
      company: isCorrectVatNumber,
      freelancer: isCorrectVatNumber,
    };
    const valid = (validatorFuncs[values.type] || (() => false))(values.tax_id);
    setIsValidTaxId(valid);
    setIsLoading(valid); // We set it here so that it doesn't flash the fields
  }, [values.tax_id]);

  // Reset everything on change of type
  useEffect(() => {
    setIsLoading(false);
    setTaxIdError();
    resetForm({
      values: {
        tax_id: values.tax_id,
        type: values.type,
      },
    });
  }, [values.type]);

  // When the tax_id is valid, check if the client already exists
  // If it does, show an error, otherwise show the correct fields
  useEffect(() => {
    if (isValidTaxId) {
      setIsLoading(true);
      fetcher(`${API_URL}/clients/tax_id/${values.tax_id}`)
        .then((response) => {
          if (response.status === 404) {
            // Client doesn't exist, which is good
            // Show the correct fields
            values.type === "company" && autoFillCompanyFields();
          } else if (response.status === 200) {
            // Client already exists in the user's list
            setTaxIdError(
              "Il cliente è già registrato nella tua lista clienti"
            );
          } else if (response.status === 403) {
            // Client already exists, but not in the user's list
            setTaxIdError("Il cliente è già registrato da un altro utente");
          } else {
            // Something went wrong
            setTaxIdError("Si è verificato un errore");
          }
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [isValidTaxId]);

  // Whenever there is a tax_id error, show a toast
  useEffect(() => {
    if (taxIdError) {
      toast.error(taxIdError);
    }
  }, [taxIdError]);

  return verticalTableFromRows({ labelClassName: "w-1/3" }, [
    {
      label: "Forma giuridica",
      value: (
        <FormikSelect
          required
          name="type"
          placeholder="Scegli la forma giuridica"
          options={[
            { value: "person", label: "Persona fisica" },
            { value: "company", label: "Società" },
            { value: "freelancer", label: "Professionista" },
          ]}
        />
      ),
    },
    // If the type is not null show the tax_id field
    values.type !== "" && getTaxIdField(values, isLoading),
    // If the tax_id is valid and there is no error, show the correct fields
    ...(isValidTaxId && !taxIdError && !isLoading
      ? [
          // If the type is freelancer, show the freelancer fields
          ...(values.type === "freelancer" ? getFreelancerFields(values) : []),
          getNameField(values),
          // Show the name field
          // If the type is company, show the company fields
          ...(values.type === "company" ? getCompanyFields(values) : []),
          {
            label: "Indirizzo",
            value: <AddressInput />,
          },
          {
            label: "Email",
            value: <EmailInput />,
          },
          {
            label: "Telefono",
            value: <PhoneInput />,
          },
          {
            label: "Note",
            value: (
              <FormikTextarea
                name="notes"
                rows={4}
                placeholder="Includere eventuali note, come per esempio se il cliente ha mai fatto Noleggio a Lungo Termine, e se sì con quale fornitore (Arval, ALD, Sifà, etc.)"
              />
            ),
          },
        ]
      : []),
  ]);
}

export default function CreateClientForm() {
  const [goToCreateDeal, setGoToCreateDeal] = useState(true);
  const navigate = useNavigate();

  const handleSubmit = (values, { resetForm }) => {
    fetcher(`${API_URL}/clients/`, {
      method: "POST",
      body: JSON.stringify({ ...values }),
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      if (response.ok) {
        response.json().then((data) => {
          const successMessage = (
            <span>
              Cliente <b>{data.name}</b> creato correttamente.{" "}
              <Link
                to={`/clients/${data.id}`}
                className="underline text-blue-700 hover:text-blue-700"
              >
                Vai alla pagina del cliente
              </Link>
              .
            </span>
          );
          toast.success(successMessage);
          // If the state is set to go to the create deal page, navigate there
          if (goToCreateDeal) navigate("/deals/new");
          resetForm({ values: INITIAL_FORM_VALUES });
        });
      } else {
        response.json().then((data) => {
          toast.error(
            <span>
              Errore:{" "}
              <code className="font-mono text-sm bg-red-100 text-red-900 p-0.5">
                {data.message}
              </code>
            </span>
          );
        });
      }
    });
  };

  return (
    <Formik initialValues={INITIAL_FORM_VALUES} onSubmit={handleSubmit}>
      {({ dirty, isSubmitting, resetForm }) => (
        <Form>
          <CreateClientFormInputs />
          <div className="flex flex-row justify-between pt-4">
            <Checkbox
              label='Vai alla pagina "Crea trattativa" dopo aver registrato il cliente'
              className="text-sm text-zinc-800"
              disabled={!dirty || isSubmitting}
              checked={goToCreateDeal}
              onChange={() => setGoToCreateDeal(!goToCreateDeal)}
            />
            <div className="flex items-center gap-2">
              <Button
                type="reset"
                className="bg-gray-300 hover:bg-gray-400"
                onClick={() => resetForm({ values: INITIAL_FORM_VALUES })}
              >
                Reset
              </Button>
              <Button
                type="submit"
                icon={<BsPlusCircle />}
                disabled={!dirty || isSubmitting}
              >
                Registra cliente
              </Button>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
}
